<fieldset [disabled]="disabled" [ngClass]="{ additive: additive }">
	<legend *ngIf="legend">{{ legend }}</legend>

	<ng-container *ngFor="let entry of entries; index as entryI; trackBy: trackEntry">
		<div *ngIf="additive" class="text-right"><a href="#" (click)="removeEntry($event, entryI)">X</a></div>

		<div class="entry-content">
			<div *ngFor="let row of entry.rows" class="form-row mb-3">
				<cm-field
					*ngFor="let field of row.fieldsVisible()"
					[field]="field.field"
					class="col-md-{{ Math.floor(12 / row.length) }} col-sm-12"
				></cm-field>
			</div>
		</div>
	</ng-container>

	<button type="button" *ngIf="additive" class="btn btn-secondary add-button" (click)="addEntry()">
		Add {{ legend }}
	</button>
</fieldset>
