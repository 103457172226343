import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
	NgbDatepickerModule,
	NgbModalModule,
	NgbNavModule,
	NgbPaginationModule,
	NgbProgressbarModule,
	NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrModule } from "angularx-flatpickr";
import { ModalModule } from "shared";
import { CalendarModule } from "../calendar/calendar.module";
import { FormModule } from "../form/form.module";
import { LayoutModule } from "../layout/layout.module";
import { AddCommissionReportComponent } from "./components/add-commission-report/add-commission-report.component";
import { AddCommissionComponent } from "./components/add-commission/add-commission.component";
import { CommissionAdjustmentComponent } from "./components/commission-adjustment/commission-adjustment.component";
import { CommissionWithholdingComponent } from "./components/commission-withholding/commission-withholding.component";
import { CommissionComponent } from "./components/commission/commission.component";
import { DrawSheetSummaryComponent } from "./components/draw-sheet-summary/draw-sheet-summary.component";
import { DrawSheetComponent } from "./components/draw-sheet/draw-sheet.component";
import { HRModalComponent } from "./components/hr-modal/hr-modal.component";
import { TimeBoardComponent } from "./components/time-board/time-board.component";
import { TimeClockComponent } from "./components/time-clock/time-clock.component";
import { TimeManagerReportComponent } from "./components/time-manager-report/time-manager-report.component";
import { TimeOffRemainingComponent } from "./components/time-off-remaining/time-off-remaining.component";
import { TimeOffRequestComponent } from "./components/time-off-request/time-off-request.component";
import { TimeReportComponent } from "./components/time-report/time-report.component";
import { TimeoffCalendarComponent } from "./components/timeoff-calendar/timeoff-calendar.component";
import { TimeoffRequestReviewComponent } from "./components/timeoff-request-review/timeoff-request-review.component";
import { HrInfoWidgetComponent } from "./components/widgets/hr-info/hr-info-widget.component";
import { TimeBoardWidgetComponent } from "./components/widgets/time-board/time-board-widget.component";
import { TimeoffActiveWidgetComponent } from "./components/widgets/timeoff-active/timeoff-active-widget.component";
import { EmpTimeoffWidgetComponent } from "./components/widgets/timeoff-emp/timeoff-emp-widget.component";
import { TimeoffPendingWidgetComponent } from "./components/widgets/timeoff-pending/timeoff-pending-widget.component";

@NgModule({
	declarations: [
		HRModalComponent,
		TimeBoardComponent,
		TimeClockComponent,
		TimeManagerReportComponent,
		TimeOffRemainingComponent,
		TimeOffRequestComponent,
		TimeReportComponent,
		TimeoffCalendarComponent,
		TimeoffRequestReviewComponent,
		HrInfoWidgetComponent,
		TimeBoardWidgetComponent,
		TimeoffActiveWidgetComponent,
		EmpTimeoffWidgetComponent,
		TimeoffPendingWidgetComponent,
		CommissionComponent,
		DrawSheetComponent,
		DrawSheetSummaryComponent,
		AddCommissionComponent,
		AddCommissionReportComponent,
		CommissionAdjustmentComponent,
		CommissionWithholdingComponent,
	],
	imports: [
		CommonModule,
		NgbModalModule,
		NgbProgressbarModule,
		FlatpickrModule,
		CalendarModule,
		FontAwesomeModule,
		ModalModule,
		FormModule,
		FormsModule,
		RouterModule,
		NgbTooltipModule,
		NgbPaginationModule,
		LayoutModule,
		NgbNavModule,
		NgbDatepickerModule,
	],
	exports: [HRModalComponent],
})
export class HRModule {}
