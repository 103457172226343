import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";
import { Service, CacheService } from "@core/app/cache.service";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class CompareService {
	private compareBS = new BehaviorSubject<Set<number>>(new Set());
	compare$ = this.compareBS.asObservable();
	cache: Service;

	ids: any[] = [];

	listings$ = this.compare$.pipe(
		switchMap((compare) =>
			this.http.post("api/statement/GetHomes", { vars: { listingids: [...compare].join(","), sold: true } }),
		),
		map((res: any) => res.results),
		tap((results) => {
			this.ids = results.map((row: any) => row.listingid);
			if (this.ids.length < this.compareBS.value.size) {
				this.toastrService.error("Items in Your Compare Have Been Removed From Site. Updating List");
			}
			this.compareBS.value.forEach((value1, value2, set) => {
				if (this.ids.indexOf(value1) === -1) {
					this.removeListing(value1);
				}
			});
		}),
		shareReplay(1),
	);

	constructor(private http: HttpClient, private cacheService: CacheService, private toastrService: ToastrService) {
		this.cache = this.cacheService.init("localStorage");
		const starting = this.cache.get("CompareIds");
		if (starting) {
			this.compareBS.next(new Set(starting));
		}
	}

	addListing(listingid: number) {
		this.compareBS.value.add(listingid);
		this.cache.set("CompareIds", [...this.compareBS.value]);
		this.compareBS.next(new Set(this.compareBS.value));
	}

	removeListing(listingid: number) {
		this.compareBS.value.delete(listingid);
		this.cache.set("CompareIds", [...this.compareBS.value]);
		this.compareBS.next(new Set(this.compareBS.value));
	}

	data$ = this.compare$.pipe(
		switchMap((listingids) =>
			this.http.post("/api/statement/GetListingPropertyInfo", {
				vars: { listingids: Array.from(listingids) },
			}),
		),
		map((res: any) => {
			return res.results.map((row: any) => {
				row.basement = row.basement ? "Yes" : "No";
				row.pool = row.pool ? "Yes" : "No";
				row.waterfront = row.waterfront ? "Yes" : "No";
				row.image = row.img_file ? row.img_dir + row.img_file : null;
				return row;
			});
		}),
		tap((results) => {
			this.ids = results.map((row: any) => row.listingid);
			if (this.ids.length < this.compareBS.value.size) {
				this.toastrService.error("Items in Your Compare Have Been Removed From Site. Updating List");
			}
			this.compareBS.value.forEach((value1, value2, set) => {
				if (this.ids.indexOf(value1) === -1) {
					this.removeListing(value1);
				}
			});
		}),
		shareReplay(1),
	);

	clear() {
		this.cache.set("CompareIds", []);
		this.compareBS.next(new Set());
	}
}
