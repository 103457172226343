import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ModalService } from "./modal.service";

@Component({
	selector: "cm-modal-outlet",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="service.templates$ | async as modals">
			<ng-container *ngFor="let modal of modals | keyvalue">
				<ng-container *ngTemplateOutlet="modal.value"></ng-container>
			</ng-container>
		</ng-container>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ModalOutletComponent {
	constructor(public service: ModalService) {}
}
