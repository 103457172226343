import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { breakpointMax, breakpointMin, breakpoints } from "@common/bootstrap";
import { iter } from "@common/iter";
import { concat, defer, fromEvent, Observable, of } from "rxjs";
import { debounceTime, map, share } from "rxjs/operators";
import { isPrerendering } from "shared";

const MOBILE_BREAKPOINT = 991;
const MOBILE_BREAKPOINT_MD = 768;

@Injectable({ providedIn: "root" })
export class ViewportService {
	private startWidth$ = defer(() => of(this.document.body.clientWidth));

	// Subscribe to get resize events
	resize$ = (typeof window === "undefined" ? of() : fromEvent(window, "resize")).pipe(
		debounceTime(300),
		map((_) => this.document.body.clientWidth),
		share(),
	);

	// Subscribe to get the window size
	windowSize$ = !isPrerendering() ? concat(this.startWidth$, this.resize$) : this.startWidth$;

	// Subscrie to get if is mobile or not
	mobile$ = this.windowSize$.pipe(map((width) => width <= MOBILE_BREAKPOINT));
	// Certain places in the program look for the break for mobile at 768 not the standard 991
	mobileMd$ = this.windowSize$.pipe(map((width) => width <= MOBILE_BREAKPOINT_MD));

	breakpoint$ = this.windowSize$.pipe(
		map((width) =>
			iter(breakpoints)
				.filter((bp) => width >= breakpointMin[bp])
				.nth(0)
				.unwrapOr("xs"),
		),
	);

	bp = {
		down: {
			xs$: this.bp$((width) => width < breakpointMax.xs),
			sm$: this.bp$((width) => width < breakpointMax.sm),
			md$: this.bp$((width) => width < breakpointMax.md),
			lg$: this.bp$((width) => width < breakpointMax.lg),
			xl$: this.bp$((width) => width < breakpointMax.xl),
		},
		up: {
			xs$: this.bp$((width) => width >= breakpointMin.xs),
			sm$: this.bp$((width) => width >= breakpointMin.sm),
			md$: this.bp$((width) => width >= breakpointMin.md),
			lg$: this.bp$((width) => width >= breakpointMin.lg),
			xl$: this.bp$((width) => width >= breakpointMin.xl),
		},
	};

	constructor(@Inject(DOCUMENT) private document: Document) {}

	scrollIntoView(element: HTMLElement | null, offset: number) {
		if (!isPrerendering() && element !== null) {
			element.scrollIntoView(true);
			const scrolledY = window.scrollY;
			if (scrolledY) {
				window.scroll(0, scrolledY - offset);
			}
		}
	}

	private bp$(cb: (width: number) => boolean): Observable<boolean> {
		return this.windowSize$.pipe(map((width) => cb(width)));
	}
}
