import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Iter, range } from "@common/iter";
import { mod } from "@common/util";
import { SlideDirective } from "../slide.directive";

@Component({
	selector: "cm-slider-impl-fade",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex h-100 slider-wrapper">
			<div
				*ngFor="let frame of frames(); trackBy: frameTrackBy"
				class="frame"
				[ngClass]="{ active: frame.active, below: frame.below }"
			>
				<div
					*ngFor="let slide of frame.slides; trackBy: slideTrackBy"
					class="flex-shrink-0 limit-x slide"
					[ngClass]="{ active: slide.index === slideIndex }"
					[style.width.%]="100 / this.inView"
				>
					<div class="d-flex justify-content-center align-items-center">
						<div *ngIf="slide.slide" class="{{ fullWidth ? 'w-100' : 'mw-100' }}">
							<ng-container *ngTemplateOutlet="slide.slide!.templateRef"></ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			.limit-x {
				overflow-x: hidden;
			}
			.frame {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: 0;
				transition: opacity 0.5s;
			}
			.frame.below {
				opacity: 1;
				z-index: -1;
			}
			.frame.active {
				position: static;
				opacity: 1;
				width: 100%;
			}
		`,
	],
})
export class SliderImplFadeComponent {
	@Input() center: boolean = false;
	@Input() fullWidth: boolean = false;
	@Input() loop: boolean = false;
	@Input() advance!: number;
	@Input() preload!: number;
	@Input() slides!: SlideDirective[];
	@Input() slideIndex: number = 0;
	@Input() inView: number = 1;

	frames(): IFrame[] {
		let start = this.slideIndex;
		if (this.center) {
			start -= Math.floor(this.inView / 2);
		}

		const preload = Math.ceil(this.preload / this.advance) * this.advance;
		let preloadStart = start - preload;
		let preloadEnd = start + this.inView + preload;

		if (!this.loop) {
			start = Math.min(this.slides.length - this.inView, Math.max(0, start));
			preloadStart = Math.max(0, start);
			preloadEnd = Math.min(this.slides.length, start);
		}

		const end = start + this.inView;

		const view = {
			active: true,
			below: false,
			slides: range(start, end)
				.map((index) => ({ index, slide: this.slides[mod(index, this.slides.length)] }))
				.toArray(),
		};

		const framesBefore = this.rangeToFrames(range(preloadStart, start), true);
		const framesAfter = this.rangeToFrames(range(end, preloadEnd), false);

		return [...framesBefore, view, ...framesAfter];
	}

	frameTrackBy(_index: number, item: IFrame) {
		return item.slides[0].index;
	}

	slideTrackBy(_index: number, item: { slide: SlideDirective; index: number }) {
		return item.index;
	}

	private rangeToFrames(iter: Iter<number>, below: boolean) {
		return iter
			.stepBy(this.advance)
			.map((index) =>
				range(index, index + this.advance)
					.map((index) => ({ index, slide: this.slides[mod(index, this.slides.length)] }))
					.toArray(),
			)
			.map((slides) => ({ active: false, below, slides }));
	}
}

interface IFrame {
	active: boolean;
	below: boolean;
	slides: { slide: SlideDirective; index: number }[];
}
