import { Component, Input, OnInit } from "@angular/core";
import { FormRow } from "../../_form-data";

@Component({
	selector: "cm-fieldset",
	templateUrl: "./fieldset.component.html",
	styleUrls: ["./fieldset.component.scss"],
})
export class FieldsetComponent implements OnInit {
	@Input() legend?: string;
	@Input() additive: boolean = false;
	@Input() disabled: boolean = false;
	@Input() rows: FormRow[] = [];
	@Input() entries: Entry[] = [];

	Math = Math;
	nextEntryId: number = 0;

	ngOnInit(): void {
		if (!this.additive) {
			this.entries.push(new Entry(this.nextEntryId++, this.cloneRows()));
		}
	}

	addEntry() {
		this.entries.push(new Entry(this.nextEntryId++, this.cloneRows()));
	}

	removeEntry(event: Event, index: number) {
		event.preventDefault();

		this.entries.splice(index, 1);
	}

	trackEntry(_index: number, entry: Entry) {
		return entry.id;
	}

	private cloneRows() {
		return this.rows.map((row: any) => row.clone());
	}
}

export class Entry {
	constructor(public id: number, public rows: FormRow[]) {}
}
