<ng-container>
	<cm-label *ngIf="label && options" [text]="label"></cm-label>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<div *ngFor="let opt of options" class="radio form-check form-check-inline">
		<label>
			<input
				ref="input"
				type="radio"
				[name]="name"
				[value]="opt.value"
				[disabled]="disabled"
				[checked]="opt.value == value"
				(change)="valueChange.emit($event.target.value)"
				class="form-check-input"
			/>
			{{ opt.label }}
		</label>
	</div>
</ng-container>
