<div class="cm-service-issue form-field">
	<button class="btn btn-primary" (click)="createNew($event)">Add Issue</button>
	<div class="single-issue" *ngFor="let issue of rows; index as i; first as f; last as l">
		<div class="issue-fields">
			<div class="row">
				<div class="col-12 col-md-6">
					<cm-field-text
						[(value)]="issue.issueText"
						(valueChange)="valueChanged()"
						[label]="'Issue'"
					></cm-field-text>
				</div>
				<div class="col-12 col-md-6">
					<cm-field-file
						[label]="'Issue Image'"
						(valueChange)="fileValueChanged(issue, 'issueFile', $event)"
					></cm-field-file>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-4">
					<cm-field-text
						[(value)]="issue.partText"
						(valueChange)="valueChanged()"
						[label]="'Part Name'"
					></cm-field-text>
				</div>
				<div class="col-12 col-md-4">
					<cm-field-number
						[(value)]="issue.partQty"
						(valueChange)="valueChanged()"
						[label]="'Part Qty'"
					></cm-field-number>
				</div>
				<div class="col-12 col-md-4">
					<cm-field-file
						[label]="'Part Image'"
						(valueChange)="fileValueChanged(issue, 'partFile', $event)"
					></cm-field-file>
				</div>
			</div>
			<div class="row" *ngIf="!f">
				<div class="col-12 mt-1">
					<button class="btn btn-danger" (click)="removeIssue(i, $event)">Remove</button>
				</div>
			</div>
		</div>
		<hr class="col-12" *ngIf="!l" />
	</div>
</div>
