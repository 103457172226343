<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div *ngIf="cannedResponses; else loading" ngbDropdown>
	<button type="button" class="btn btn-outline-dark" id="{{ id }}-dropdown" ngbDropdownToggle>
		Select Canned Response
	</button>
	<div ngbDropdownMenu attr.aria-labelledby="{{ id }}-dropdown" class="overflow-scroll" style="max-height:300px;">
		<ng-container *ngFor="let cat of cannedResponseCats">
			<h6 class="dropdown-header">{{ cat }}</h6>
			<button
				type="button"
				*ngFor="let response of cannedResponses.get(cat).unwrap()"
				class="dropdown-item"
				(click)="onCannedResponseClick(response.value)"
			>
				{{ response.text }}
			</button>
		</ng-container>
	</div>
</div>
<textarea
	#input
	[name]="name"
	[disabled]="disabled"
	[required]="required"
	[attr.minlength]="minLen"
	[attr.maxlength]="maxLen"
	class="form-control mt-2"
	[(ngModel)]="value"
	(ngModelChange)="valueChange.emit($event)"
	(focus)="focused = true"
	(blur)="validate()"
></textarea>
<div *ngIf="!hideCount && focused && maxLen" class="small">{{ input.value.length }} / {{ maxLen }}</div>
<div class="invalid-feedback">{{ error }}</div>

<ng-template #loading>
	<ngb-progressbar type="info" [value]="100" [striped]="true" [animated]="true">Loading...</ngb-progressbar>
</ng-template>
