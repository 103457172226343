<ng-container>
	<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<input
		#input
		type="number"
		[id]="id"
		[name]="name"
		[disabled]="disabled"
		[required]="required"
		[attr.autocomplete]="autocomplete"
		[attr.pattern]="pattern"
		[(ngModel)]="value"
		(ngModelChange)="valueChange.emit($event)"
		[ngStyle]="inputStyle"
		class="form-control"
		step="any"
		(blur)="validate()"
	/>
	<div class="invalid-feedback">{{ error }}</div>
</ng-container>
