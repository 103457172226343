import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-scroll-arrows",
	template: `
		<ng-container *ngIf="visible < total">
			<button
				type="button"
				class="btn btn-secondary mr-3"
				[disabled]="!loop && offset <= 0"
				(click)="arrowClick(-1)"
			>
				<fa-icon [icon]="faArrowLeft"></fa-icon>
			</button>
			<cm-scroll [offset]="offset" [visible]="visible" [total]="total" class="flex-grow-1"></cm-scroll>
			<button
				type="button"
				class="btn btn-secondary ml-3"
				[disabled]="!loop && offset >= total - advance"
				(click)="arrowClick(1)"
			>
				<fa-icon [icon]="faArrowRight"></fa-icon>
			</button>
		</ng-container>
	`,
	styles: [
		`
			:host {
				display: flex;
				align-items: center;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollArrowsComponent {
	@Input() offset = 0;
	@Input() visible = 1;
	@Input() total = 10;
	@Input() advance = 1;
	@Input() loop = false;

	@Output() offsetChange = new EventEmitter<number>();

	faArrowLeft = faArrowLeft;
	faArrowRight = faArrowRight;

	arrowClick(dir: number) {
		let offset = this.offset + this.advance * dir;
		if (this.loop) {
			offset = (offset + this.total) % this.total;
		}
		this.offsetChange.next(offset);
	}
}
