import { Injectable } from "@angular/core";
import { CacheService } from "./cache.service";

@Injectable({ providedIn: "root" })
export class RestHelperService {
	deferredRequests: any = [];
	timeoutMs: any = 300;
	timeout: any = null;
	queueTimeout: any = null;
	queueTimeoutMs: any = 300;
	cache: any = null;
	queue: any = [];

	constructor(public cacheService: CacheService) {
		this.cache = this.cacheService.init("sessionStorage");
	}

	defer(request: any) {
		this.deferredRequests.push(request);
	}

	runDeferredRequests() {
		clearTimeout(this.timeout);

		this.timeout = setTimeout(() => {
			for (const request of this.deferredRequests) {
				request
					.makeRequest(request.httpVerb, request.data, request.id, request.cacheKey)
					.then(request.callback);
			}

			this.deferredRequests = [];
		}, this.timeoutMs);
	}
}
