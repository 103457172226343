<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<textarea
	#input
	[id]="id"
	[name]="name"
	[placeholder]="placeholder"
	[disabled]="disabled"
	[required]="required"
	[attr.minlength]="minLen"
	[attr.maxlength]="maxLen"
	[(ngModel)]="value"
	(ngModelChange)="onModelChange()"
	class="form-control"
	(focus)="focused = true"
	(blur)="validate()"
></textarea>
<div *ngIf="!hideCount && focused && maxLen" class="small">{{ input.value.length }} / {{ maxLen }}</div>
<div class="invalid-feedback">{{ error }}</div>
