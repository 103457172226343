<cm-label *ngIf="label" [text]="label"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div *ngIf="brands; else loading" class="form-row">
	<ng-container *ngTemplateOutlet="col; context: { $implicit: units[0] }"></ng-container>
	<ng-container *ngTemplateOutlet="col; context: { $implicit: units[1] }"></ng-container>
	<ng-container *ngTemplateOutlet="col; context: { $implicit: units[2] }"></ng-container>
</div>

<ng-template #col let-unit>
	<div class="col-4">
		<select class="form-control" [(ngModel)]="unit.brand" (ngModelChange)="onBrandSelect(unit)">
			<option value=""></option>
			<option *ngFor="let brand of brands" [ngValue]="brand">{{ brand.name }}</option>
		</select>
		<div *ngIf="unit.brand" class="mt-2">
			<select
				*ngIf="unit.listings; else loading"
				class="form-control"
				[(ngModel)]="unit.listing"
				(ngModelChange)="onListingSelect(unit)"
			>
				<option value="" disabled>Choose model</option>
				<option *ngFor="let listing of unit.listings" [ngValue]="listing">
					{{ listing.year }} {{ listing.modelNum }}
					<ng-container *ngIf="listing.subvin">- {{ listing.subvin }}</ng-container>
				</option>
			</select>

			<ng-container *ngIf="unit.listing">
				<cm-image2
					*ngIf="unit.listing.image"
					[image]="unit.listing.image"
					class="d-block text-center mt-2"
				></cm-image2>
				<div class="mt-2">
					{{ unit.listing.condition }} {{ unit.listing.year }} {{ unit.brand.name }}
					{{ unit.listing.modelNum }}
				</div>
				<div class="my-2">
					<span *ngIf="pageData.appInfo.data.siteConfigId != 17"
						>Wholesale Price: {{ unit.listing.wholesalePrice | currency: "USD" }}<br
					/></span>
					<span *ngIf="pageData.appInfo.data.siteConfigId == 15"
						>Profit Protection: {{ unit.listing.wholesalePrice * 1.04 + 595 | currency: "USD" }}<br
					/></span>
					Sale Price: {{ unit.listing.price | currency: "USD" }}
				</div>
				<ng-container *ngIf="unit.specs; else loading">
					<div class="d-flex" *ngIf="unit.specs.sleeps">
						<strong class="flex-grow-1">Sleeps</strong> {{ unit.specs.sleeps }}
					</div>
					<div class="d-flex" *ngIf="unit.specs.slides">
						<strong class="flex-grow-1">Slides</strong> {{ unit.specs.slides }}
					</div>
					<div class="d-flex" *ngIf="unit.specs.hitchWeight">
						<strong class="flex-grow-1">Hitch Weight</strong> {{ unit.specs.hitchWeight }} lbs.
					</div>
					<div class="d-flex" *ngIf="unit.specs.shipWeight">
						<strong class="flex-grow-1">Ship Weight</strong> {{ unit.specs.shipWeight }} lbs.
					</div>
					<div class="d-flex" *ngIf="unit.specs.gvwr">
						<strong class="flex-grow-1">GVWR</strong> {{ unit.specs.gvwr }} lbs.
					</div>
					<div class="d-flex" *ngIf="unit.specs.length.feet">
						<strong class="flex-grow-1">Length</strong> {{ unit.specs.length.feet }}'
						{{ unit.specs.length.feet }}"
					</div>
					<div class="d-flex" *ngIf="unit.specs.height.feet">
						<strong class="flex-grow-1">Height</strong> {{ unit.specs.height.feet }}'
						{{ unit.specs.height.feet }}"
					</div>
					<div class="d-flex" *ngIf="unit.specs.width.feet">
						<strong class="flex-grow-1">Width</strong> {{ unit.specs.width.feet }}'
						{{ unit.specs.width.feet }}"
					</div>
					<div class="d-flex" *ngIf="unit.specs.freshWater">
						<strong class="flex-grow-1">Fresh Water</strong> {{ unit.specs.freshWater }} gal
					</div>
					<div class="d-flex" *ngIf="unit.specs.grayWater">
						<strong class="flex-grow-1">Gray Water</strong> {{ unit.specs.grayWater }} gal
					</div>
					<div class="d-flex" *ngIf="unit.specs.blackWater">
						<strong class="flex-grow-1">Black Water</strong> {{ unit.specs.blackWater }} gal
					</div>
					<div class="d-flex" *ngIf="unit.specs.lpCapacity">
						<strong class="flex-grow-1">L.P. Capacity</strong> {{ unit.specs.lpCapacity }} lbs.
					</div>
					<div class="d-flex" *ngIf="unit.specs.tireSize">
						<strong class="flex-grow-1">Tire Size</strong> {{ unit.specs.tireSize }}
					</div>
					<div class="d-flex" *ngIf="unit.specs.furnaceBtu">
						<strong class="flex-grow-1">Furnace BTU</strong> {{ unit.specs.furnaceBtu }}
					</div>
				</ng-container>
				<cm-image2
					*ngIf="unit.listing.floorplan"
					[image]="unit.listing.floorplan"
					class="d-block text-center mt-2"
				></cm-image2>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #loading>
	<ngb-progressbar type="info" [value]="100" [striped]="true" [animated]="true">Loading...</ngb-progressbar>
</ng-template>
