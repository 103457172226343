import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { faCheckCircle, faSpinner, faTrash, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { Apollo, gql } from "apollo-angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { map, scan, startWith, switchMap, tap } from "rxjs/operators";
import { UploadService } from "shared";
import { iter, iterFiles, tuple } from "shared/common";

@Component({
	selector: "cm-import-wizard",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<button *ngIf="currentFile" class="btn btn-sm btn-info" (click)="currentFile = null">
						Upload Another
					</button>
				</div>
				<div
					class="col-12 order-2 order-lg-1 border-lg-right"
					[ngClass]="{
						'col-lg-2': pageData.appInfo.data.siteConfigId === 12,
						'col-lg-4': pageData.appInfo.data.siteConfigId !== 12
					}"
				>
					<div *ngFor="let file of uploadedFiles$ | async">
						<div class="d-flex align-items-center">
							<div
								class="file py-3 d-flex flex-grow-1"
								[ngClass]="{
									disabled: pageData.appInfo.data.siteConfigId !== 12 || !file.relatedFiles.length,
									selectable: file.relatedFiles.length,
									current: currentFile === file
								}"
								(click)="pageData.appInfo.data.siteConfigId === 12 && switchFile(file)"
							>
								<div class="px-3">
									<fa-icon
										*ngIf="!file.relatedFiles.length"
										[icon]="faSpinner"
										[fixedWidth]="true"
										[spin]="true"
										[pulse]="true"
									></fa-icon>
									<fa-icon
										*ngIf="file.relatedFiles.length"
										[icon]="faCheckCircle"
										[fixedWidth]="true"
										class="text-success"
									></fa-icon>
								</div>
								<div *ngIf="pageData.appInfo.data.siteConfigId === 12" class="px-3">
									{{ file.added_at | date : "shortDate" }}
								</div>
								<div class="px-3 filename">{{ file.filename }}</div>
							</div>
							<div *ngIf="pageData.appInfo.data.siteConfigId === 12" class="px-3">
								<button
									type="button"
									class="btn btn-sm btn-outline-dark"
									(click)="deleteFile(file.fileid)"
								>
									<fa-icon [icon]="faTrash"></fa-icon>
								</button>
							</div>
						</div>
						<small *ngIf="pageData.appInfo.data.siteConfigId === 12">{{ file.siteConfig.dealer }}</small>
					</div>
				</div>
				<div
					class="col-12 col-lg-10 order-1 order-lg-2"
					[ngClass]="{
						'col-lg-10': pageData.appInfo.data.siteConfigId === 12,
						'col-lg-8': pageData.appInfo.data.siteConfigId !== 12
					}"
				>
					<ng-container *ngIf="!currentFile">
						<cm-dropzone
							*ngIf="!(uploadingBS | async); else uploading"
							(click)="fileClick(33)"
							(filesDropped)="fileDrop($event, 33)"
						>
							<div class="row">
								<p class="col-12 m-0">
									<fa-icon [icon]="faUpload" [fixedWidth]="true" style="font-size:3rem;"></fa-icon>
								</p>
								<p class="col-12 m-0">Click or Drag & Drop Your Files Here</p>
							</div>
						</cm-dropzone>
						<ng-template #uploading>
							<ngb-progressbar type="info" [value]="100" [striped]="true" [animated]="true"
								>Uploading...</ngb-progressbar
							>
						</ng-template>
					</ng-container>
					<div *ngIf="currentFile" class="row">
						<div class="col-12 col-lg-6">
							<object
								[data]="currentFile.url"
								type="application/pdf"
								class="position-sticky"
								style="height: calc(100vh - 3rem); width:100%; top: 1.5rem"
							>
								<p>This browser does not support PDFs.</p>
							</object>
						</div>
						<div class="col-12 col-lg-6">
							<cm-import-listing
								[fileid]="currentFile.fileid"
								[path]="
									'https://dfm-cdn.com/' +
									currentFile.file_dir +
									currentFile.filename +
									'-blocks.json'
								"
								(saved)="currentFile = null; this.uploadedFilesRefresh.next()"
							></cm-import-listing>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			.filename {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.file {
				max-width: calc(100% - 4rem);
			}
			.file.selectable:hover {
				background: var(--gray);
				color: white;
				cursor: pointer;
			}
			.file.disabled:hover {
				background: unset;
				color: unset;
				cursor: unset;
			}
			.file.current {
				background: var(--teal);
			}
		`,
	],
})
export class ImportWizardComponent {
	uploadedFilesRefresh = new Subject();
	uploadedFiles$ = this.uploadedFilesRefresh.pipe(
		startWith(0),
		switchMap(() => this.apollo.query<any>({ query: FILES, fetchPolicy: "network-only" })),
		switchMap((res) => {
			const files = iter(res.data.files)
				.map((x: any) =>
					tuple(x.fileid, {
						...x,
						url: this.sanitizer.bypassSecurityTrustResourceUrl(
							"https://dfm-cdn.com/" + x.file_dir + x.filename,
						),
					}),
				)
				.toMap();
			return this.apollo.subscribe<any>({ query: TEXTRACT_SUB }).pipe(
				scan((acc, curr) => {
					const x = acc.get(curr.data.textract);
					if (x) x.relatedFiles = [{ fileid: 0 }];
					return new Map(acc);
				}, files),
				startWith(files),
			);
		}),
		map((files) => Array.from(files.values())),
		tap(() => this.uploadingBS.next(false)),
	);
	faUpload = faUpload;
	faSpinner = faSpinner;
	faCheckCircle = faCheckCircle;
	faTrash = faTrash;

	currentFile: any = null;
	uploadingBS = new BehaviorSubject(false);

	constructor(
		public sanitizer: DomSanitizer,
		@Inject("PAGE_DATA") public pageData: any,
		private apollo: Apollo,
		private cd: ChangeDetectorRef,
		private upload: UploadService,
		private toastr: ToastrService,
	) {}

	deleteFile(fileid: number) {
		this.uploadingBS.next(true);
		this.apollo

			.mutate({ mutation: DELETE_FILE, variables: { fileid } })
			.subscribe(() => this.uploadedFilesRefresh.next());
	}

	fileDrop(files: File[] | null, file_catid: number) {
		if (!files?.length) {
			this.toastr.error("Failed to read file(s). Please try again.");
			return;
		}

		this.uploadingBS.next(true);
		const uploads = [];
		for (const file of files) {
			uploads.push(
				this.upload
					.uploadFile(file, file_catid)
					.pipe(
						switchMap((res) =>
							this.apollo.mutate({ mutation: TEXTRACT, variables: { fileid: res.fileid } }),
						),
					),
			);
		}
		combineLatest(uploads).subscribe(() => {
			this.uploadedFilesRefresh.next();
			this.uploadingBS.next(false);
		});
	}

	fileClick(file_catid: number) {
		const input = document.createElement("input");
		input.type = "file";
		input.onchange = () => this.fileDrop(iterFiles(input.files!).toArray(), file_catid);
		input.click();
	}

	switchFile(file: any) {
		if (file.relatedFiles.length) {
			this.currentFile = null;
			setTimeout(() => {
				this.currentFile = file;
				this.cd.markForCheck();
			});
		}
	}
}

const DELETE_FILE = gql`
	mutation DeleteFile($fileid: Int!) {
		deleteFile(fileid: $fileid)
	}
`;

const TEXTRACT = gql`
	mutation Textract($fileid: Int!) {
		textract(fileid: $fileid)
	}
`;

const TEXTRACT_SUB = gql`
	subscription TextractSub {
		textract
	}
`;

const FILES = gql`
	query Files {
		files(filters: { file_catids: [33], hasListingInvoice: false }, take: 10) {
			fileid
			file_dir
			filename
			added_at
			relatedFiles {
				fileid
			}
			siteConfig {
				dealer
			}
		}
	}
`;
