import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FieldOption } from "@core/app/form/field";

@Component({
	selector: "cm-field-radio",
	templateUrl: "./field-radio.component.html",
	styles: [":host { display: block; }"],
})
export class FieldRadioComponent {
	@Input() name!: string;
	@Input() options: FieldOption[] | null = null;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() value: string | number = "";

	@Output() valueChange = new EventEmitter<string>();
}
