import { HttpClient } from "@angular/common/http";
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from "@angular/core";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Apollo, gql } from "apollo-angular";
import levenshtein from "damerau-levenshtein";
import { format } from "date-fns-2";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Observable, of, OperatorFunction, ReplaySubject } from "rxjs";
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	shareReplay,
	switchMap,
	tap,
	withLatestFrom,
} from "rxjs/operators";
import { iter, SMap, tuple } from "shared/common";
import { TextractService } from "./textract.service";

@Component({
	selector: "cm-import-listing",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="form-row">
			<div class="col-6 form-group">
				<label for="site">Site</label>
				<select
					class="form-control"
					id="site"
					[ngModel]="site_configidBS | async"
					(ngModelChange)="site_configidBS.next($event)"
					required
				>
					<option *ngFor="let siteConfig of sites$ | async" [ngValue]="siteConfig.site_configid">
						{{ siteConfig.dealer }}
					</option>
				</select>
			</div>

			<div *ngIf="site_configidBS | async" class="col-6 form-group">
				<label for="vin">VIN</label>
				<input class="form-control" id="vin" [ngModel]="vinBS | async" (ngModelChange)="vinBS.next($event)" />
			</div>

			<div *ngIf="site_configidBS | async" class="col-6 form-group">
				<a href="javascript:void(0)" (click)="showMnSearch = !showMnSearch">
					<fa-icon [icon]="faSearch"></fa-icon> Search for Model Num
				</a>
				<input
					*ngIf="showMnSearch"
					autofocus
					class="form-control"
					[ngbTypeahead]="searchMn"
					[resultTemplate]="rt"
					(selectItem)="
						lockModelNum = true;
						showMnSearch = false;
						mfridBS.next($event.item.brand.mfr.mfrid);
						brandidBS.next($event.item.brand.brandid);
						model_numidBS.next($event.item.model_numid)
					"
				/>
				<ng-template #rt let-result="result" let-term="term">
					<ngb-highlight [result]="result?.model_num" [term]="term" class="mr-2"></ngb-highlight>
					{{ result?.brand.mfr.mfr }} {{ result?.brand.brand }}
				</ng-template>
			</div>

			<div class="col-6 form-group">
				<ng-container *ngIf="listing$ | async as listing">
					Found existing listing. Clicking save will update it.
				</ng-container>
			</div>

			<div *ngIf="(site_configidBS | async) && (vinBS | async)" class="col-6 form-group">
				<label for="mfr">Manufacturer</label>
				<select
					class="form-control"
					id="mfr"
					[ngModel]="mfridBS | async"
					(ngModelChange)="mfridBS.next($event); lockModelNum = false"
				>
					<option *ngFor="let mfr of mfrs$ | async" [ngValue]="mfr.mfrid">{{ mfr.mfr }}</option>
				</select>
			</div>

			<ng-container *ngIf="site_configidBS | async">
				<div *ngIf="mfridBS | async as mfrid" class="col-6 form-group">
					<label for="brand">Brand</label>
					<select
						class="form-control"
						id="brand"
						[ngModel]="brandidBS | async"
						(ngModelChange)="brandidBS.next($event); lockModelNum = false"
					>
						<option *ngFor="let brand of (brandsByMfr$ | async)?.get(mfrid)" [ngValue]="brand.brandid">
							{{ brand.brand }}
						</option>
					</select>
				</div>
			</ng-container>

			<ng-container *ngIf="brandidBS | async as brandid">
				<div class="col-6 form-group">
					<label for="model-num">Model Num</label>
					<select
						class="form-control"
						id="model-num"
						[ngModel]="model_numidBS | async"
						(ngModelChange)="model_numidBS.next($event); lockModelNum = false"
					>
						<option
							*ngFor="let modelNum of (modelNumsByBrand$ | async)?.get(brandid)"
							[ngValue]="modelNum.model_numid"
						>
							{{ modelNum.model_num }}
						</option>
					</select>
				</div>
			</ng-container>

			<ng-container *ngIf="model_numidBS | async as model_numid">
				<div class="col-6 form-group">
					<label for="model-num-year">Model Year</label>
					<select
						class="form-control"
						id="model-num-year"
						[ngModel]="model_num_yearidBS | async"
						(ngModelChange)="model_num_yearidBS.next($event)"
					>
						<option
							*ngFor="let modelNumYear of modelNumYears$ | async"
							[ngValue]="modelNumYear.model_num_yearid"
						>
							{{ modelNumYear.modelYear.model_year }}
						</option>
					</select>
				</div>
			</ng-container>

			<ng-container *ngIf="model_num_yearidBS | async">
				<ng-container *ngIf="mny_brand_optidsBS | async as mny_brand_optids">
					<div *ngFor="let group of optGroups$ | async" class="col-6 form-group">
						{{ group.option_group }}
						<div
							*ngFor="let opt of (mnyOpts$ | async)!.get(group.option_groupid).unwrapOr([])"
							class="form-check"
						>
							<input
								class="form-check-input"
								type="checkbox"
								value=""
								id="opt-{{ opt.mny_brand_optionid }}"
								[ngModel]="mny_brand_optids.has(opt.mny_brand_optionid)"
								(ngModelChange)="
									$event
										? mny_brand_optids.add(opt.mny_brand_optionid)
										: mny_brand_optids.delete(opt.mny_brand_optionid);
									mny_brand_optidsBS.next(mny_brand_optids)
								"
							/>
							<label class="form-check-label" for="opt-{{ opt.mny_brand_optionid }}">
								{{ opt.brandOption.brand_option }} - {{ opt.brandOption.option_cost | currency }} (MSRP
								{{ opt.brandOption.option_msrp | currency }})
							</label>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="customOptionsBS | async as customOptions">
					<div class="col-7 form-group">Name</div>
					<div class="col-2 form-group">Cost</div>
					<div class="col-2 form-group">MSRP</div>
					<ng-container *ngFor="let opt of customOptions; index as i">
						<div class="col-7 form-group">
							<input
								class="form-control"
								[(ngModel)]="opt.name"
								(ngModelChange)="customOptionsBS.next(customOptionsBS.value)"
							/>
						</div>
						<div class="col-2 form-group">
							<input
								type="number"
								class="form-control"
								[(ngModel)]="opt.cost"
								(ngModelChange)="customOptionsBS.next(customOptionsBS.value)"
							/>
						</div>
						<div class="col-2 form-group">
							<input
								type="number"
								class="form-control"
								[(ngModel)]="opt.msrp"
								(ngModelChange)="customOptionsBS.next(customOptionsBS.value)"
							/>
						</div>
						<div class="col-1 form-group">
							<a
								href="javascript:void(0)"
								(click)="customOptions.splice(i, 1); customOptionsBS.next(customOptions)"
								>x</a
							>
						</div>
					</ng-container>
					<div class="col-12 form-group">
						<button
							type="button"
							class="btn btn-secondary"
							(click)="
								customOptions.push({ name: '', cost: '', msrp: '' });
								customOptionsBS.next(customOptions)
							"
						>
							Add Custom Option
						</button>
					</div>
				</ng-container>

				<ng-container *ngIf="customDiscountsBS | async as customDiscounts">
					<div class="col-7 form-group">Name</div>
					<div class="col-2 form-group">Discount</div>
					<div class="col-2 form-group">MSRP</div>
					<ng-container *ngFor="let opt of customDiscounts; index as i">
						<div class="col-7 form-group">
							<input
								class="form-control"
								[(ngModel)]="opt.name"
								(ngModelChange)="customDiscountsBS.next(customDiscountsBS.value)"
							/>
						</div>
						<div class="col-2 form-group">
							<input
								type="number"
								class="form-control"
								[(ngModel)]="opt.cost"
								(ngModelChange)="customDiscountsBS.next(customDiscountsBS.value)"
							/>
						</div>
						<div class="col-2 form-group">
							<input
								type="number"
								class="form-control"
								[(ngModel)]="opt.msrp"
								(ngModelChange)="customDiscountsBS.next(customDiscountsBS.value)"
							/>
						</div>
						<div class="col-1 form-group">
							<a
								href="javascript:void(0)"
								(click)="customDiscounts.splice(i, 1); customDiscountsBS.next(customDiscounts)"
								>x</a
							>
						</div>
					</ng-container>
					<div class="col-12 form-group">
						<button
							type="button"
							class="btn btn-secondary"
							(click)="
								customDiscounts.push({ name: '', cost: '', msrp: '' });
								customDiscountsBS.next(customDiscounts)
							"
						>
							Add Custom Discount
						</button>
					</div>
				</ng-container>

				<div class="col-6 form-group">
					<label for="location">Location <i>(required)</i></label>
					<select class="form-control" id="location" required [(ngModel)]="locationid">
						<option *ngFor="let location of locations$ | async" [ngValue]="location.locationid">
							{{ location.location }}
						</option>
					</select>
				</div>
				<div class="col-6 form-group">
					<label for="capital-source">Capital Source</label>
					<select class="form-control" id="capital-source" required [(ngModel)]="capital_sourceid">
						<option [ngValue]="null">None</option>
						<option
							*ngFor="let capitalSource of capitalSources$ | async"
							[ngValue]="capitalSource.capital_sourceid"
						>
							{{ capitalSource.capital_source }}
						</option>
					</select>
				</div>
				<div class="col-4 form-group">
					<label for="cost-base-override">Cost Base Override</label>
					<input
						id="cost-base-override"
						class="form-control"
						[ngModel]="costBaseOverrideBS | async"
						(ngModelChange)="costBaseOverrideBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label for="msrp-base-override">MSRP Base Override</label>
					<input
						id="msrp-base-override"
						class="form-control"
						[ngModel]="msrpBaseOverrideBS | async"
						(ngModelChange)="msrpBaseOverrideBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label for="msrp-discount">MSRP Discount <i>(required)</i></label>
					<input
						id="msrp-discount"
						class="form-control"
						required
						[ngModel]="msrpDiscountBS | async"
						(ngModelChange)="msrpDiscountBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label for="std-discount">Standard Discount <i>(required)</i></label>
					<input
						id="std-discount"
						class="form-control"
						required
						[ngModel]="stdDiscountBS | async"
						(ngModelChange)="stdDiscountBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label for="sales-allowance">Sales Allowance <i>(required)</i></label>
					<input
						id="sales-allowance"
						class="form-control"
						required
						[ngModel]="salesAllowanceBS | async"
						(ngModelChange)="salesAllowanceBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label>Sales Allowance as %</label>
					<div class="d-flex">
						<div class="form-check mr-2">
							<input
								class="form-check-input"
								type="radio"
								name="sales-allowance-pct"
								id="sales-allowance-pct-yes"
								[value]="true"
								[ngModel]="salesAllowancePctBS | async"
								(ngModelChange)="salesAllowancePctBS.next($event)"
							/>
							<label class="form-check-label" for="sales-allowance-pct-yes">Yes</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								name="sales-allowance-pct"
								id="sales-allowance-pct-no"
								[value]="false"
								[ngModel]="salesAllowancePctBS | async"
								(ngModelChange)="salesAllowancePctBS.next($event)"
							/>
							<label class="form-check-label" for="sales-allowance-pct-no">No</label>
						</div>
					</div>
				</div>
				<div class="col-6 form-group">
					<label for="invoice-date">Invoice Date</label>
					<input
						id="invoice-date"
						class="form-control"
						required
						[ngModel]="invoiceDateBS | async"
						(ngModelChange)="invoiceDateBS.next($event)"
					/>
				</div>
				<div class="col-6 form-group">
					<label for="invoice-num">Invoice #</label>
					<input
						id="invoice-num"
						class="form-control"
						required
						[ngModel]="invoiceNumBS | async"
						(ngModelChange)="invoiceNumBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label for="retail-name">Retail Name</label>
					<input
						id="retail-name"
						class="form-control"
						required
						[ngModel]="retailNameBS | async"
						(ngModelChange)="retailNameBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label for="serial-num">Serial Number</label>
					<input
						id="serial-num"
						class="form-control"
						required
						[ngModel]="serialNumBS | async"
						(ngModelChange)="serialNumBS.next($event)"
					/>
				</div>
				<div class="col-4 form-group">
					<label for="stock-num">Stock #</label>
					<input
						id="stock-num"
						class="form-control"
						required
						[ngModel]="stockNumBS | async"
						(ngModelChange)="stockNumBS.next($event)"
					/>
				</div>

				<div class="col-12 form-group">
					Wholesale: {{ buildCost$ | async | currency }}<br />
					MSRP: {{ buildMsrp$ | async | currency }}
				</div>
				<div class="col-12 form-group">
					<button type="button" class="btn btn-primary" (click)="save()">Save</button>
				</div>
			</ng-container>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			:host ::ng-deep .radio-item.active > div {
				background: var(--primary);
				color: #fff;
			}
		`,
	],
})
export class ImportListingComponent implements OnChanges {
	@Input() fileid!: number;
	@Input() path!: string;

	@Output() saved = new EventEmitter<void>();

	faSearch = faSearch;

	site_configidBS = new BehaviorSubject<number | null>(null);
	mfridBS = new BehaviorSubject<number | null>(null);
	brandidBS = new BehaviorSubject<number | null>(null);
	vinBS = new BehaviorSubject<string>("");
	model_numidBS = new BehaviorSubject<number | null>(null);
	model_num_yearidBS = new BehaviorSubject<number | null>(null);
	mny_brand_optidsBS = new BehaviorSubject<Set<number>>(new Set());
	customOptionsBS = new BehaviorSubject<CustomOpt[]>([]);
	customDiscountsBS = new BehaviorSubject<CustomOpt[]>([]);
	locationid: number | null = null;
	capital_sourceid: number | null = null;
	costBaseOverrideBS = new BehaviorSubject("");
	msrpBaseOverrideBS = new BehaviorSubject("");
	msrpDiscountBS = new BehaviorSubject("");
	stdDiscountBS = new BehaviorSubject("");
	salesAllowanceBS = new BehaviorSubject("");
	salesAllowancePctBS = new BehaviorSubject(false);
	invoiceDateBS = new BehaviorSubject(format(new Date(), "yyyy-MM-dd"));
	invoiceNumBS = new BehaviorSubject("");
	retailNameBS = new BehaviorSubject("");
	serialNumBS = new BehaviorSubject("");
	stockNumBS = new BehaviorSubject("");
	showMnSearch = false;
	lockModelNum = false;

	savingBS = new BehaviorSubject(false);
	pathRS = new ReplaySubject<any>(1);

	searchMn: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			withLatestFrom(this.site_configidBS),
			switchMap(([model_num, site_configid]) =>
				model_num.length < 2
					? of([])
					: this.apollo
							.query<any>({ query: SEARCH_MNS, variables: { site_configid, model_num } })
							.pipe(map((res) => res.data.modelNums)),
			),
		);

	globalData$ = this.apollo.query<any>({ query: GET_SITES }).pipe(
		map((res) => res.data),
		shareReplay(1),
	);
	sites$ = this.globalData$.pipe(map((x) => x.siteConfigs));
	customLineItems$ = this.globalData$.pipe(map((x) => x.customLineItems));
	options$ = this.site_configidBS.pipe(
		filter((x) => !!x),
		switchMap((site_configid) =>
			this.apollo.query<any>({ query: GET_OPTIONS, variables: { site_configid } }).pipe(
				map((res) => res.data),
				shareReplay(1),
			),
		),
	);
	mfrs$ = this.options$.pipe(map((opt) => opt.mfrs));
	brandsByMfr$ = this.mfrs$.pipe(
		map((mfrs) =>
			iter(mfrs)
				.map((mfr: any) => tuple(mfr.mfrid, mfr.brands))
				.toMap(),
		),
	);
	modelNumsByBrand$ = this.brandsByMfr$.pipe(
		map((brands) =>
			iter(brands.values())
				.flatten()
				.map((brand: any) => tuple(brand.brandid, brand.modelNums))
				.toMap(),
		),
	);

	mfrsById$ = this.mfrs$.pipe(
		map((mfrs) =>
			iter(mfrs)
				.map((x: any) => tuple(x.mfrid, x))
				.toMap(),
		),
	);
	brandsById$ = this.brandsByMfr$.pipe(
		map((brands) =>
			iter(brands.values())
				.flatten()
				.map((b: any) => tuple(b.brandid, b))
				.toMap(),
		),
	);

	blocks$ = this.pathRS.pipe(
		switchMap((path) => this.textract.getResults(path)),
		shareReplay(1),
	);

	itemTables$ = this.blocks$.pipe(
		map(({ tables }) =>
			iter(tables)
				.filter((x) => !!x[0] && !!getItemNumberKey(x[0]))
				.toArray(),
		),
	);

	mfrid$ = combineLatest([this.blocks$, this.mfrs$]).pipe(
		map(([{ lines }, mfrs]) => {
			for (const line of lines) {
				for (const mfr of mfrs) {
					if (line.toLowerCase().includes(mfr.mfr.toLowerCase())) {
						return mfr.mfrid;
					}
				}
			}
			return null;
		}),
		filter((x) => !!x),
	);

	rvLine$ = combineLatest([this.itemTables$, this.mfridBS, this.mfrsById$, this.brandsByMfr$]).pipe(
		filter(([_itemTables, mfrid, _mfrsById, brands]) => !!brands.get(mfrid)),
		map(([itemTables, mfrid, mfrsById, brands]) =>
			iter<any>(brands.get(mfrid))
				.chain(
					iter(mfrsById.get(mfrid).childMfrs)
						.map((m: any) => mfrsById.get(m.mfrid).brands)
						.flatten(),
				)
				.map((brand) =>
					iter(itemTables).map((x) => {
						const descKey = getDescriptionKey(x[0])!;
						return tuple(
							brand,
							x[0][descKey]?.toLowerCase().startsWith("serial") ? x[1] : x[0],
							getDescriptionKey(x[0]),
							getItemNumberKey(x[0]),
						);
					}),
				)
				.flatten()
				.filter(([_brand, _line, k]) => !!k)
				.minByKey(([brand, line, descKey, itemNumKey]) => {
					let words = line[descKey!]
						.toLowerCase()
						.replace(
							new RegExp(`(\\b\\d\\d\\d\\d\\b|fifth\s?wheel|travel\s?trailer|${brand?.brand}|-)`, "gi"),
							"",
						)
						.trim()
						.split(" ")
						.filter((x: string) => !!x.trim());
					for (let i = 0; i < words.length; i++) {
						if (line[itemNumKey!].includes(words[i])) {
							words.splice(i, 1);
							--i;
						}
					}
					return levenshtein(brand.brand.toLowerCase(), words.join(" ")).relative;
				})
				.unwrapOr([]),
		),
		filter((x) => !!x.length),
	);

	model_numid$ = combineLatest([this.brandidBS, this.rvLine$, this.brandsById$, this.modelNumsByBrand$]).pipe(
		debounceTime(0),
		distinctUntilChanged(([x], [y]) => x === y),
		map(([brandid, [superBrand, line], brandsById, modelNumsByBrand]) => {
			const [modelNum] = getBestModelNum(brandid || superBrand.brandid, line, brandsById, modelNumsByBrand);
			return [brandsById.get(modelNum?.brandid)?.mfrid, modelNum?.brandid, modelNum?.model_numid];
		}),
	);

	vin$ = this.blocks$.pipe(
		map(
			({ keyValues, tables }) =>
				iter(keyValues)
					.filter(([k]) => k.includes("vin"))
					.map(([_, v]) => v)
					.maxByKey((x) => x.length)
					.unwrapOr(null) ||
				iter(tables)
					.map(([r0]) => r0[getDescriptionKey(r0) || ""])
					.filter((desc) => desc?.toLowerCase().startsWith("serial"))
					.map((desc) => desc.split(" ")[2])
					.nth(0)
					.unwrapOr(null),
		),
		filter((x) => !!x),
	);

	listing$ = this.vinBS.pipe(
		switchMap((vin) => (vin ? this.apollo.query<any>({ query: GET_LISTING, variables: { vin } }) : of(null))),
		map((res) => res?.data.listing),
		shareReplay(1),
	);

	modelYear$ = this.vinBS.pipe(
		filter((x) => !!x),
		switchMap((vin) => this.http.get(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`)),
		map((res: any) => res.Results[0].ModelYear),
		catchError(() => of(null)),
	);

	modelNumYears$ = combineLatest([this.site_configidBS, this.model_numidBS]).pipe(
		filter(([_, model_numid]) => !!model_numid),
		switchMap(([site_configid, model_numid]) =>
			this.apollo.query<any>({ query: GET_MODEL_NUM_YEARS, variables: { site_configid, model_numid } }),
		),
		map((res) => res.data.modelNumYears),
	);

	model_num_yearid$ = combineLatest([this.modelYear$, this.modelNumYears$]).pipe(
		map(
			([modelYear, modelNumYears]) =>
				iter<any>(modelNumYears)
					.filter((x: any) => x.modelYear.model_year === modelYear)
					.nth(0)
					.unwrapOr(null)?.model_num_yearid,
		),
	);

	mny$ = combineLatest([this.site_configidBS, this.model_num_yearidBS]).pipe(
		filter(([_, model_num_yearid]) => !!model_num_yearid),
		switchMap(([site_configid, model_num_yearid]) =>
			this.apollo.query<any>({ query: GET_MNY, variables: { site_configid, model_num_yearid } }),
		),
		map((res) => res.data.modelNumYear),
		shareReplay(1),
	);

	mnyOptsById$: Observable<Map<number, any>> = this.mny$.pipe(
		map((res) =>
			iter(res.mnyBrandOptions)
				.map((x: any) => tuple(x.mny_brand_optionid, x))
				.toMap(),
		),
		shareReplay(1),
	);

	mnyOpts$: Observable<SMap<number, any>> = this.mny$.pipe(
		map((res) =>
			iter(res.mnyBrandOptions)
				.map((x: any) => tuple(x.brandOption.brandOptionGroup.option_groupid, x))
				.toGroupMap(),
		),
		shareReplay(1),
	);

	optGroups$ = this.mnyOpts$.pipe(
		switchMap((mnyOpts) =>
			this.apollo.query<any>({
				query: GET_OPT_GROUPS,
				variables: { option_groupids: Array.from(mnyOpts.keys()) },
			}),
		),
		map((res) => res.data.optionGroups),
	);

	mny_brand_optids$ = combineLatest([
		this.customLineItems$,
		this.blocks$,
		this.itemTables$,
		this.rvLine$,
		this.mnyOpts$,
		this.mny$,
		this.brandidBS,
		this.brandsById$,
	]).pipe(
		map(([customLineItems, { keyValues }, itemTables, [_, rvLine], mnyOpts, mny, brandid, brandsById]) => {
			const byPrice = iter<any>(mnyOpts.values())
				.flatten()
				.map((opt: any) => tuple(opt.brandOption.option_cost, opt))
				.toGroupMap();

			const mny_brand_optids = new Set<number>();

			let customOptions = [];
			const customDiscounts: any[] = [];

			for (const table of itemTables) {
				for (const line of table) {
					if (line === rvLine || Object.values(line).some((x: any) => x.toLowerCase().includes("total"))) {
						continue;
					}

					const descKey = getDescriptionKey(line);
					const priceKey = getAmountKey(line);
					if (!descKey || !priceKey || !line[descKey]?.trim()) continue;

					const price = cleanPrice(line[priceKey], false);
					const priceNum = Number(price);
					if (priceNum < 0) {
						if (
							!line[descKey].toLowerCase().includes("allowance") ||
							-priceNum !== Number(mny.siteModelNumYear?.sales_allowance)
						) {
							if (line[descKey] === "VSP") {
								this.stdDiscountBS.next((-priceNum).toString());
							} else {
								customDiscounts.push({ name: line[descKey], cost: (-priceNum).toString(), msrp: "" });
							}
						}
						continue;
					}
					const opts = byPrice.get(price).unwrapOr(null);
					if (!opts) {
						if (priceNum) {
							customOptions.push({ name: line[descKey], cost: priceNum.toString() || "", msrp: "" });
						}
						continue;
					}

					const brand = brandsById.get(brandid)?.brand.toLowerCase();
					const bestOpt = iter(opts)
						.map((opt) =>
							tuple(
								opt,
								levenshtein(
									opt.brandOption.brand_option.toLowerCase(),
									line[descKey]
										.toLowerCase()
										.replace(
											new RegExp(
												`(\\b\\d\\d\\d\\d\\b|fifth\s?wheel|travel\s?trailer|${brand?.brand}|interior|decor|-)`,
												"gi",
											),
											"",
										)
										.trim(),
								).relative,
							),
						)
						.filter(([opt, score]) => (price && price !== "0") || score <= 0.5)
						.minByKey(([opt, score]) => score)
						.map(([opt]) => opt)
						.unwrapOr(null);
					if (bestOpt) {
						mny_brand_optids.add(bestOpt.mny_brand_optionid);
					} else {
						customOptions.push({ name: line[descKey], cost: price || "", msrp: "" });
					}
				}
			}

			customOptions = customOptions.concat(
				customLineItems.map((x: any) => ({
					name: x.custom_line_item,
					cost: (x.custom_line_item === "FREIGHT" && Number(keyValues.get("freight")).toString()) || "",
					msrp: "",
				})),
			);

			this.customOptionsBS.next(customOptions);
			this.customDiscountsBS.next(customDiscounts);

			return mny_brand_optids;
		}),
	);

	locations$ = this.site_configidBS.pipe(
		switchMap((site_configid) => this.apollo.query<any>({ query: GET_LOCATIONS, variables: { site_configid } })),
		map((res) => res.data.locations),
		tap((locations) => (this.locationid = locations[0].locationid)),
	);

	capitalSources$ = this.site_configidBS.pipe(
		switchMap((site_configid) =>
			this.apollo.query<any>({ query: GET_CAPITAL_SOURCES, variables: { site_configid } }),
		),
		map((res) => res.data.capitalSources),
	);

	buildCost$ = combineLatest([
		this.costBaseOverrideBS,
		this.stdDiscountBS,
		this.salesAllowanceBS,
		this.salesAllowancePctBS,
		this.customOptionsBS,
		this.customDiscountsBS,
		this.mny_brand_optidsBS,
		this.mnyOptsById$,
	]).pipe(
		map(([base, discount, salesAllowance, percent, customOptions, customDiscounts, opts, mnyOptsById]: any) => {
			salesAllowance = Number(salesAllowance) || 0;

			let cost = Number(base);
			cost += iter(opts)
				.map((opt: any) => Number(mnyOptsById.get(opt)?.brandOption.option_cost) || 0)
				.sum();
			cost += iter(customOptions)
				.filter((opt: any) => opt.cost)
				.map((opt: any) => Number(opt.cost) || 0)
				.sum();
			cost -= iter(customDiscounts)
				.filter((opt: any) => opt.cost)
				.map((opt: any) => Number(opt.cost) || 0)
				.sum();
			if (percent) {
				cost -= cost * (salesAllowance / 100);
			} else {
				cost = cost - salesAllowance;
			}
			return cost - Number(discount);
		}),
	);

	buildMsrp$ = combineLatest([
		this.msrpBaseOverrideBS,
		this.msrpDiscountBS,
		this.customOptionsBS,
		this.customDiscountsBS,
		this.mny_brand_optidsBS,
		this.mnyOptsById$,
	]).pipe(
		map(
			([base, discount, customOptions, customDiscounts, opts, mnyOptsById]: any) =>
				base -
				discount +
				iter(opts)
					.map((opt: any) => Number(mnyOptsById.get(opt)?.brandOption.option_msrp || 0))
					.sum() +
				iter(customOptions)
					.filter((opt: any) => opt.msrp)
					.map((opt: any) => Number(opt.msrp))
					.sum() +
				iter(customDiscounts)
					.filter((opt: any) => opt.msrp)
					.map((opt: any) => Number(opt.msrp) * -1)
					.sum(),
		),
	);

	constructor(
		private apollo: Apollo,
		private cd: ChangeDetectorRef,
		private http: HttpClient,
		private textract: TextractService,
		private toastr: ToastrService,
	) {
		this.mfrid$.subscribe((mfrid) => this.mfridBS.next(mfrid));
		this.vin$.subscribe((vin: any) => this.vinBS.next(vin));
		this.model_numid$.subscribe(([mfrid, brandid, model_numid]) => {
			if (!this.lockModelNum) {
				this.mfridBS.next(mfrid);
				this.brandidBS.next(brandid);
				this.model_numidBS.next(model_numid);
				this.cd.markForCheck();
			}
		});
		combineLatest([this.model_num_yearid$, this.modelNumYears$]).subscribe(([model_num_yearid, modelNumYears]) =>
			this.model_num_yearidBS.next(model_num_yearid || modelNumYears[0]?.model_num_yearid),
		);
		this.mny_brand_optids$.subscribe((mny_brand_optids) => this.mny_brand_optidsBS.next(mny_brand_optids));
		this.blocks$.subscribe(({ keyValues, words }) => {
			this.invoiceDateBS.next(getInvoiceDate(keyValues) || "");
			this.invoiceNumBS.next(keyValues.get("invoice number") || keyValues.get("invoice no.") || "");
			this.retailNameBS.next(keyValues.get("retail sold name") || "");
			this.stockNumBS.next(
				words
					.filter((x) => x.TextType === "HANDWRITING")
					.nth(0)
					.map((x) => x.Text)
					.unwrapOr(""),
			);
		});
		this.mny$.subscribe((res) => {
			this.salesAllowanceBS.next(res.siteModelNumYear?.sales_allowance);
			this.salesAllowancePctBS.next(res.siteModelNumYear?.sales_allowance_percentage);
			this.msrpBaseOverrideBS.next(res.base_msrp);
			this.msrpDiscountBS.next(res.msrp_discount);
		});
		combineLatest([this.rvLine$, this.blocks$]).subscribe(([[_, rvLine], { keyValues }]) => {
			const priceKey = getPriceKey(rvLine);
			if (priceKey) this.costBaseOverrideBS.next(cleanPrice(rvLine[priceKey], true) || "");
			const discount = rvLine["discount"] || keyValues.get("discount");
			if (discount) this.stdDiscountBS.next(cleanPrice(discount, true) || "");
		});
		this.mfridBS.pipe(distinctUntilChanged()).subscribe(() => this.brandidBS.next(null));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.path) this.pathRS.next(this.path);
	}

	save() {
		this.savingBS.next(true);
		combineLatest([this.site_configidBS, this.buildCost$, this.buildMsrp$, this.mny_brand_optidsBS, this.listing$])
			.pipe(
				switchMap(([site_configid, buildCost, buildMsrp, mny_brand_optids, listing]) =>
					this.http.post("/api/inventory/createListing", {
						site_configid,
						fileid: this.fileid,
						listingid: listing?.listingid || null,
						vin: this.vinBS.value,
						modelNumYearId: this.model_num_yearidBS.value,
						cost_base_override: this.costBaseOverrideBS.value,
						msrp_base_override: this.msrpBaseOverrideBS.value,
						standard_discount: this.stdDiscountBS.value,
						msrp_discount: this.msrpDiscountBS.value,
						sales_allowance: this.salesAllowanceBS.value,
						sales_allowance_percentage: this.salesAllowancePctBS.value,
						cost: buildCost,
						msrp: buildMsrp,
						options: Array.from(mny_brand_optids),
						customOptions: this.customOptionsBS.value.filter((x) => x.cost),
						customDiscounts: this.customDiscountsBS.value,
						invoice_date: this.invoiceDateBS.value,
						invoice_id: this.invoiceNumBS.value,
						capital_sourceid: this.capital_sourceid,
						retail_name: this.retailNameBS.value,
						serial_number: this.serialNumBS.value,
						stock_id: this.stockNumBS.value,
						locationid: this.locationid,
					}),
				),
			)
			.subscribe((res: any) => {
				if (res.success) {
					this.saved.next();
					this.toastr.success("Created listing.");
				} else {
					this.savingBS.next(false);
					this.toastr.error("Failed to create listing.");
				}
			});
	}
}

const GET_SITES = gql`
	query GetSites {
		siteConfigs(filters: { moduleid: 22 }) {
			site_configid
			dealer
		}
		customLineItems {
			custom_line_itemid
			custom_line_item
		}
	}
`;

const GET_OPTIONS = gql`
	query GetOptions($site_configid: Int!) {
		mfrs(filters: { site_configid: $site_configid, hide: null }) {
			mfrid
			mfr
			childMfrs(filters: { site_configid: $site_configid, hide: null }) {
				mfrid
			}
			brands(filters: { site_configid: $site_configid, hide: null }) {
				brandid
				mfrid
				brand
				childBrands(filters: { site_configid: $site_configid, hide: null }) {
					brandid
					childBrands(filters: { site_configid: $site_configid, hide: null }) {
						brandid
						childBrands(filters: { site_configid: $site_configid, hide: null }) {
							brandid
							childBrands(filters: { site_configid: $site_configid, hide: null }) {
								brandid
							}
						}
					}
				}
				modelNums(filters: { site_configid: $site_configid, hide: null }) {
					model_numid
					model_num
					brandid
				}
			}
		}
	}
`;

const GET_MODEL_NUM_YEARS = gql`
	query GetModelNumYears($model_numid: Int!, $site_configid: Int!) {
		modelNumYears(filters: { model_numids: [$model_numid], site_configid: $site_configid }) {
			model_num_yearid
			modelYear {
				model_yearid
				model_year
			}
		}
	}
`;

const GET_MNY = gql`
	query GetMny($model_num_yearid: Int!, $site_configid: Int!) {
		modelNumYear(model_num_yearid: $model_num_yearid) {
			base_msrp
			msrp_discount
			siteModelNumYear(filters: { site_configid: $site_configid }) {
				sales_allowance
				sales_allowance_percentage
			}
			mnyBrandOptions(filters: { orderBy: { field: mny_brand_optionid, dir: desc } }) {
				mny_brand_optionid
				brandOption {
					brand_option
					option_cost
					option_discount
					option_msrp
					brandOptionGroup {
						option_groupid
					}
				}
			}
		}
	}
`;

const SEARCH_MNS = gql`
	query SearchMns($site_configid: Int!, $model_num: String!) {
		modelNums(filters: { site_configid: $site_configid, model_num: $model_num }) {
			model_numid
			model_num
			brand {
				brandid
				brand
				mfr {
					mfrid
					mfr
				}
			}
		}
	}
`;

const GET_OPT_GROUPS = gql`
	query GetOptGroups($option_groupids: [Int!]!) {
		optionGroups(filters: { option_groupids: $option_groupids }) {
			option_groupid
			option_group
		}
	}
`;

const GET_LOCATIONS = gql`
	query GetLocations($site_configid: Int!) {
		locations(filters: { site_configid: $site_configid }) {
			locationid
			location
		}
	}
`;

const GET_CAPITAL_SOURCES = gql`
	query GetCapitalSources($site_configid: Int!) {
		capitalSources(filters: { site_configid: $site_configid }) {
			capital_sourceid
			capital_source
		}
	}
`;

const GET_LISTING = gql`
	query GetListing($vin: String!) {
		listing(vin: $vin) {
			listingid
		}
	}
`;

function getBestModelNum(
	brandid: number,
	line: any,
	brandsById: Map<number, any>,
	modelNumsByBrand: Map<number, any[]>,
): [any, number] {
	const brand = brandsById.get(brandid);
	const sanitizedDesc = line[getItemNumberKey(line)!]
		.toLowerCase()
		.replace(new RegExp(`(\\b\\d\\d\\d\\d\\b|fifth\s?wheel|travel\s?trailer|${brand?.brand}|-)`, "gi"), "");

	let minScore = Infinity;
	let minModelNum = null;

	for (const modelNum of modelNumsByBrand.get(brandid) || []) {
		const score = levenshtein(modelNum.model_num.toLowerCase(), sanitizedDesc).relative;
		if (score < minScore) {
			minScore = score;
			minModelNum = modelNum;
		}
	}
	for (const subBrand of brand?.childBrands || []) {
		const [modelNum, score] = getBestModelNum(subBrand.brandid, line, brandsById, modelNumsByBrand);
		if (score < minScore) {
			minScore = score;
			minModelNum = modelNum;
		}
	}

	return [minModelNum, minScore];
}

function cleanPrice(price: string | undefined, removeNegation: boolean) {
	const ret = price?.replace(/(\$|,|\.00)/g, "");
	if (removeNegation) {
		return ret?.replace(/-/g, "").replace(/^\((.*)\)$/, (_, p1) => p1);
	} else {
		return ret?.replace(/^\((.*)\)$/, (_, p1) => `-${p1}`);
	}
}

function getItemNumberKey(obj: any) {
	return iter(Object.keys(obj))
		.filter((k) => k.includes("item") || k.includes("description") || k.includes("allowances"))
		.nth(0)
		.unwrapOr(null);
}

function getDescriptionKey(obj: any) {
	return iter(Object.keys(obj))
		.filter((k) => k.includes("description") || k.includes("allowances"))
		.nth(0)
		.unwrapOr(null);
}

function getPriceKey(obj: any) {
	return (
		iter(Object.keys(obj))
			.filter((k) => k.includes("price"))
			.nth(0)
			.unwrapOr(null) ||
		iter(Object.keys(obj))
			.filter((k) => k.includes("amount"))
			.nth(0)
			.unwrapOr(null)
	);
}
function getAmountKey(obj: any) {
	return iter(Object.keys(obj))
		.filter((k) => k.includes("amount") || k.includes("total"))
		.nth(0)
		.unwrapOr(null);
}

function getInvoiceDate(keyValues: Map<string, string>) {
	return iter(keyValues)
		.map(([k, v]) => tuple(k, Date.parse(v)))
		.filter(([k, v]) => !!v)
		.map(([k, v]) => tuple(k, format(new Date(v), "yyyy-MM-dd")))
		.minByKey(([k, v]) => levenshtein(k, "inv date").relative)
		.map(([_, v]) => v)
		.unwrapOr(null);
}

interface CustomOpt {
	name: string;
	cost: string;
	msrp: string;
}
