/// <reference types="node" />

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlMatcher, UrlMatchResult, UrlSegment } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RootComponent } from "@core/app/pages/root/root.component";
import { RouteResolver } from "@core/app/route-resolver.service";
import { environment } from "@core/environments/environment";
import { ToastNoAnimationModule } from "ngx-toastr";
import { PrerenderModule } from "shared";
import { CoreModule } from "./core.module";
import { PAGE_DATA } from "./generated/page-data";
import { httpInterceptorsProvider } from "./http-Interceptors.provider";
import { UtilModule } from "./util/util.module";

export const routes: Routes = [
	{
		path: "become-an-agent",
		loadChildren: () => import("./pages/page-jobs/page-jobs.module").then((m) => m.PageJobsModule),
	},
	{
		matcher: routeMatcher0 as UrlMatcher,
		loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Admin",
			pageTemplateId: 88,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/new-admin).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher1 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Blog",
			pageTemplateId: 37,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/(blog))(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&blog=2&page=3",
			stmtid: 93,
		},
	},
	{
		matcher: routeMatcher2 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "BlogPost",
			pageTemplateId: 14,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^\\/blog\\/([^\\/]+)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "blog_post=1&page=2",
			stmtid: 93,
		},
	},
	{
		matcher: routeMatcher3 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Account",
			pageTemplateId: 60,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/my-account)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher4 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 104,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/contact)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher5 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 90,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/listings\\/.+\\/.+\\/.+\\/([0-9]+))(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&slug=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher6 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 94,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/listings)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher7 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Home",
			pageTemplateId: 39,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/)(\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher8 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 136,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/financing)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher9 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 206,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/compare)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher10 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 168,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/listings\\/.+?\\/.+?)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher11 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 216,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/sell-a-home)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher12 as UrlMatcher,
		loadChildren: () => import("./pages/page-about/page-about.module").then((m) => m.PageAboutModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "About",
			pageTemplateId: 224,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/about)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher13 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 228,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/lead-management).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher14 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 294,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/search\\/(\\d*))(?!\\/\\d{2,})(?:\\/([0-9]))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&site_user_saved_srchid=2&page=3",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher16 as UrlMatcher,
		loadChildren: () => import("./pages/page-buy-a-home/page-buy-a-home.module").then((m) => m.PageBuyAHomeModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 373,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/buy-a-home)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher17 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 489,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/property-type\\/.+?)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher18 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 97,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/our-offices\\/.+\\/.+)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher15 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 13,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
];

export function routeMatcher0(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/new-admin).*$/, segments, true);
}
export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/(blog))(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^\/blog\/([^\/]+)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false);
}
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/contact)(?:\?.*)?$/, segments, false);
}
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/listings\/.+\/.+\/.+\/([0-9]+))(?:\?.*)?$/, segments, false);
}
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/listings)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/)(\?.*)?$/, segments, false);
}
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/financing)(?:\?.*)?$/, segments, false);
}
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/compare)(?:\?.*)?$/, segments, false);
}
export function routeMatcher10(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/listings\/.+?\/.+?)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher11(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/sell-a-home)(?:\?.*)?$/, segments, false);
}
export function routeMatcher12(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/about)$/, segments, false);
}
export function routeMatcher13(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/lead-management).*$/, segments, false);
}
export function routeMatcher14(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/search\/(\d*))(?!\/\d{2,})(?:\/([0-9]))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher15(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher16(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/buy-a-home)(?:\?.*)?$/, segments, false);
}
export function routeMatcher17(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/property-type\/.+?)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher18(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/our-offices\/.+\/.+)(?:\?.*)?$/, segments, false);
}

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
	if (regex.test("/" + segments.map((x) => x.path).join("/"))) {
		if (hasChildRouter) {
			return { consumed: [segments[0]] };
		} else {
			return { consumed: segments };
		}
	} else {
		return null;
	}
}

@NgModule({
	declarations: [RootComponent],
	imports: [
		CommonModule,
		CoreModule.forRoot(),
		ToastNoAnimationModule.forRoot({ positionClass: "toast-top-center" }),
		PrerenderModule,
		RouterModule.forRoot(routes, {
			initialNavigation: "enabledBlocking",
			scrollPositionRestoration: "enabled",
			anchorScrolling: "enabled",
			onSameUrlNavigation: "reload",
		}),
		ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
		UtilModule,
	],
	providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }, httpInterceptorsProvider],
	entryComponents: [RootComponent],
	bootstrap: [RootComponent],
})
export class AppModule {}
