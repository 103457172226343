import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { AppService } from "@core/app/app.service";
import { GTMService } from "@core/app/gtm.service";
import { Menu2Service } from "@core/app/menu2/menu2.service";
import { faChevronRight, faClock, faMapMarkerAlt, faPhoneAlt } from "@fortawesome/pro-regular-svg-icons";
import { ToastrService } from "ngx-toastr";
import { first, map, tap } from "rxjs/operators";

@Component({
	selector: "cm-footer-stevo",
	template: `
		<div class="container-fluid px-5 py-3" itemscope itemtype="http://schema.org/WPFooter">
			<meta itemprop="copyrightYear" [content]="year" />
			<meta itemprop="copyrightHolder" [content]="app.name$ | async" />

			<div class="row">
				<div class="col-12 col-sm-6 col-xl-3 d-flex">
					<div>
						<img [src]="app.logo$ | async | image: 'm'" />
						<cm-social-links2 class="d-block text-center mt-3"></cm-social-links2>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-xl-3 mt-3 mt-sm-0">
					<ng-container *ngFor="let l of locations$ | async">
						<div class="d-flex">
							<fa-icon [icon]="faPhoneAlt" class="text-primary mr-2" [fixedWidth]="true"></fa-icon>
							<a
								(click)="linkClicked('Link Click', 'Phone Call', 'click')"
								href="tel:{{ l.org_phone_number }}"
								class="text-dark"
							>
								{{ l.org_phone_number }}
							</a>
						</div>
						<div class="d-flex mt-3">
							<fa-icon [icon]="faMapMarkerAlt" class="text-primary mr-2" [fixedWidth]="true"></fa-icon>
							<div>
								<div>{{ l.address1 }} {{ l.address2 }}</div>
								<div>{{ l.city }}, {{ l.abbrev }} {{ l.postal_code }}</div>
							</div>
						</div>
						<div class="d-flex mt-3">
							<fa-icon [icon]="faClock" class="text-primary mr-2" [fixedWidth]="true"></fa-icon>
							<div>
								<p>Location Hours</p>
								<p>
									M-F<br />
									8AM - 5PM walk ins<br />
									(Evenings By Appointment)
								</p>
								<p>
									S-S<br />
									By Appointment Only
								</p>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="col-12 col-sm-6 col-xl-3 mt-3 mt-xl-0">
					<ng-container
						*ngTemplateOutlet="menuTpl; context: { items: menu.getMenu(7) | async }"
					></ng-container>
					<ng-template #menuTpl let-items="items">
						<ul class="list-unstyled font-weight-bold text-uppercase">
							<li *ngFor="let item of items" class="mb-2">
								<fa-icon [icon]="faChevronRight" class="text-primary mr-2"></fa-icon>
								<a [routerLink]="item.url" class="text-dark">{{ item.text }}</a>
								<div class="pl-3">
									<ng-container
										*ngTemplateOutlet="menuTpl; context: { items: item.children }"
									></ng-container>
								</div>
							</li>
						</ul>
					</ng-template>
				</div>
				<div class="col-12 col-sm-6 col-xl-3 mt-3 mt-xl-0">
					<a class="text-dark font-weight-bold text-uppercase" [routerLink]="'/terms-of-use'">Terms of Use</a>
					<div class="mt-3">
						<a class="text-dark font-weight-bold text-uppercase" [routerLink]="'/privacy-policy'"
							>Privacy Policy</a
						>
					</div>
					<div class="mt-3">
						Powered by<br />
						<a class="text-dark font-weight-bold text-uppercase" href="https://designforcemarketing.com/"
							>Design Force Marketing</a
						>
					</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ["./footer-stevo.component.scss"],
})
export class FooterStevoComponent {
	faChevronRight = faChevronRight;
	faClock = faClock;
	faPhoneAlt = faPhoneAlt;
	faMapMarkerAlt = faMapMarkerAlt;

	year = new Date().getFullYear();
	locations$ = this.http.post("/api/statement/GetLocations", {}).pipe(map((res: any) => res.results));
	email: string = "";
	showModal = false;

	constructor(
		public app: AppService,
		public menu: Menu2Service,
		private http: HttpClient,
		private toastrService: ToastrService,
		private gtmService: GTMService,
	) {}

	sendEmail() {
		if (this.email.length > 0 && this.validateEmail(this.email)) {
			this.http
				.post("/api/realty/addToList", { email: this.email })
				.pipe(
					tap((response: any) => {
						if (response.success) {
							this.toastrService.success("Email Signed Up");
							this.email = "";
							this.showModal = false;
						} else {
							this.toastrService.error("Invalid email submitted");
						}
					}),
					first(),
				)
				.subscribe();
		} else {
			this.toastrService.error("Invalid email submitted");
		}
	}

	validateEmail(email: string) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return true;
		}
		return false;
	}
	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}
