<div class="cm-button hidden-print" (click)="buttonClick($event)">
	<div *ngIf="buttonType === 'button-only'" class="{{ obj && obj.css ? obj.css.classes : '' }}">
		<fa-icon *ngIf="obj && obj.icon" [icon]="obj.icon" class="mr-1"></fa-icon>
		<cm-image2 *ngIf="obj && obj.image" [image]="obj.image"></cm-image2>
		<span *ngIf="obj && obj.text" class="text" [innerHTML]="obj.text"></span>
	</div>
	<a
		*ngIf="buttonType === 'linked-button'"
		[cmHref]="obj.link"
		[cmHrefOptions]="{ preventClick: true }"
		target="{{ obj && obj.target ? obj.target : '_self' }}"
		rel="noopener {{ obj && obj.rel ? obj.rel : '' }}"
	>
		<div class="{{ obj && obj.css ? obj.css.classes : '' }}">
			<fa-icon *ngIf="obj && obj.icon" [icon]="obj.icon" class="mr-1"></fa-icon>
			<cm-image2 *ngIf="obj && obj.image" [image]="obj.image"></cm-image2>
			<span *ngIf="obj && obj.text" class="text" [innerHTML]="obj.text"></span>
		</div>
	</a>
	<button
		*ngIf="buttonType === 'submit-button'"
		type="submit"
		[disabled]="obj.disabled"
		class="{{ obj && obj.css ? obj.css.classes : '' }}"
	>
		<fa-icon *ngIf="obj && obj.icon" [icon]="obj.icon" class="mr-1"></fa-icon>
		<cm-image2 *ngIf="obj && obj.image" [image]="obj.image"></cm-image2>
		<span *ngIf="obj && obj.text" class="text" [innerHTML]="obj.text"></span>
	</button>
</div>
