<div class="cm-tab-listingviews theme-bg">
	<div class="row">
		<div
			class="h1 col-12 text-white font-normal font-weight-normal text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0 bg-primary"
		>
			Saved Search Views
		</div>
		<div class="col-12">
			<div class="py-2">
				<ngb-progressbar type="primary" [value]="100" [striped]="true" [animated]="true" *ngIf="loading"
					>Loading...</ngb-progressbar
				>
			</div>
			<table
				class="table table-sm table-striped table-hover table-responsive w-100 d-block d-md-table"
				*ngIf="savedSearches$ | async as savedSearches"
			>
				<thead>
					<tr>
						<th>Saved Search</th>
						<th class="text-center">Total Viewers</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let savedSearch of savedSearches">
						<td>
							<a [href]="savedSearch.url" target="_blank">{{ savedSearch.title }}</a>
						</td>
						<td class="text-center">
							{{ savedSearch.count }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
