import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormModule } from "@core/app/form/form.module";
import { ImageModule } from "@core/app/image/image.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from "shared";
import { CrmCardHeaderComponent } from "./components/card-header.component";
import { CrmNotesModalComponent } from "./components/notes-modal.component";
import { CrmReassignModalComponent } from "./components/reassign-modal.component";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "@core/app/layout/layout.module";

@NgModule({
	declarations: [CrmCardHeaderComponent, CrmNotesModalComponent, CrmReassignModalComponent],
	imports: [
		CommonModule,
		ImageModule,
		NgbModule,
		FontAwesomeModule,
		FormModule,
		ModalModule,
		FormsModule,
		RouterModule,
		LayoutModule,
	],
	exports: [CrmCardHeaderComponent, CrmNotesModalComponent, CrmReassignModalComponent],
})
export class CrmSharedModule {}
