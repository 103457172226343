import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SaveListingComponent } from "./components/save-listing.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AccountModule } from "@core/app/account/account.module";

@NgModule({
	declarations: [SaveListingComponent],
	imports: [CommonModule, AccountModule, FontAwesomeModule],
	exports: [SaveListingComponent],
})
export class FavoritesModule {}
