import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { IHomeTile } from "@model/home-tile";

@Component({
	selector: "cm-stevo-listing-card",
	template: `
		<div *ngIf="data" class="shadow rounded" (mouseover)="setHighlight(true)" (mouseleave)="setHighlight(false)">
			<a [routerLink]="data.content_page_url" class="text-decoration-none text-secondary">
				<div class="embed-responsive embed-responsive-16by9">
					<div class="embed-responsive-item d-flex align-items-center">
						<img
							*ngIf="data.img_dir && data.img_file"
							class="img-fluid"
							loading="lazy"
							[src]="data.img_dir + data.img_file | image : 'm'"
							alt="{{ data.address1 }} {{ data.city }}, {{ data.abbrev }} {{ data.postal_code }}"
						/>
						<div
							class="p-1 bg-secondary text-white font-weight-bold position-absolute openHouse rounded text-uppercase"
							*ngIf="data.openHouse"
						>
							{{ data.openHouse }}
						</div>
						<div
							class="py-1 px-3 bg-white text-secondary position-absolute photos text-uppercase"
							*ngIf="data.img_count"
						>
							<fa-icon [icon]="faCamera" class="mx-1"></fa-icon>{{ data.img_count }}
						</div>
					</div>
				</div>
				<div class="p-1 p-lg-2 d-flex flex-column bg-white">
					<div class="d-flex align-items-end flex-wrap text-center text-uppercase">
						<div class="col-4 my-1 my-lg-2 px-1">
							<b class="h4 text-primary"
								><img src="https://dfm-cdn.com/static/54/bed-color.png" class="mx-1" alt="beds" />{{
									data.beds_total
								}}</b
							><br />
							Beds
						</div>
						<div class="col-4 my-1 my-lg-2 px-1">
							<b class="h4 text-primary"
								><img src="https://dfm-cdn.com/static/54/bath-color.png" class="mx-1" alt="bath" />{{
									data.baths_total
								}}</b
							><br />
							Baths
						</div>
						<div class="col-4 my-1 my-lg-2 px-1">
							<b class="h4 text-primary"
								><img src="https://dfm-cdn.com/static/54/sqft-color.png" class="mx-1" alt="sq ft" />{{
									data.square_feet | number : "1.0-0"
								}}</b
							><br />
							Sq Ft
						</div>
					</div>
					<div class="d-flex flex-wrap text-tertiary align-items-center flex-grow-1">
						<div class="col-12 px-1 px-lg-2 my-2 text-center">
							{{ data.address1 }}<br />
							<span class="font-weight-bold text-uppercase">{{ data.city }}, {{ data.abbrev }}</span>
						</div>
						<div class="col-12 text-center">
							{{ data.property_type }}
						</div>
						<div class="col-12 text-center my-1 my-lg-2 text-primary font-weight-bold h4">
							{{ data.msrp | currency : undefined : "symbol" : "1.0-0" }}
						</div>
					</div>
				</div>
			</a>
			<div class="d-flex flex-wrap justify-content-center px-2 pb-3 bg-white">
				<cm-stevo-compare-button [listingid]="data.listingid" class="m-1"></cm-stevo-compare-button>
				<div
					*ngIf="!hideButtons"
					class="btn btn-primary text-white font-weight-bold m-1"
					(click)="showRequestInfo = true"
				>
					MORE INFO
				</div>
				<cm-save-listing *ngIf="!hideButtons" [listingid]="data.listingid" class="m-1"></cm-save-listing>
			</div>
		</div>

		<cm-modal [show]="showRequestInfo" [detach]="true" (showChange)="showRequestInfo = $event">
			<ng-template cmModal>
				<div class="row">
					<div class="h1 col-12 text-white text-uppercase flex-center px-3 px-sm-5 pb-3 py-sm-5 bg-primary">
						Request Info
					</div>
					<cm-form
						[formid]="71"
						class="text-center col-12"
						[submitClass]="'btn btn-primary text-white font-weight-bold m-auto rounded'"
						gtmEvent="Request Info"
						[prefill]="{ listingid: data.listingid }"
						[gtmEventLabel]="data.content_page_url"
						(onSubmit)="showRequestInfo = false"
					></cm-form>
				</div>
			</ng-template>
		</cm-modal>

		<cm-modal [show]="showRequestTour" [detach]="true" (showChange)="showRequestTour = $event">
			<ng-template cmModal>
				<div class="row">
					<div class="h1 col-12 text-white text-uppercase flex-center px-3 px-sm-5 pb-3 py-sm-5 bg-primary">
						Request A Tour
					</div>
					<cm-form
						[formid]="71"
						class="text-center col-12"
						[submitClass]="'btn btn-primary text-white font-weight-bold m-auto rounded'"
						gtmEvent="Request A Tour"
						[prefill]="{ listingid: data.listingid }"
						[gtmEventLabel]="data.content_page_url"
						(onSubmit)="showRequestTour = false"
					></cm-form>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host {
				display: inline-block;
				width: 100%;
				margin: 0.5rem 0;
			}
			.embed-responsive img {
				max-height: 100%;
				object-fit: cover;
				width: 100%;
			}
			.stripe-gray {
				background: rgba(128, 128, 128, 1);
			}

			.stripe-orange {
				background: rgba(255, 165, 0, 1);
			}

			.stripe-red {
				background: rgba(255, 0, 0, 1);
			}

			.stripe-green {
				background: #68dc1b;
			}
			.openHouse {
				top: 0;
				left: 10px;
			}
			.photos {
				bottom: 0;
				right: 10px;
			}
			.address {
				min-height: 65px;
			}
		`,
	],
})
export class StevoListingCardComponent {
	@Input() data!: IHomeTile;
	@Input() hideButtons: boolean = false;
	@Input() highlight: boolean = false;

	@Output() highlightChange: EventEmitter<boolean> = new EventEmitter();
	@Output() requestInfo: EventEmitter<number> = new EventEmitter();
	@Output() requestTour: EventEmitter<number> = new EventEmitter();

	faCamera = faCamera;

	showModal = false;
	showTourModal = false;

	showRequestInfo = false;
	showRequestTour = false;

	setHighlight(val: boolean): void {
		this.highlight = val;
		this.highlightChange.emit(val);
	}
}
