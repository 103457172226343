import { HttpClient } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { iterObj } from "@core/app/common/iter";
import { option, some } from "@core/app/common/option";
import { cloneDeep } from "@core/app/common/util";
import { Entry } from "../fieldset/fieldset.component";
import { GTMService } from "@core/app/gtm.service";
import { TrackingService } from "@core/app/tracking.service";
import { Observable } from "rxjs";
import { Field, FieldData } from "../../field";
import { FormField, FormRow } from "../../_form-data";

@Component({
	selector: "cm-form",
	templateUrl: "./form.component.html",
	styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit, OnChanges {
	@Input() formid!: string | number;
	@Input() gtmEvent?: string;
	@Input() redirect?: string;
	@Input() gtmEventAction?: string;
	@Input() gtmEventLabel?: string;
	@Input() prefill: { [key: string]: any } = {};
	@Input() siteActionId?: string | number;
	@Input() submitClass: string = "";
	@Input() submitTextOverride?: string;
	@Input() paginationPostion: string = "top";
	@Input() paginationStyle: string = "number";

	@Output() onReady: EventEmitter<void> = new EventEmitter();
	@Output() onSubmit: EventEmitter<void> = new EventEmitter();
	loadRequest: Observable<any> | null = null;

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("frm", { static: false }) form!: ElementRef<HTMLFormElement>;

	FormState = FormState;
	Math = Math;

	activePage: number = 0;
	availablePage: number = 0;
	errorMessage: string | null = null;
	oldPrefill: { [key: string]: any } = {};
	paginated: boolean = false;
	pages: Page[] = [];
	state: FormState = FormState.Loading;
	submitDisabled: boolean = false;
	formTitle: string | null = null;
	formIntro: string | null = null;
	formThankYou: string = "Submitted";
	startText: string | null = null;
	nextText: string = "Next";
	prevText: string = "Previous";
	submitText: string = "Submit";
	validated: boolean = false;
	routerParams: any = {};

	constructor(
		private cd: ChangeDetectorRef,
		private gtmService: GTMService,
		private trackingService: TrackingService,
		private router: Router,
		route: ActivatedRoute,
		private transfer: TransferRxService,
		private http: HttpClient,
	) {
		route.data
			.subscribe((data) => {
				if (data && data.routerData) {
					for (const [k, v] of iterObj(data.routeData.params)) {
						this.routerParams[k] = v;
					}
				}
			})
			.unsubscribe();
		route.queryParams
			.subscribe((params) => {
				for (const [k, v] of iterObj(params)) {
					this.routerParams[k] = v;
				}
			})
			.unsubscribe();
	}

	get maxPage(): number {
		return this.paginated ? this.availablePage + 1 : this.pages.length;
	}

	ngOnInit() {
		this.loadRequest = this.http.get(`/api/forms/meta/${this.formid}`);
		this.loadRequest.subscribe((results) => {
			this.initFromApiResult(results);
			this.fillRouteParams();
			this.prefillFields();
			setTimeout(() =>
				setTimeout(() => {
					this.onReady.emit();
				}),
			);
			this.state = FormState.Loaded;
			this.cd.markForCheck();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.prefill && !changes.prefill.firstChange) {
			this.prefill = changes.prefill.currentValue;
			this.loadRequest!.subscribe(() => this.prefillFields());
		}
		if (changes.redirect && !changes.redirect.firstChange) {
			this.redirect = changes.redirect.currentValue;
		}
		if (changes.paginationPostion) {
			this.paginationPostion = changes.paginationPostion.currentValue;
		}
		if (changes.paginationStyle) {
			this.paginationStyle = changes.paginationStyle.currentValue;
		}
		if (changes.submitClass) {
			this.submitClass = changes.submitClass.currentValue;
		}
	}

	onPageClick(index: number): void {
		this.activePage = index;

		this.sendGtmEvent();
	}

	onPrevClick(): void {
		window.scroll(0, 0);

		--this.activePage;

		this.sendGtmEvent();
	}

	onNextClick(): void {
		window.scroll(0, 0);
		if (this.activePage === this.availablePage) {
			if (this.form && !this.form.nativeElement.reportValidity()) {
				this.validated = true;
				return;
			} else {
				this.validated = false;
				++this.availablePage;
			}
		}

		++this.activePage;

		this.sendGtmEvent();
	}

	async onSubmitInternal(): Promise<void> {
		// browsers should validate the form themselves before creating a submit event, but for some reason they don't,
		// so we manually force a validity check here
		if (this.form && !this.form.nativeElement.reportValidity()) {
			return;
		}

		for (const page of this.pages) {
			for (const item of page.formItems) {
				for (const field of item.fields()) {
					if (
						field.visible() &&
						(field.field as any).required &&
						!field.getValue() &&
						field.getValue() !== 0
					) {
						this.errorMessage =
							"Error submitting form. This is probably a bug with your web browser. Updating your browser or using a different one may help.";
						throw new Error(`ngModel failed to detect value for required field ${field.name}`);
					}
				}
			}
		}

		this.errorMessage = null;
		this.submitDisabled = true;

		const submitData: any = {};
		for (const page of this.pages) {
			for (const item of page.formItems) {
				Object.assign(submitData, await item.getData());
			}
		}

		this.http.post(`/api/forms/saveRecord/${this.formid}`, submitData).subscribe(
			(response: any) => {
				if (response.success === false) {
					this.errorMessage = response.message;
					this.submitDisabled = false;
					return;
				}

				if (response.sendEvent === undefined || response.sendEvent === "send") {
					if (this.siteActionId !== undefined) {
						this.trackingService.trackVisit({ actionId: this.siteActionId });
					}

					if (this.gtmEvent !== undefined) {
						this.gtmService.track(
							this.gtmEvent,
							this.gtmEventAction || "submit",
							this.gtmEventLabel || "success",
						);
					}
				}

				this.onSubmit.emit();

				if (response.redirect) {
					this.router.navigate([response.redirect]);
				} else if (this.redirect) {
					if (this.redirect.startsWith("http") || this.redirect.startsWith("//")) {
						if (typeof window !== "undefined") {
							window.location.href = this.redirect;
						}
					} else {
						this.router.navigateByUrl(this.redirect);
					}
				}
				this.state = FormState.Submitted;
				this.cd.markForCheck();
			},
			(err) => {
				this.submitDisabled = false;
				throw err;
			},
		);
	}

	private fillRouteParams() {
		for (const page of this.pages) {
			for (const item of page.formItems) {
				for (const field of item.fields()) {
					if (this.routerParams.hasOwnProperty(field.name)) {
						field.setValue(this.routerParams[field.name]);
					}
				}
			}
		}
	}

	private prefillFields(): void {
		for (const page of this.pages) {
			for (const item of page.formItems) {
				for (const field of item.fields()) {
					if (
						this.prefill &&
						this.prefill.hasOwnProperty(field.name) &&
						(!this.oldPrefill ||
							!this.oldPrefill.hasOwnProperty(field.name) ||
							this.prefill[field.name] !== this.oldPrefill[field.name])
					) {
						field.setValue(this.prefill[field.name]);
					}
				}
			}
		}

		this.oldPrefill = cloneDeep(this.prefill);
	}

	private initFromApiResult(formMeta: any): void {
		const data = formMeta.data;
		const fieldMap = new Map<number, [any, FormField]>();

		this.formTitle = data.frm_title;
		this.formIntro = data.frm_intro;

		this.paginated = data.paginate_frm_flds === 1;
		this.startText = data.start_btn;
		if (data.next_btn) {
			this.nextText = data.next_btn;
		}
		if (data.prev_btn) {
			this.prevText = data.prev_btn;
		}
		if (data.submit_btn) {
			this.submitText = data.submit_btn;
		}
		if (data.frm_thank_you) {
			this.formThankYou = data.frm_thank_you;
		}

		for (const page of data.pages) {
			const newPage = new Page(page.frm_pg, page.frm_pg_intro);
			this.pages.push(newPage);

			let row = new FormRow();
			let fieldset = null;
			let currentFieldsetid = null;
			for (const dbfield of page.fields) {
				let newRow = false;
				let newFieldset = false;
				if (dbfield.frm_fld_col === 0) {
					newRow = true;
				}
				if (dbfield.frm_pg_fieldsetid !== currentFieldsetid) {
					newRow = true;
					newFieldset = true;
					currentFieldsetid = dbfield.frm_pg_fieldsetid;
				}

				if (newRow && row.fields.length > 0) {
					if (fieldset === null) {
						newPage.formItems.push(new FormItem(row));
					} else {
						fieldset.rows.push(row);
					}

					row = new FormRow();
				}

				if (newFieldset) {
					if (fieldset !== null) {
						newPage.formItems.push(new FormItem(fieldset));
					}

					if (dbfield.frm_pg_fieldsetid === null) {
						fieldset = null;
					} else {
						const dbfieldset = data.fieldsets[dbfield.frm_pg_fieldsetid];
						fieldset = new Fieldset(
							dbfieldset.fieldset,
							dbfieldset.fieldset_lbl,
							dbfieldset.additive === 1,
							dbfieldset.disabled === 1,
						);
					}
				}

				const fieldData = new FieldData(parseInt(dbfield.fld_defid), dbfield.frm_fld, dbfield.frm_fld_lbl);
				fieldData.typeId = some(dbfield.fld_typeid);
				fieldData.required = some(dbfield.frm_fld_req === 1);
				fieldData.intro = option(dbfield.frm_fld_intro);
				fieldData.choices = option(dbfield.choices);
				fieldData.maximum = option(dbfield.maximum).map((x) => parseInt(x));
				fieldData.stmt = option(dbfield.stmt);
				fieldData.default = dbfield.frm_fld_val
					? isNaN(dbfield.frm_fld_val)
						? option(dbfield.frm_fld_val)
						: option(parseInt(dbfield.frm_fld_val))
					: option(null);

				const field = new FormField(
					Field.fromData(fieldData),
					option(dbfield.on_frm_fld_answer_choiceid),
					option(dbfield.on_frm_fld_regex).map((x) => RegExp(x)),
				);

				row.fields.push(field);
				fieldMap.set(dbfield.frm_fldid, [dbfield, field]);
			}
			if (row.fields.length > 0) {
				if (fieldset === null) {
					newPage.formItems.push(new FormItem(row));
				} else {
					fieldset.rows.push(row);
					newPage.formItems.push(new FormItem(fieldset));
				}
			}
		}

		for (const [dbfield, field] of fieldMap.values()) {
			field.parent = option(dbfield.frm_fld_ofid).map((id) => option(fieldMap.get(id)).unwrap()[1]);
		}
	}

	private sendGtmEvent() {
		if (this.gtmEvent !== undefined) {
			this.gtmService.track(this.gtmEvent, "page " + this.activePage, "success", { step: this.activePage });
		}
	}
}

class Page {
	title: string;
	intro: string | null;
	formItems: FormItem[] = [];

	constructor(title: string, intro: string | null) {
		this.title = title;
		this.intro = intro;
	}
}

enum FormState {
	Loading,
	Loaded,
	Submitted,
}

class FormItem {
	val: FormRow | Fieldset;

	constructor(val: FormRow | Fieldset) {
		this.val = val;
	}

	asFormRow(): FormRow | null {
		if (this.val instanceof FormRow) {
			return this.val;
		} else {
			return null;
		}
	}

	asFieldset(): Fieldset | null {
		if (this.val instanceof Fieldset) {
			return this.val;
		} else {
			return null;
		}
	}

	isFormRow(): boolean {
		return this.val instanceof FormRow;
	}

	isFieldset(): boolean {
		return this.val instanceof Fieldset;
	}

	*fields(): IterableIterator<FormField> {
		if (this.val instanceof FormRow) {
			for (const field of this.val.fields) {
				yield field;
			}
		} else {
			for (const row of this.val.rows) {
				for (const field of row.fields) {
					yield field;
				}
			}
		}
	}

	async getData(): Promise<any> {
		return await this.val.getData();
	}
}

class Fieldset {
	name: string;
	legend: string;
	additive: boolean;
	disabled: boolean;
	rows: FormRow[] = [];
	entries: Entry[] = [];

	constructor(name: string, legend: string, additive: boolean, disabled: boolean) {
		this.name = name;
		this.legend = legend;
		this.additive = additive;
		this.disabled = disabled;
	}

	async getData(): Promise<any> {
		const entryDatas: any[] = [];

		for (const entry of this.entries) {
			const data: any = {};

			for (const row of entry.rows) {
				Object.assign(data, await row.getData());
			}

			entryDatas.push(data);
		}

		if (this.name) {
			const ret: any = {};
			ret[this.name] = entryDatas;
			return ret;
		} else {
			return entryDatas[0];
		}
	}
}
