import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ScrollArrowsComponent } from "./components/scroll-arrows.component";
import { ScrollComponent } from "./components/scroll.component";

@NgModule({
	declarations: [ScrollComponent, ScrollArrowsComponent],
	imports: [CommonModule, FontAwesomeModule],
	exports: [ScrollComponent, ScrollArrowsComponent],
})
export class ScrollModule {}
