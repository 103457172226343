import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ListingService } from "@core/app/listing/services/listing.service";
import { IHomeTile } from "@model/home-tile";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "cm-stevo-listing-nearby-homes",
	template: `
		<ng-container *ngIf="cards$ | async as cards">
			<ng-container *ngIf="cards.length > 0">
				<div class="row">
					<h2 class="col-6 font-weight-bold text-uppercase">{{ title }}</h2>
					<div class="col-6">
						<cm-scroll-arrows
							[(offset)]="slideshowIndex"
							[visible]="slideshowSlidesInView"
							[advance]="slideshowSlidesInView"
							[total]="cards.length"
							class="flex-grow-1"
						></cm-scroll-arrows>
					</div>
				</div>
				<cm-slider
					[slidesInView]="slidesInView"
					[arrows]="false"
					[pagination]="false"
					[(slideIndex)]="slideshowIndex"
					(currentSlidesInView)="slideshowSlidesInView = $event"
					itemscope
					itemtype="http://schema.org/OfferCatalog"
				>
					<ng-template cmSlide *ngFor="let card of cards">
						<cm-stevo-listing-card
							[data]="card"
							class="p-1"
							itemprop="itemListElement"
							itemscope
							itemtype="http://schema.org/Offer"
						></cm-stevo-listing-card>
					</ng-template>
				</cm-slider>
			</ng-container>
		</ng-container>
	`,
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StevoListingNearbyHomesComponent implements OnInit {
	@Input() title: string = "Similar Homes";
	@Input() sold: boolean = false;
	@Input() feature: boolean = false;
	@Input() pending: boolean = false;
	@Input() salespersonid?: string | number;
	@Input() notListingid?: string | number;
	@Input() limit: string | number = 12;
	@Input() zipCode: any = null;
	@Input() cityids: any = null;
	@Input() listingids?: string[] | number[];
	@Input() slidesInView?: string | number | { [key: string]: string | number } = {
		xs: 1,
		768: 2,
		1100: 3,
		1500: 4,
		1900: 5,
		2200: 6,
	};

	listingInfo: any = {};
	slideshowIndex = 0;
	slideshowSlidesInView = 1;
	cards$: Observable<IHomeTile[]> = of([]);

	constructor(private http: HttpClient, private listingService: ListingService) {}

	ngOnInit() {
		const vars = {
			postal_code: this.zipCode ? this.zipCode : undefined,
			cityids: this.cityids ? this.cityids : undefined,
			limit: this.limit,
			sold: this.sold ? true : undefined,
			feature: this.feature ? true : undefined,
			pending_sale: this.pending ? true : undefined,
			notListingid: this.notListingid,
			listingids: this.listingids ? this.listingids : undefined,
			salespersonid: this.salespersonid,
		};
		this.cards$ = this.http
			.post("/api/statement/GetHomes", {
				vars,
			})
			.pipe(
				map((response: any) => response.results.map((row: any) => this.listingService.makeHomeTileObject(row))),
			);
	}
}
