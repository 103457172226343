<cm-json-ld [json]="ld$ | async"></cm-json-ld>
<cm-header-stevo></cm-header-stevo>
<div class="site-wrapper" itemscope itemtype="http://schema.org/WebPageElement" itemprop="mainContentOfPage">
	<router-outlet></router-outlet>
</div>
<cm-footer-stevo></cm-footer-stevo>
<div toastContainer></div>
<cm-stevo-compare-popup></cm-stevo-compare-popup>
<cm-quick-bar></cm-quick-bar>
<cm-modal-outlet></cm-modal-outlet>
