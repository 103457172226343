import { HttpClient } from "@angular/common/http";
import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "cm-tab-customerviews",
	templateUrl: "./tab-customerviews.component.html",
	styleUrls: ["./tab-content.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class TabCustomerViewsComponent {
	showUser: boolean = false;

	loading = true;

	users$ = this.http.post("/api/statement/GetUserViewingReport", {}).pipe(
		map((res: any) =>
			res.results.map((row: any) => {
				const lv = row.lastVisit ? row.lastVisit.replace(" ", "T") : null;

				return {
					...row,
					lastVisit: lv ? new Date(lv).toString() : null,
				};
			}),
		),
		tap(() => (this.loading = false)),
	);

	userIdBS = new BehaviorSubject(0);

	favs$ = this.userIdBS.pipe(
		switchMap((userid) => this.http.post("/api/statement/GetUserFavListings", { vars: { userid } })),
		map((response: any) => [...new Set(response.results.map((row: any) => row.listingid))]),
	);

	cusotmerInfo$ = this.userIdBS.pipe(
		switchMap((userid) => this.http.post("/api/statement/GetCustomerInfo", { vars: { userid } })),
		map((response: any) => response.results[0]),
	);

	savedSearches$ = this.userIdBS.pipe(
		switchMap((userid) => this.http.post("api/statement/GetSiteUserSavedSearches", { vars: { userid } })),
		map((res: any) =>
			res.results.map((row: any) => ({
				...row,
				labels: JSON.parse(row.saved_srch_summary),
				values: JSON.parse(row.saved_srch_params),
			})),
		),
	);
	visits$ = this.userIdBS.pipe(
		switchMap((userid) => this.http.post("api/statement/GetListingVisitsByUser", { vars: { userid } })),
		map((res: any) =>
			res.results.map((row: any) => ({
				...row,
				lastVisit: new Date(row.lastVisit + " UTC").toString(),
			})),
		),
	);

	constructor(private http: HttpClient, private router: Router) {}

	loadSearch(search: any) {
		if (search.content_page_url) {
			this.router.navigateByUrl(search.content_page_url);
		} else {
			search.values.viewSlideshow = "false";
			this.router.navigate(["/listings"], {
				queryParams: search.values,
			});
		}
	}
}
