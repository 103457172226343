<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div *ngFor="let v of value; index as i; trackBy: trackByFn" class="d-flex" [ngClass]="{ 'mt-2': i > 0 }">
	<div class="flex-grow-1">
		<input
			#input
			type="file"
			[id]="id"
			[name]="name"
			[disabled]="disabled"
			[required]="required"
			(change)="onFileChange(i, $event.target.files)"
		/>
	</div>
	<ng-container *ngIf="i === 0; else delete">
		<button type="button" class="btn btn-primary ml-3 add-delete" (click)="onAddClick()">
			<fa-icon [icon]="faPlus"></fa-icon>
		</button>
	</ng-container>
	<ng-template #delete>
		<button type="button" class="btn btn-outline-danger ml-3 add-delete" (click)="onDeleteClick(i)">
			<fa-icon [icon]="faTimes"></fa-icon>
		</button>
	</ng-template>
</div>
<div class="invalid-feedback">{{ error }}</div>
