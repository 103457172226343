<div class="cm-quick-admin theme-bg">
	<ngb-tabset type="pills">
		<ngb-tab title="Views By User">
			<ng-template ngbTabContent>
				<cm-tab-customerviews></cm-tab-customerviews>
			</ng-template>
		</ngb-tab>

		<ngb-tab title="Views By Listing">
			<ng-template ngbTabContent>
				<cm-tab-listingviews></cm-tab-listingviews>
			</ng-template>
		</ngb-tab>
		<ngb-tab title="Saved Search Views">
			<ng-template ngbTabContent>
				<cm-tab-saved-search-views></cm-tab-saved-search-views>
			</ng-template>
		</ngb-tab>
	</ngb-tabset>
</div>
