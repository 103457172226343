import {
	Component,
	HostBinding,
	Input,
	ElementRef,
	ViewChild,
	Output,
	EventEmitter,
	Inject,
	OnInit,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { fromEvent } from "rxjs";
import { take } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { subYears, format } from "date-fns";
import { parseDate } from "../../../common/date";

let nextIdNum = 0;

const minDate = { year: subYears(new Date(), 125).getFullYear(), month: 1, day: 1 };

@Component({
	selector: "cm-field-date",
	templateUrl: "./field-date.component.html",
	styleUrls: ["./field-date.component.scss"],
})
export class FieldDateComponent implements OnInit {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() required: boolean = false;
	@Input() pattern?: string | null;
	@Input() value?: string;
	@Input() format: string = "MM/DD/YYYY";
	@Input() displayFormat: boolean = false;

	@Output() valueChange = new EventEmitter<string>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	faCalendarAlt = faCalendarAlt;
	minDate = minDate;

	idNum: number = 0;
	pickerVisible: boolean = false;
	error: string | null = null;
	ngbDate?: NgbDateStruct;
	validated: boolean = false;

	constructor(@Inject(DOCUMENT) private document: any) {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-date-${this.idNum}`;
	}

	ngOnInit(): void {
		if (this.value) {
			const date = parseDate(this.value, this.format);
			this.ngbDate = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
		}
	}

	validate() {
		if (!this.input.nativeElement.checkValidity()) {
			this.error = this.input.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}

	onDateSelect() {
		this.pickerVisible = false;
		this.value = format(new Date(this.ngbDate!.year, this.ngbDate!.month - 1, this.ngbDate!.day), this.format);
		this.validate();
		this.valueChange.emit(this.value);
	}

	onPickerToggle(event: Event) {
		event.stopPropagation();

		this.pickerVisible = !this.pickerVisible;

		if (this.pickerVisible) {
			fromEvent(this.document, "click")
				.pipe(take(1))
				.subscribe(() => {
					this.pickerVisible = false;
				});
		}
	}
}
