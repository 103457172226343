<cm-label *ngIf="label && type !== 'hidden'" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div *ngFor="let v of valueSplit; index as i" class="d-flex" [ngClass]="{ 'mt-2': i > 0 }">
	<input
		#inputs
		[type]="type"
		[id]="id"
		name="{{ name }}-{{ i }}"
		[placeholder]="placeholder"
		[disabled]="disabled"
		[required]="required && type !== 'hidden'"
		[attr.autocomplete]="autocomplete"
		[attr.maxlength]="type == 'hidden' ? null : maxLen"
		[attr.minlength]="type == 'hidden' ? null : minLen"
		[attr.pattern]="pattern"
		[(ngModel)]="v.val"
		(ngModelChange)="onModelChange()"
		[ngStyle]="inputStyle"
		class="form-control flex-grow-1"
		(blur)="validate()"
		(focus)="focused = true"
	/>
	<ng-container *ngIf="i === 0; else delete">
		<button type="button" class="btn btn-primary ml-3 add-delete" (click)="onAddClick()">
			<fa-icon [icon]="faPlus"></fa-icon>
		</button>
	</ng-container>
	<ng-template #delete>
		<button type="button" class="btn btn-outline-danger ml-3 add-delete" (click)="onDeleteClick(i)">
			<fa-icon [icon]="faTimes"></fa-icon>
		</button>
	</ng-template>
</div>
<div *ngIf="!hideCount && focused && maxLen" class="small">{{ input.value.length }} / {{ maxLen }}</div>
<div class="invalid-feedback">{{ error }}</div>
