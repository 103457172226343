import { isPlatformServer } from "@angular/common";
import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { IPageData } from "@model/page-data";

@Injectable()
export class ProxyInterceptor implements HttpInterceptor {
	constructor(@Inject(PLATFORM_ID) private platformId: object, @Inject("PAGE_DATA") private pageData: IPageData) {}
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (!req.url.startsWith("//") && !req.url.startsWith("http")) {
			const newUrl = isPlatformServer(this.platformId) ? "https://" + this.pageData.host + req.url : req.url;
			return next.handle(req.clone({ url: newUrl }));
		}
		return next.handle(req);
	}
}
