import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "cm-send-a-message-modal",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<cm-modal [show]="show" (showChange)="showChange.next(null)">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h1 col-12 text-white font-normal font-weight-normal text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0 bg-primary"
					>
						Send Me A Message!
					</div>
					<div class="col-12">
						<cm-form
							class="text-center"
							[formid]="71"
							(onSubmit)="show = false"
							[gtmEvent]="'Send A Message'"
							[submitClass]="'btn btn-primary text-white font-weight-bold m-auto rounded'"
						></cm-form>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
})
export class SendAMessageModalComponent {
	@Input() show!: any;

	@Output() showChange = new BehaviorSubject(null);
}
