import { HttpClient } from "@angular/common/http";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { map, tap } from "rxjs/operators";

@Component({
	selector: "cm-tab-listingviews",
	templateUrl: "./tab-listingviews.component.html",
	styleUrls: ["./tab-content.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class TabListingViewsComponent {
	loading = true;
	listings$ = this.http
		.post("/api/statement/GetListingVisits", { vars: { site_configid: this.pageData.appInfo.data.siteConfigId } })
		.pipe(
			map((res: any) =>
				res.results.map((row: any) => ({
					...row,
				})),
			),
			tap(() => {
				this.loading = false;
			}),
		);

	constructor(private http: HttpClient, @Inject("PAGE_DATA") private pageData: any) {}
}
