import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	QueryList,
	SimpleChanges,
} from "@angular/core";
import { faChevronLeft, faChevronRight, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { BehaviorSubject, combineLatest, interval, ReplaySubject, Subscription } from "rxjs";
import { first, map, shareReplay, takeUntil, tap } from "rxjs/operators";
import { isPrerendering } from "shared";
import { breakpointMin, iterObj, range, tuple, ViewportService } from "shared/common";
import { SlideDirective } from "./slide.directive";

/**
 * @example
 * ```html
 * <cm-slider>
 *     <ng-template cmSlide>slide content 1</ng-template>
 *     <ng-template cmSlide>slide content 2</ng-template>
 *     <ng-template cmSlide>slide content 3</ng-template>
 * </cm-slider>
 * ```
 */
@Component({
	selector: "cm-slider",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex h-100">
			<div *ngIf="arrows" class="d-flex align-items-center">
				<button
					type="button"
					class="btn btn-link p-1 p-lg-2 arrows"
					[ngClass]="{ invisible: !loop && !(slideIndexBS | async), 'arrow-abs-left': arrowsAbs }"
					(click)="prev()"
					(mouseenter)="prevMouseEnter.emit(true)"
					(mouseleave)="prevMouseLeave.emit(true)"
				>
					<fa-icon [icon]="faChevronLeft" size="3x"></fa-icon>
				</button>
			</div>

			<div [ngSwitch]="transition" class="position-relative w-100 limit-x">
				<cm-slider-impl-translate
					*ngSwitchCase="Transition.Slide"
					[center]="center"
					[fullWidth]="fullWidth"
					[fullHeight]="fullHeight"
					[loop]="loop"
					[preload]="preload$ | async"
					[slides]="(slidesRS | async).toArray()"
					[slideIndex]="slideIndexBS | async"
					[inView]="inView$ | async"
					(slideChange)="setSlide(slideIndexBS.value + $event)"
					[draggable]="draggable"
				></cm-slider-impl-translate>
				<cm-slider-impl-fade
					*ngSwitchCase="Transition.Fade"
					[center]="center"
					[fullWidth]="fullWidth"
					[loop]="loop"
					[advance]="advance$ | async"
					[preload]="preload$ | async"
					[slides]="(slidesRS | async).toArray()"
					[slideIndex]="slideIndexBS | async"
					[inView]="inView$ | async"
				></cm-slider-impl-fade>
			</div>

			<div *ngIf="arrows" class="d-flex align-items-center">
				<button
					type="button"
					class="btn btn-link p-1 p-lg-2 arrows"
					[ngClass]="{ invisible: !loop && (arrowRightInvisible$ | async), 'arrow-abs-right': arrowsAbs }"
					(click)="next()"
					(mouseenter)="nextMouseEnter.emit(true)"
					(mouseleave)="nextMouseLeave.emit(true)"
				>
					<fa-icon [icon]="faChevronRight" size="3x"></fa-icon>
				</button>
			</div>
		</div>

		<div
			*ngIf="pagination"
			class="p-2 d-none d-lg-flex flex-wrap justify-content-center pagination"
			[ngClass]="{ 'pagination-abs': paginationAbs }"
		>
			<button
				*ngFor="let offset of pageOffsets$ | async"
				type="button"
				class="btn btn-link"
				[ngClass]="{
					active:
						offset % slides.length ===
						(slideIndexBS | async) - ((slideIndexBS | async) % (advance$ | async)),
					'pagination-abs': paginationAbs
				}"
				(click)="setSlide(offset)"
			>
				<fa-icon [icon]="faCircle"></fa-icon>
			</button>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
				position: relative;
			}
			.limit-x {
				overflow-x: hidden;
			}
			.btn {
				border: 0;
			}
			.btn-link {
				color: #dfdfdf;
			}
			.btn-link:hover,
			.btn-link.active {
				color: #828282;
			}
			.arrow-abs-left {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
			}
			.arrow-abs-right {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				z-index: 1;
			}
			.pagination-abs {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100%;
				z-index: 1;
			}
		`,
	],
})
export class SliderComponent implements AfterContentInit, OnChanges, OnDestroy {
	@Input() center: boolean = false;
	@Input() fullWidth: boolean = false;
	@Input() fullHeight: boolean = false;
	/**
	 * If undefined, defaults to `this.slidesInView`
	 */
	@Input() pageAdvance?: number = undefined;
	/**
	 * Disables the dots below the carousel
	 */
	@Input() autoscroll: number = 0;
	@Input() arrows: boolean = true;
	@Input() arrowsAbs: boolean = false;
	@Input() loop: boolean = false;
	@Input() pagination: boolean = true;
	@Input() paginationAbs: boolean = false;
	@Input() draggable: boolean = true;
	/**
	 * The number of slides to load in advance. Defaults to `this.pageAdvance`.
	 */
	@Input() preload?: number = undefined;
	@Input() slideIndex: number = 0;
	@Input() slidesInView: number | ISlidesInView = 1;
	@Input() transition: Transition = Transition.Slide;

	@Output() slideIndexChange: EventEmitter<number> = new EventEmitter();
	@Output() currentSlidesInView: EventEmitter<number> = new EventEmitter();
	@Output() prevMouseEnter: EventEmitter<number> = new EventEmitter();
	@Output() prevMouseLeave: EventEmitter<number> = new EventEmitter();
	@Output() nextMouseEnter: EventEmitter<number> = new EventEmitter();
	@Output() nextMouseLeave: EventEmitter<number> = new EventEmitter();

	@ContentChildren(SlideDirective) slides!: QueryList<SlideDirective>;

	faChevronLeft = faChevronLeft;
	faChevronRight = faChevronRight;
	faCircle = faCircle;
	Transition = Transition;

	centerBS: BehaviorSubject<boolean> = new BehaviorSubject(this.center);
	autoscrollSub: Subscription | null = null;
	loopBS: BehaviorSubject<boolean> = new BehaviorSubject(this.loop);
	pageAdvanceBS: BehaviorSubject<number | undefined> = new BehaviorSubject(this.pageAdvance);
	preloadBS: BehaviorSubject<number | undefined> = new BehaviorSubject(this.preload);
	slideIndexBS: BehaviorSubject<number> = new BehaviorSubject(this.slideIndex);
	slidesInViewBS: BehaviorSubject<number | ISlidesInView> = new BehaviorSubject(this.slidesInView);
	slidesRS: ReplaySubject<QueryList<SlideDirective>> = new ReplaySubject(1);

	// breakpoint$ = this.windowSize$.pipe(
	// 	map((width) =>
	// 		iter(breakpoints)
	// 			.filter((bp) => width >= breakpointMin[bp])
	// 			.nth(0)
	// 			.unwrapOr("xs"),
	// 	),
	// );

	inView$ = combineLatest(this.slidesInViewBS, this.viewport.windowSize$).pipe(
		map(([slidesInView, windowSize]) => {
			if (typeof slidesInView === "number") {
				return slidesInView;
			} else {
				return iterObj(slidesInView)
					.map(([bp, inView]) => tuple(Number(bp) || breakpointMin[bp], inView))
					.filter(([bpWidth, _]) => windowSize >= bpWidth)
					.maxByKey(([bpWidth, _]) => bpWidth)
					.map(([_, inView]) => inView!)
					.expect(`window is smaller than any breakpoint (${JSON.stringify(slidesInView)})`);
			}
		}),
		tap((x) => this.currentSlidesInView.emit(x)),
		shareReplay(1),
	);

	advance$ = combineLatest(this.pageAdvanceBS, this.inView$).pipe(
		map(([pageAdvance, inView]) => pageAdvance || inView),
	);

	preload$ = combineLatest(this.advance$, this.preloadBS).pipe(
		map(([advance, preload]) => (preload === undefined ? advance : preload)),
	);

	arrowRightInvisible$ = combineLatest(
		this.loopBS,
		this.slideIndexBS,
		this.inView$,
		this.slidesRS,
		this.centerBS,
	).pipe(
		map(
			([loop, slideIndex, inView, slides, center]) =>
				!loop && slideIndex + (center ? 1 : inView) >= slides.length,
		),
	);

	pageOffsets$ = combineLatest(this.slidesRS, this.advance$).pipe(
		map(([slides, advance]) => range(0, slides.length).stepBy(advance)),
	);

	private ngOnDestroyRS: ReplaySubject<void> = new ReplaySubject();

	constructor(private viewport: ViewportService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.center) {
			this.centerBS.next(changes.center.currentValue);
		}
		if (changes.loop) {
			this.loopBS.next(changes.loop.currentValue);
		}
		if (changes.pageAdvance) {
			this.pageAdvanceBS.next(changes.pageAdvance.currentValue);
		}
		if (changes.preload) {
			this.preloadBS.next(changes.preload.currentValue);
		}
		if (changes.slideIndex) {
			this.setSlide(changes.slideIndex.currentValue, false);
		}
		if (changes.slidesInView) {
			this.slidesInViewBS.next(changes.slidesInView.currentValue);
		}
		if (changes.autoscroll) {
			if (this.autoscrollSub) {
				this.autoscrollSub.unsubscribe();
			}
			if (changes.autoscroll.currentValue && !isPrerendering()) {
				this.autoscrollSub = interval(changes.autoscroll.currentValue)
					.pipe(takeUntil(this.ngOnDestroyRS))
					.subscribe(() => this.next());
			}
		}
	}

	ngAfterContentInit(): void {
		this.slidesRS.next(this.slides);
		this.slides.changes.subscribe((slides) => this.slidesRS.next(slides));
	}

	ngOnDestroy(): void {
		this.ngOnDestroyRS.next();
	}

	next(): void {
		combineLatest(this.slideIndexBS, this.advance$)
			.pipe(first())
			.subscribe(([slideIndex, advance]) => this.setSlide(slideIndex + advance));
	}

	prev(): void {
		combineLatest(this.slideIndexBS, this.advance$)
			.pipe(first())
			.subscribe(([slideIndex, advance]) => this.setSlide(slideIndex - advance));
	}

	setSlide(index: number, output: boolean = true) {
		this.slidesRS.pipe(first()).subscribe((slides) => {
			if (!this.loop) {
				index = Math.max(0, Math.min(slides.length, index));
			}

			this.slideIndexBS.next(index);
			if (output) {
				this.slideIndexChange.emit(index);
			}
		});
	}

	slideTrackBy(_index: number, item: { slide: SlideDirective; index: number }) {
		return item.index;
	}
}

export interface ISlidesInView {
	xs: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	[key: string]: number | undefined;
}

export enum Transition {
	Slide,
	Fade,
}
