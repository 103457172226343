import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnDestroy, ViewEncapsulation } from "@angular/core";
import { faFacebook, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowCircleUp, faShareAlt, faCopy, faInfoCircle, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { BehaviorSubject, fromEvent, Observable, of, Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { UserService } from "../user.service";
import { ToastrService } from "ngx-toastr";

declare var FB: any;
declare var PinUtils: any;

@Component({
	selector: "cm-quick-bar",
	templateUrl: "./quick-bar.component.html",
	styleUrls: ["./quick-bar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class QuickBarComponent implements OnDestroy {
	onScroll: Subscription | null = null;
	shareButtons: any[];
	shareButtonsActive: boolean;
	showUserViewing: boolean = false;
	showInfo: boolean = false;
	currentUserId: number | null = null;

	loading = false;

	buttons$: Observable<any[]> = of([]);

	userIdBS = new BehaviorSubject(0);

	favs$ = this.userIdBS.pipe(
		switchMap((userid) => this.http.post("/api/statement/GetUserFavListings", { vars: { userid } })),
		map((response: any) => [...new Set(response.results.map((row: any) => row.listingid))]),
	);

	cusotmerInfo$ = this.userIdBS.pipe(
		switchMap((userid) => this.http.post("/api/statement/GetCustomerInfo", { vars: { userid } })),
		map((response: any) => response.results[0]),
	);

	savedSearches$ = this.userIdBS.pipe(
		switchMap((userid) => this.http.post("api/statement/GetSiteUserSavedSearches", { vars: { userid } })),
		map((res: any) =>
			res.results.map((row: any) => ({
				...row,
				labels: JSON.parse(row.saved_srch_summary),
				values: JSON.parse(row.saved_srch_params),
			})),
		),
	);

	constructor(
		@Inject(DOCUMENT) private document: any,
		@Inject("PAGE_DATA") pageData: any,
		userService: UserService,
		private http: HttpClient,
		private toastrService: ToastrService,
	) {
		this.buttons$ = userService.permissions$.pipe(
			map((perms) => {
				if (perms.hasPermission(["user-viewing"])) {
					return [
						{
							id: "crm",
							text: "CRM",
							icon: faPencil,
							iconFixedWidth: true,
							css: {
								classes: "btn cm-button-full btn-info",
							},
							link: "/lead-management",
						},
						{
							id: "share",
							text: "Share",
							icon: faShareAlt,
							iconFixedWidth: true,
							css: {
								classes: "btn cm-button-full btn-primary",
							},
							clicked: () => (this.shareButtonsActive = !this.shareButtonsActive),
						},
						{
							id: "info",
							text: "Info",
							icon: faInfoCircle,
							iconFixedWidth: true,
							css: {
								classes: "btn cm-button-full info-button",
							},
							clicked: () => {
								this.showInfo = true;
							},
						},
						{
							id: "top",
							text: "Top",
							icon: faArrowCircleUp,
							iconFixedWidth: true,
							css: {
								classes: "btn cm-button-full top-button",
							},
							clicked: () => {
								this.document.body.scrollIntoView();
							},
						},
					];
				} else {
					return [
						{
							id: "share",
							text: "Share",
							icon: faShareAlt,
							iconFixedWidth: true,
							css: {
								classes: "btn cm-button-full btn-primary",
							},
							clicked: () => (this.shareButtonsActive = !this.shareButtonsActive),
						},
						{
							id: "info",
							text: "Info",
							icon: faInfoCircle,
							iconFixedWidth: true,
							css: {
								classes: "btn cm-button-full info-button",
							},
							clicked: () => {
								this.showInfo = true;
							},
						},
						{
							id: "top",
							text: "Top",
							icon: faArrowCircleUp,
							iconFixedWidth: true,
							css: {
								classes: "btn cm-button-full top-button",
							},
							clicked: () => {
								this.document.body.scrollIntoView();
							},
						},
					];
				}
			}),
		);

		this.shareButtons = [
			{
				id: "facebook",
				icon: faFacebook,
				iconFixedWidth: true,
				service: "facebook",
			},
			{
				id: "pinterest",
				icon: faPinterest,
				iconFixedWidth: true,
				service: "pinterest",
			},
			{
				id: "twitter",
				icon: faTwitter,
				iconFixedWidth: true,
				service: "twitter",
			},
			{
				id: "copylink",
				icon: faCopy,
				iconFixedWidth: true,
				service: "copylink",
			},
		];

		this.shareButtonsActive = false;

		if (typeof window !== "undefined") {
			this.onScroll = fromEvent(window, "scroll").subscribe(() => {
				this.shareButtonsActive = false;
			});
		}
	}

	ngOnDestroy(): void {
		if (this.onScroll) {
			this.onScroll.unsubscribe();
		}
	}

	share(btn: any) {
		if (btn.service === "copylink") {
			const input = document.createElement("input");
			input.id = "copy_url_input";
			input.type = "text";
			input.style.display = "none;";
			input.value = this.document.URL;
			document.body.appendChild(input);

			input.select();
			input.setSelectionRange(0, 99999); /*For mobile devices*/

			document.execCommand("copy");
			this.toastrService.success("URL Copied To Clipboard:\n" + this.document.URL);

			return;
		}
		if (btn.service === "facebook") {
			FB.ui(
				{
					method: "share",
					display: "popup",
					href: this.document.URL + "?utm_source=share&utm_medium=facebook&utm_category=share",
				},
				(response: any) => {},
			);
		}
		if (btn.service === "pinterest") {
			PinUtils.pinAny();
		}
		if (btn.service === "twitter") {
			var url =
				"https://twitter.com/intent/tweet?url=" +
				encodeURIComponent(this.document.URL + "?utm_source=share&utm_medium=twitter&utm_category=share");
			const TwitterWindow = window.open(
				url,
				"TwitterWindow",
				"height=450, width=550, top=" +
					(window.innerHeight / 2 - 225) +
					", left=" +
					(window.innerWidth / 2 - 275) +
					", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0",
			);
			return false;
		}
	}
}
