import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CampaignPickerComponent } from "@core/app/adwords/components/campaign-picker/campaign-picker.component";
import { ButtonGridComponent } from "@core/app/button-grid/button-grid.component";
import { ButtonModule } from "@core/app/button/button.module";
import { FormTransparentComponent } from "@core/app/form-transparent/form-transparent.component";
import { GroupByPipe } from "@core/app/group-by.pipe";
import { ImageModule } from "@core/app/image/image.module";
import { InventoryModule } from "@core/app/inventory/inventory.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { Map2Module } from "@core/app/map2/map2.module";
import { Pagination2Module } from "@core/app/pagination2/pagination2.module";
import { SearchModule } from "@core/app/search/search.module";
import { SocialLinks2Component } from "@core/app/shared/components/social-links2/social-links2.component";
import { TableFiltersComponent } from "@core/app/shared/components/table-filters/table-filters.component";
import { TableSelectsComponent } from "@core/app/shared/components/table-selects/table-selects.component";
import { TableComponent } from "@core/app/shared/components/table/table.component";
import { SliderModule } from "@core/app/slider/slider.module";
import { SlugifyPipe } from "@core/app/slugify.pipe";
import { StarListingComponent } from "@core/app/star-listing/star-listing.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrModule } from "angularx-flatpickr";
import { ModalModule } from "shared";
import { CrmEmailModule } from "../crm/components/email/email.module";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { FormModule } from "../form/form.module";
import { ComparePopupComponent } from "../stevo/components/compare/compare-popup.component";
import { StevoMenuComponent } from "../stevo/components/menu/menu.component";
import { SendAMessageModalComponent } from "../stevo/components/modals/send-a-message-modal.component";
import { UtilModule } from "../util/util.module";

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		NgbModule,
		FlatpickrModule.forRoot(),
		RouterModule,
		RouterModule,
		ImageModule,
		ButtonModule,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		Pagination2Module,
		ButtonModule,
		UtilModule,
		FormModule,
		ModalModule,
		CrmEmailModule,
		UtilModule,
	],
	declarations: [
		ButtonGridComponent,
		GroupByPipe,
		SlugifyPipe,
		StarListingComponent,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		SocialLinks2Component,
		CampaignPickerComponent,
		FormTransparentComponent,
		StevoMenuComponent,
		SendAMessageModalComponent,
		ComparePopupComponent,
	],
	exports: [
		ButtonGridComponent,
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		GroupByPipe,
		NgbModule,
		RouterModule,
		SlugifyPipe,
		StarListingComponent,
		DropzoneComponent,
		FlatpickrModule,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		ImageModule,
		SocialLinks2Component,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		CampaignPickerComponent,
		FormTransparentComponent,
		Pagination2Module,
		ButtonModule,
		FormModule,
		ModalModule,
		StevoMenuComponent,
		SendAMessageModalComponent,
		ComparePopupComponent,
		CrmEmailModule,
		UtilModule,
	],
	providers: [Location],
})
export class SharedModule {}
