import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ModalModule } from "shared";
import { ScrollModule } from "shared/bootstrap";
import { FormModule } from "../form/form.module";
import { LayoutModule } from "../layout/layout.module";
import { Map2Module } from "../map2/map2.module";
import { SharedModule } from "../shared/shared.module";
import { SliderModule } from "../slider/slider.module";
import { BuyBrowsebyComponent } from "./components/browseby.component";
import { ContactSectionComponent } from "./components/contact-section.component";
import { FaqsComponent } from "./components/faqs.component";
import { HomeResourcesComponent } from "./components/home-resources.component";
import { ReviewsComponent } from "./components/reviews.component";
import { VerticalReviewsComponent } from "./components/vertical-reviews.component";

@NgModule({
	declarations: [
		ContactSectionComponent,
		VerticalReviewsComponent,
		BuyBrowsebyComponent,
		FaqsComponent,
		HomeResourcesComponent,
		ReviewsComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		LayoutModule,
		FormModule,
		SharedModule,
		Map2Module,
		SliderModule,
		NgxChartsModule,
		ModalModule,
		ScrollModule,
		NgbNavModule,
	],
	exports: [
		ContactSectionComponent,
		VerticalReviewsComponent,
		BuyBrowsebyComponent,
		FaqsComponent,
		HomeResourcesComponent,
		ReviewsComponent,
	],
})
export class RealEstateWestModule {}
