import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CompareService } from "../../services/compare.service";

let nextId = 0;

@Component({
	selector: "cm-stevo-compare-button",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<button
			*ngIf="compareService.compare$ | async as compare"
			(click)="check(compare.has(listingid))"
			[ngClass]="{
				'btn-secondary': compare.has(listingid),
				'btn-outline-secondary': !compare.has(listingid)
			}"
			class="btn w-100"
		>
			<span *ngIf="!compare.has(listingid); else minus">+</span>
			<ng-template #minus><span>-</span></ng-template>
			COMPARE
		</button>
	`,
})
export class CompareButtonComponent {
	@Input() listingid!: number;

	id = nextId++;

	constructor(public compareService: CompareService) {}

	check(checked: boolean) {
		if (!checked) {
			this.compareService.addListing(this.listingid);
		} else {
			this.compareService.removeListing(this.listingid);
		}
	}
}
