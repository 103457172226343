import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AppService } from "@core/app/app.service";
import { GTMService } from "@core/app/gtm.service";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { CompareService } from "@core/app/stevo/services/compare.service";
import { UserService } from "@core/app/user.service";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/pro-regular-svg-icons";
import { IPageData } from "@model/page-data";
import { map } from "rxjs/operators";

@Component({
	selector: "cm-header-stevo",
	template: `
		<header id="nav-header" class="header container-fluid my-2">
			<div class="row flex-nowrap align-items-center" itemscope itemtype="http://schema.org/WPHeader">
				<div class="col-6 col-lg-auto">
					<a routerLink="/">
						<img [src]="app.logo$ | async | image : 's'" alt="Logo" class="img-fluid logo" />
					</a>
				</div>
				<div class="col-6 col-lg-auto flex-grow-1 text-right text-xl-left menu-content">
					<div class="d-flex flex-column align-items-end">
						<div
							class="d-flex justify-content-end align-items-center w-100 border-bottom border-primary py-2"
						>
							<a
								class="text-dark mx-3"
								(click)="linkClicked('Link Click', 'Phone Call', 'click')"
								href="tel:{{ app.phoneNumber$ | async }}"
							>
								<fa-icon [icon]="faPhoneAlt"></fa-icon>
								{{ app.phoneNumber$ | async }}
							</a>
							<fa-icon [icon]="faMapMarkerAlt"></fa-icon>
							<a
								*ngIf="!(mobile$ | async)"
								[href]="app.addressLink$ | async"
								class="text-dark mx-3 d-none d-lg-inline-block"
								target="_blank"
								(click)="showModal = true"
							>
								{{ app.addressLine1$ | async }}, {{ app.addressLine2$ | async }}
							</a>
							<div
								class="d-none d-lg-block text-right"
								*ngIf="!(userService.loggedIn$ | async) && !(mobile$ | async)"
							>
								<div
									class="btn btn-secondary font-weight-bold mx-1 rounded"
									(click)="formState = LoginState.Login; showLogin = true"
								>
									SIGN IN
								</div>
								<div
									class="btn btn-primary font-weight-bold mx-1 rounded"
									(click)="formState = LoginState.Register; showLogin = true"
								>
									REGISTER
								</div>
							</div>
							<div
								class="d-none d-lg-block text-right"
								*ngIf="(userService.loggedIn$ | async) && !(mobile$ | async)"
							>
								<a routerLink="/my-account" class="text-dark tk-adelle mx-1"
									>Hello, {{ userService.firstName$ | async }}</a
								>
								&#x000B7;
								<ng-container *ngIf="(compareService.compare$ | async).size > 1"
									><a routerLink="/compare" class="tk-adelle mx-1">Compare</a> &#x000B7;
								</ng-container>
								<span class="tk-adelle pointer-cursor mx-1" (click)="userService.logOut()"
									>Sign Out</span
								>
							</div>
						</div>
						<div class="menu py-2">
							<cm-stevo-menu
								class="font-weight-bold"
								[appMenuid]="7"
								rootStyle="inline"
								subStyle="unstyled"
								itemscope
								itemtype="http://schema.org/SiteNavigationElement"
								(logIn)="formState = LoginState.Login; showLogin = true"
								(signUp)="formState = LoginState.Register; showLogin = true"
							></cm-stevo-menu>
						</div>
					</div>
				</div>
			</div>
		</header>
		<cm-send-a-message-modal [(show)]="showModal"></cm-send-a-message-modal>
		<cm-login-modal
			[state]="formState"
			[show]="showLogin"
			(loggedIn)="showLogin = false"
			(showChange)="showLogin = $event"
		></cm-login-modal>
	`,
	styleUrls: ["./header-stevo.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderStevoComponent {
	faPhoneAlt = faPhoneAlt;
	faMapMarkerAlt = faMapMarkerAlt;

	formState = LoginState.Login;
	LoginState = LoginState;
	showModal = false;
	showLogin = false;

	mobile$ = this.viewportService.windowSize$.pipe(map((w) => w < 1250));

	constructor(
		@Inject("PAGE_DATA") public pageData: IPageData,
		public app: AppService,
		public compareService: CompareService,
		public userService: UserService,
		private gtmService: GTMService,
		private viewportService: ViewportService,
	) {}

	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}

enum LoginState {
	Login,
	Register,
	ForgotPass,
}
