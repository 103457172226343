import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { faFacebook, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowCircleUp, faEye, faShareAlt, faCopy, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { BehaviorSubject, fromEvent, Observable, of, Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { loadScript } from "../common/util";
import { ToastrService } from "ngx-toastr";

@Component({
	selector: "cm-quick-admin",
	templateUrl: "./quick-admin.component.html",
	styleUrls: ["./quick-admin.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class QuickAdminComponent implements OnInit {

	showUserViewing: boolean = false;
	showInfo: boolean = false;
	currentUserId: number | null = null;
	showUser: boolean = false;


	constructor(
		@Inject(DOCUMENT) private document: any,
		@Inject("PAGE_DATA") private pageData: any,
		private http: HttpClient,
		private toastrService: ToastrService
	){}

	ngOnInit(){

	}

}
