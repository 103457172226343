import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { format } from "date-fns";
import { combineLatest } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { SMap } from "shared/common";

@Injectable({ providedIn: "root" })
export class AppService {
	dealerInfo$ = this.transfer
		.transfer$("GetDealerInfo", () => this.http.post("/api/statement/GetDealerInfo", {}))
		.pipe(
			map((res: any) => res.results[0]),
			shareReplay(1),
		);

	logo$ = this.dealerInfo$.pipe(map((dealerInfo) => dealerInfo.img_dir + dealerInfo.img_file));
	name$ = this.dealerInfo$.pipe(map((dealerInfo) => dealerInfo.dealer));

	phoneNumber$ = this.dealerInfo$.pipe(map((dealerInfo) => dealerInfo.phone_number));
	faxNumber$ = this.dealerInfo$.pipe(map((dealerInfo) => dealerInfo.faxNumber));

	city$ = this.dealerInfo$.pipe(map((dealerInfo) => dealerInfo.city));
	abbrev$ = this.dealerInfo$.pipe(map((dealerInfo) => dealerInfo.abbrev));
	postal_code$ = this.dealerInfo$.pipe(map((dealerInfo) => dealerInfo.postal_code));
	addressLine1$ = this.dealerInfo$.pipe(map((dealerInfo) => `${dealerInfo.address1}${dealerInfo.address2 || ""}`));
	addressLine2$ = this.dealerInfo$.pipe(
		map((dealerInfo) => `${dealerInfo.city}, ${dealerInfo.abbrev} ${dealerInfo.postal_code}`),
	);
	addressLink$ = combineLatest([this.addressLine1$, this.addressLine2$]).pipe(
		map(([addressLine1, addressLine2]) => {
			const link1 = addressLine1.replace(/ /g, "+");
			const link2 = addressLine2.replace(/ /g, "+");
			return `https://www.google.com/maps/place/${link1},+${link2}`;
		}),
	);

	hours$ = this.transfer
		.transfer$("GetOrgDeptHours", () => this.http.post("/api/statement/GetOrgDeptHoursNoFormat", {}))
		.pipe(
			map((res: any) => {
				const depts = new SMap<string, SMap<string, [string, string]>>();
				for (const row of res.results) {
					depts
						.getOrInsertWith(row.org_dept, () => new SMap())
						.getOrInsert(
							`${format(`1/1/1970 ${row.time_from}`, "h:mm[]a")} - ${format(
								`1/1/1970 ${row.time_to}`,
								"h:mm[]a",
							)}`,
							[row.day_of_week, row.day_of_week],
						)[1] = row.day_of_week;
				}

				const ret = new SMap<string, [string, string][]>();
				for (const [dept, days] of depts) {
					for (const [hours, [start, end]] of days) {
						ret.getOrInsert(dept, []).push([start === end ? start : `${start}-${end}`, hours]);
					}
				}
				return ret.toMap();
			}),
			shareReplay(1),
		);

	constructor(private http: HttpClient, private transfer: TransferRxService) {}
}
