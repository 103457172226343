import { Injectable } from "@angular/core";
import { IHomeTile } from "@model/home-tile";
import { IGetHomes } from "@model/stmt/GetHomes";
import { format } from "date-fns";

@Injectable({
	providedIn: "root",
})
export class ListingService {
	makeHomeTileObject(data: IGetHomes): IHomeTile {
		return {
			content_page_url: data.content_page_url,
			address1: data.address1,
			city: data.city,
			abbrev: data.abbrev,
			postal_code: data.postal_code,
			beds_total: data.beds_total,
			baths_total: data.baths_total,
			square_feet: data.square_feet,
			img_file: data.img_file,
			img_dir: data.img_dir,
			latitude: parseFloat(data.latitude),
			longitude: parseFloat(data.longitude),
			msrp: parseFloat(data.msrp),
			highlight: false,
			sold: data.sold,
			pending_sale: data.pending_sale,
			open_house_date: data.open_house_date,
			timeOnSite: data.timeOnSite,
			start_time: data.start_time,
			end_time: data.end_time,
			property_type: data.property_type,
			lot_acres: data.lot_acres,
			img_count: data.img_count,
			openHouse: this.openHouse(data),
			cssClass: this.cssClass(data),
			header: this.headerMessage(data),
			listingid: data.listingid,
		};
	}

	openHouse(data: IGetHomes): string | null {
		let returnValue: string | null = null;
		if (data.open_house_date && data.start_time) {
			const date = format(data.open_house_date + " " + data.start_time, "ddd., M/D");
			const start = this.time24To12(data.open_house_date, data.start_time);
			returnValue = "Open House " + date + " @ " + start;
		}
		return returnValue;
	}

	time24To12(date: string, time: string) {
		// Convert to local time string in 12-hour format with the user's local time zone
		return new Date(`${date}T${time}Z`).toLocaleTimeString("en-US", {
			hour12: true,
			hour: "numeric",
			minute: "numeric",
		});
	}

	cssClass(data: IGetHomes): string {
		if (data.sold === 1) {
			return "stripe-gray";
		} else if (data.pending_sale === 1) {
			return "stripe-gray";
		} else if (data.timeOnSite <= 3) {
			return "stripe-red";
		} else {
			return "stripe-green";
		}
	}

	headerMessage(data: IGetHomes): string {
		if (data.sold === 1) {
			return "Sold";
		} else if (data.pending_sale === 1) {
			return "Pending";
		} else if (data.timeOnSite <= 3) {
			return "Hot Listing!";
		} else {
			return "Active";
		}
	}
}
