import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-facebook-default-descriptions",
	template: `
		<div class="container-fluid">
			<ul ngbNav #mainNav="ngbNav" class="nav-pills justify-content-center my-1">
				<li ngbNavItem="Descriptions">
					<a ngbNavLink>Descriptions</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-12">
								<div class="h5">Title of Post</div>
							</div>
							<div class="col-12 form-group">
								<input type="text" [(ngModel)]="title" class="form-control my-2" />
								<button class="btn btn-primary" [disabled]="adding" (click)="updateTitle()">
									Update
								</button>
							</div>
							<div class="col-12">
								<div class="h5">Call to Action</div>
							</div>
							<div class="col-12 form-group">
								<input type="text" [(ngModel)]="cta" class="form-control my-2" />
								<button class="btn btn-primary" [disabled]="adding" (click)="updateCta()">
									Update
								</button>
							</div>
							<div class="col-12">
								<div class="h5">Add New Default Description</div>
							</div>
							<div class="col-12 form-group">
								<textarea [(ngModel)]="defaultDesc" class="form-control my-2" rows="5"></textarea>
								<select class="form-control" [(ngModel)]="descSection">
									<option [value]="381">RV</option>
									<option [value]="404">Boat</option>
								</select>
								<button class="btn btn-primary" [disabled]="adding" (click)="addDesc()">Add</button>
							</div>
							<div class="col-12">
								<div class="h5">Current Default Descriptions</div>
							</div>
							<div class="col-12">
								<table class="table table-sm table-hover table-bordered table-striped">
									<thead>
										<tr>
											<th>Type</th>
											<th>Description</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let row of descriptions$ | async">
											<td>{{ row.desc_section }}</td>

											<td>{{ row.desc_default }}</td>
											<td>
												<button class="btn btn-danger" (click)="removeDesc(row.desc_defaultid)">
													Remove
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Daily Posting Configs">
					<a ngbNavLink>Daily Posting Configs</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-12">
								<ul ngbNav #nav="ngbNav" class="nav-pills justify-content-center my-1">
									<li ngbNavItem="Gallery Post">
										<a ngbNavLink>Gallery Post</a>
										<ng-template ngbNavContent>
											<ul ngbNav #subNav="ngbNav" class="nav-pills justify-content-center my-1">
												<li ngbNavItem="RV">
													<a ngbNavLink>RV</a>
													<ng-template ngbNavContent>
														<div class="row">
															<div class="col-4 form-group">
																<label>{{ location }} Number to Post</label>
																<br />
																<input
																	type="number"
																	[(ngModel)]="numberToPost"
																	(ngModelChange)="adjustTimeSlots($event)"
																/>
															</div>
															<div
																class="col-12 my-1"
																*ngFor="let time of timeSlots; index as i"
															>
																<ngb-timepicker
																	class="mx-auto d-inline-block"
																	[(ngModel)]="timeSlots[i]"
																	[minuteStep]="15"
																	[meridian]="true"
																></ngb-timepicker>
															</div>
															<div class="col-12 my-1">
																<button
																	class="btn btn-primary"
																	(click)="updateTimeSlots()"
																>
																	Save Slots
																</button>
															</div>
															<div class="col-12 mt-1">
																<div class="h5">{{ location }} Days to Post</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="postDays.Sunday"
																		(ngModelChange)="updateDays()"
																		id="Sunday"
																	/>
																	<label class="custom-control-label" for="Sunday"
																		>Sunday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="postDays.Monday"
																		(ngModelChange)="updateDays()"
																		id="Monday"
																	/>
																	<label class="custom-control-label" for="Monday"
																		>Monday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="postDays.Tuesday"
																		(ngModelChange)="updateDays()"
																		id="Tuesday"
																	/>
																	<label class="custom-control-label" for="Tuesday"
																		>Tuesday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="postDays.Wednesday"
																		(ngModelChange)="updateDays()"
																		id="Wednesday"
																	/>
																	<label class="custom-control-label" for="Wednesday"
																		>Wednesday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="postDays.Thursday"
																		(ngModelChange)="updateDays()"
																		id="Thursday"
																	/>
																	<label class="custom-control-label" for="Thursday"
																		>Thursday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="postDays.Friday"
																		(ngModelChange)="updateDays()"
																		id="Friday"
																	/>
																	<label class="custom-control-label" for="Friday"
																		>Friday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="postDays.Saturday"
																		(ngModelChange)="updateDays()"
																		id="Saturday"
																	/>
																	<label class="custom-control-label" for="Saturday"
																		>Saturday</label
																	>
																</div>
															</div>
															<ng-container *ngIf="location2">
																<div class="col-4 form-group">
																	<label>{{ location2 }} Number to Post</label>
																	<br />
																	<input
																		type="number"
																		[(ngModel)]="numberToPost2"
																		(ngModelChange)="adjustTimeSlots2($event)"
																	/>
																</div>
																<div
																	class="col-12 my-1"
																	*ngFor="let time of timeSlots2; index as i"
																>
																	<ngb-timepicker
																		class="mx-auto d-inline-block"
																		[(ngModel)]="timeSlots2[i]"
																		[minuteStep]="15"
																		[meridian]="true"
																	></ngb-timepicker>
																</div>
																<div class="col-12 my-1">
																	<button
																		class="btn btn-primary"
																		(click)="updateTimeSlots2()"
																	>
																		Save Slots
																	</button>
																</div>

																<div class="col-12 mt-1">
																	<div class="h5">{{ location2 }} Days to Post</div>
																</div>
																<div class="col-12">
																	<div
																		class="custom-control custom-switch d-inline-block"
																	>
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			[(ngModel)]="postDays2.Sunday"
																			(ngModelChange)="updateDays2()"
																			id="2Sunday"
																		/>
																		<label
																			class="custom-control-label"
																			for="2Sunday"
																			>Sunday</label
																		>
																	</div>
																</div>
																<div class="col-12">
																	<div
																		class="custom-control custom-switch d-inline-block"
																	>
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			[(ngModel)]="postDays2.Monday"
																			(ngModelChange)="updateDays2()"
																			id="2Monday"
																		/>
																		<label
																			class="custom-control-label"
																			for="2Monday"
																			>Monday</label
																		>
																	</div>
																</div>
																<div class="col-12">
																	<div
																		class="custom-control custom-switch d-inline-block"
																	>
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			[(ngModel)]="postDays2.Tuesday"
																			(ngModelChange)="updateDays2()"
																			id="2Tuesday"
																		/>
																		<label
																			class="custom-control-label"
																			for="2Tuesday"
																			>Tuesday</label
																		>
																	</div>
																</div>
																<div class="col-12">
																	<div
																		class="custom-control custom-switch d-inline-block"
																	>
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			[(ngModel)]="postDays2.Wednesday"
																			(ngModelChange)="updateDays2()"
																			id="2Wednesday"
																		/>
																		<label
																			class="custom-control-label"
																			for="2Wednesday"
																			>Wednesday</label
																		>
																	</div>
																</div>
																<div class="col-12">
																	<div
																		class="custom-control custom-switch d-inline-block"
																	>
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			[(ngModel)]="postDays2.Thursday"
																			(ngModelChange)="updateDays2()"
																			id="2Thursday"
																		/>
																		<label
																			class="custom-control-label"
																			for="2Thursday"
																			>Thursday</label
																		>
																	</div>
																</div>
																<div class="col-12">
																	<div
																		class="custom-control custom-switch d-inline-block"
																	>
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			[(ngModel)]="postDays2.Friday"
																			(ngModelChange)="updateDays2()"
																			id="2Friday"
																		/>
																		<label
																			class="custom-control-label"
																			for="2Friday"
																			>Friday</label
																		>
																	</div>
																</div>
																<div class="col-12">
																	<div
																		class="custom-control custom-switch d-inline-block"
																	>
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			[(ngModel)]="postDays2.Saturday"
																			(ngModelChange)="updateDays2()"
																			id="2Saturday"
																		/>
																		<label
																			class="custom-control-label"
																			for="2Saturday"
																			>Saturday</label
																		>
																	</div>
																</div>
															</ng-container>
															<div class="col-4 form-group">
																<label>Brand</label>
																<select
																	class="form-control"
																	[(ngModel)]="dailyPostingBrand"
																	(ngModelChange)="updateDailyPostingBrand()"
																	multiple
																>
																	<option></option>
																	<option
																		*ngFor="let a of brandids$ | async"
																		[value]="a.brandid"
																	>
																		{{ a.name }}
																	</option>
																</select>
															</div>
														</div>
													</ng-template>
												</li>
												<li ngbNavItem="Boat">
													<a ngbNavLink>Boat</a>
													<ng-template ngbNavContent>
														<div class="row">
															<div class="col-4 form-group">
																<label>{{ location }} Number to Post</label>
																<br />
																<input
																	type="number"
																	[(ngModel)]="boatnumberToPost"
																	(ngModelChange)="boatadjustTimeSlots($event)"
																/>
															</div>
															<div
																class="col-12 my-1"
																*ngFor="let time of boattimeSlots; index as i"
															>
																<ngb-timepicker
																	class="mx-auto d-inline-block"
																	[(ngModel)]="boattimeSlots[i]"
																	[minuteStep]="15"
																	[meridian]="true"
																></ngb-timepicker>
															</div>
															<div class="col-12 my-1">
																<button
																	class="btn btn-primary"
																	(click)="boatupdateTimeSlots()"
																>
																	Save Slots
																</button>
															</div>
															<div class="col-12 mt-1">
																<div class="h5">{{ location }} Days to Post</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays.Sunday"
																		(ngModelChange)="boatupdateDays()"
																		id="Sunday"
																	/>
																	<label class="custom-control-label" for="Sunday"
																		>Sunday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays.Monday"
																		(ngModelChange)="boatupdateDays()"
																		id="Monday"
																	/>
																	<label class="custom-control-label" for="Monday"
																		>Monday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays.Tuesday"
																		(ngModelChange)="boatupdateDays()"
																		id="Tuesday"
																	/>
																	<label class="custom-control-label" for="Tuesday"
																		>Tuesday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays.Wednesday"
																		(ngModelChange)="boatupdateDays()"
																		id="Wednesday"
																	/>
																	<label class="custom-control-label" for="Wednesday"
																		>Wednesday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays.Thursday"
																		(ngModelChange)="boatupdateDays()"
																		id="Thursday"
																	/>
																	<label class="custom-control-label" for="Thursday"
																		>Thursday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays.Friday"
																		(ngModelChange)="boatupdateDays()"
																		id="Friday"
																	/>
																	<label class="custom-control-label" for="Friday"
																		>Friday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays.Saturday"
																		(ngModelChange)="boatupdateDays()"
																		id="Saturday"
																	/>
																	<label class="custom-control-label" for="Saturday"
																		>Saturday</label
																	>
																</div>
															</div>
															<div class="col-4 form-group">
																<label>{{ location2 }} Number to Post</label>
																<br />
																<input
																	type="number"
																	[(ngModel)]="boatnumberToPost2"
																	(ngModelChange)="boatadjustTimeSlots2($event)"
																/>
															</div>
															<div
																class="col-12 my-1"
																*ngFor="let time of boattimeSlots2; index as i"
															>
																<ngb-timepicker
																	class="mx-auto d-inline-block"
																	[(ngModel)]="boattimeSlots2[i]"
																	[minuteStep]="15"
																	[meridian]="true"
																></ngb-timepicker>
															</div>
															<div class="col-12 my-1">
																<button
																	class="btn btn-primary"
																	(click)="boatupdateTimeSlots2()"
																>
																	Save Slots
																</button>
															</div>
															<div class="col-12 mt-1">
																<div class="h5">{{ location2 }} Days to Post</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays2.Sunday"
																		(ngModelChange)="boatupdateDays2()"
																		id="boat2Sunday"
																	/>
																	<label
																		class="custom-control-label"
																		for="boat2Sunday"
																		>Sunday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays2.Monday"
																		(ngModelChange)="boatupdateDays2()"
																		id="boat2Monday"
																	/>
																	<label
																		class="custom-control-label"
																		for="boat2Monday"
																		>Monday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays2.Tuesday"
																		(ngModelChange)="boatupdateDays2()"
																		id="boat2Tuesday"
																	/>
																	<label
																		class="custom-control-label"
																		for="boat2Tuesday"
																		>Tuesday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays2.Wednesday"
																		(ngModelChange)="boatupdateDays2()"
																		id="boat2Wednesday"
																	/>
																	<label
																		class="custom-control-label"
																		for="boat2Wednesday"
																		>Wednesday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays2.Thursday"
																		(ngModelChange)="boatupdateDays2()"
																		id="boat2Thursday"
																	/>
																	<label
																		class="custom-control-label"
																		for="boat2Thursday"
																		>Thursday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays2.Friday"
																		(ngModelChange)="boatupdateDays2()"
																		id="boat2Friday"
																	/>
																	<label
																		class="custom-control-label"
																		for="boat2Friday"
																		>Friday</label
																	>
																</div>
															</div>
															<div class="col-12">
																<div
																	class="custom-control custom-switch d-inline-block"
																>
																	<input
																		type="checkbox"
																		class="custom-control-input"
																		[(ngModel)]="boatpostDays2.Saturday"
																		(ngModelChange)="boatupdateDays2()"
																		id="boat2Saturday"
																	/>
																	<label
																		class="custom-control-label"
																		for="boat2Saturday"
																		>Saturday</label
																	>
																</div>
															</div>
														</div>
													</ng-template>
												</li>
											</ul>
											<div [ngbNavOutlet]="subNav"></div>
											<div class="row">
												<div class="col-4 form-group">
													<label>UTM Campaign</label>
													<input
														type="type"
														class="form-control"
														[(ngModel)]="dailyPostingUTM"
														(ngModelChange)="updateDailyPosting()"
													/>
												</div>

												<div class="col-12 mt-2">
													<div class="h5">Boosting Config</div>
												</div>
												<div class="col-4 form-group">
													<label>Audience</label>
													<select
														class="form-control"
														[(ngModel)]="audience"
														(ngModelChange)="updateAudience()"
													>
														<option></option>
														<option
															*ngFor="let a of audiences$ | async"
															[value]="a.audience_id"
														>
															{{ a.name }}
														</option>
													</select>
												</div>
												<div class="col-4 form-group">
													<label>Duration</label>
													<input
														type="number"
														class="form-control"
														[(ngModel)]="duration"
														(ngModelChange)="updateDuration()"
													/>
												</div>
												<div class="col-4 form-group">
													<label>Total Budget</label>
													<input
														type="number"
														class="form-control"
														[(ngModel)]="total_budget"
														(ngModelChange)="updateBudget()"
													/>
												</div>
												<div class="col-4 form-group">
													<label>Special Ad Category</label>
													<select
														class="form-control"
														[(ngModel)]="special"
														(ngModelChange)="updateSpecial()"
													>
														<option>None</option>
														<option>Credit</option>
														<option>Employment</option>
														<option>Housing</option>
													</select>
												</div>
											</div>
										</ng-template>
									</li>
									<li ngbNavItem="Video Post">
										<a ngbNavLink>Video Post</a>
										<ng-template ngbNavContent>
											<div class="row">
												<div class="col-4 form-group">
													<label>Number to Post</label>
													<br />
													<input
														type="number"
														[(ngModel)]="numberAVToPost"
														(ngModelChange)="adjustAVTimeSlots($event)"
													/>
												</div>
												<div class="col-12 my-1" *ngFor="let time of timeAVSlots; index as i">
													<ngb-timepicker
														class="mx-auto d-inline-block"
														[(ngModel)]="timeAVSlots[i]"
														[minuteStep]="15"
														[meridian]="true"
													></ngb-timepicker>
												</div>
												<div class="col-12 my-1">
													<button class="btn btn-primary" (click)="updateAVTimeSlots()">
														Save Slots
													</button>
												</div>
												<div class="col-12 mt-1">
													<div class="h5">Days to Post</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postAVDays.Sunday"
															(ngModelChange)="updateAVDays()"
															id="AVSunday"
														/>
														<label class="custom-control-label" for="AVSunday"
															>Sunday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postAVDays.Monday"
															(ngModelChange)="updateAVDays()"
															id="AVMonday"
														/>
														<label class="custom-control-label" for="AVMonday"
															>Monday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postAVDays.Tuesday"
															(ngModelChange)="updateAVDays()"
															id="AVTuesday"
														/>
														<label class="custom-control-label" for="AVTuesday"
															>Tuesday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postAVDays.Wednesday"
															(ngModelChange)="updateAVDays()"
															id="AVWednesday"
														/>
														<label class="custom-control-label" for="AVWednesday"
															>Wednesday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postAVDays.Thursday"
															(ngModelChange)="updateAVDays()"
															id="AVThursday"
														/>
														<label class="custom-control-label" for="AVThursday"
															>Thursday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postAVDays.Friday"
															(ngModelChange)="updateAVDays()"
															id="AVFriday"
														/>
														<label class="custom-control-label" for="AVFriday"
															>Friday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postAVDays.Saturday"
															(ngModelChange)="updateAVDays()"
															id="AVSaturday"
														/>
														<label class="custom-control-label" for="AVSaturday"
															>Saturday</label
														>
													</div>
												</div>
												<div class="col-4 form-group">
													<label>UTM Campaign</label>
													<input
														type="type"
														class="form-control"
														[(ngModel)]="videoUTM"
														(ngModelChange)="updateVideo()"
													/>
												</div>
											</div>
										</ng-template>
									</li>
									<li ngbNavItem="Tour Post">
										<a ngbNavLink>Tour Post</a>
										<ng-template ngbNavContent>
											<div class="row">
												<div class="col-4 form-group">
													<label>Number to Post</label>
													<br />
													<input
														type="number"
														[(ngModel)]="numberTourToPost"
														(ngModelChange)="adjustTourTimeSlots($event)"
													/>
												</div>
												<div class="col-12 my-1" *ngFor="let time of timeTourSlots; index as i">
													<ngb-timepicker
														class="mx-auto d-inline-block"
														[(ngModel)]="timeTourSlots[i]"
														[minuteStep]="15"
														[meridian]="true"
													></ngb-timepicker>
												</div>
												<div class="col-12 my-1">
													<button class="btn btn-primary" (click)="updateTourTimeSlots()">
														Save Slots
													</button>
												</div>

												<div class="col-12 mt-1">
													<div class="h5">Days to Post</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postTourDays.Sunday"
															(ngModelChange)="updateTourDays()"
															id="TourSunday"
														/>
														<label class="custom-control-label" for="TourSunday"
															>Sunday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postTourDays.Monday"
															(ngModelChange)="updateTourDays()"
															id="TourMonday"
														/>
														<label class="custom-control-label" for="TourMonday"
															>Monday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postTourDays.Tuesday"
															(ngModelChange)="updateTourDays()"
															id="TourTuesday"
														/>
														<label class="custom-control-label" for="TourTuesday"
															>Tuesday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postTourDays.Wednesday"
															(ngModelChange)="updateTourDays()"
															id="TourWednesday"
														/>
														<label class="custom-control-label" for="TourWednesday"
															>Wednesday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postTourDays.Thursday"
															(ngModelChange)="updateTourDays()"
															id="TourThursday"
														/>
														<label class="custom-control-label" for="TourThursday"
															>Thursday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postTourDays.Friday"
															(ngModelChange)="updateTourDays()"
															id="TourFriday"
														/>
														<label class="custom-control-label" for="TourFriday"
															>Friday</label
														>
													</div>
												</div>
												<div class="col-12">
													<div class="custom-control custom-switch d-inline-block">
														<input
															type="checkbox"
															class="custom-control-input"
															[(ngModel)]="postTourDays.Saturday"
															(ngModelChange)="updateTourDays()"
															id="TourSaturday"
														/>
														<label class="custom-control-label" for="TourSaturday"
															>Saturday</label
														>
													</div>
												</div>
												<div class="col-4 form-group">
													<label>UTM Campaign</label>
													<input
														type="type"
														class="form-control"
														[(ngModel)]="tourUTM"
														(ngModelChange)="updateTour()"
													/>
												</div>
											</div>
										</ng-template>
									</li>
								</ul>
								<div [ngbNavOutlet]="nav"></div>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Ad Text Defaults">
					<a ngbNavLink>Ad Text Defaults</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-12">
								<div class="h5">Ad Headline</div>
							</div>
							<div class="col-12 form-group">
								<input type="text" [(ngModel)]="headline" class="form-control my-2" />
								<button class="btn btn-primary" [disabled]="adding" (click)="updateHeadline()">
									Update
								</button>
							</div>
							<div class="col-12">
								<div class="h5">Model Click Ad Body</div>
							</div>
							<div class="col-12 form-group">
								<textarea type="text" [(ngModel)]="modelBody" class="form-control my-2"></textarea>
								<button class="btn btn-primary" [disabled]="adding" (click)="updateModelBody()">
									Update
								</button>
							</div>
							<div class="col-12">
								<div class="h5">Lead Ad Body</div>
							</div>
							<div class="col-12 form-group">
								<textarea type="text" [(ngModel)]="leadBody" class="form-control my-2"></textarea>
								<button class="btn btn-primary" [disabled]="adding" (click)="updateLeadBody()">
									Update
								</button>
							</div>
							<div class="col-12">
								<div class="h5">Carousel Ad Body</div>
							</div>
							<div class="col-12 form-group">
								<textarea type="text" [(ngModel)]="carouselBody" class="form-control my-2"></textarea>
								<button class="btn btn-primary" [disabled]="adding" (click)="updateCarouselBody()">
									Update
								</button>
							</div>
							<div class="col-12">
								<div class="h5">Ad Description</div>
							</div>
							<div class="col-12 form-group">
								<textarea type="text" [(ngModel)]="adDescription" class="form-control my-2"></textarea>
								<button class="btn btn-primary" [disabled]="adding" (click)="updateAdDescription()">
									Update
								</button>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Lead Ad Settings">
					<a ngbNavLink>Lead Ad Settings</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-4 form-group">
								<label>Lead Ads Campaign</label>
								<select class="form-control" [(ngModel)]="campaign" (ngModelChange)="updateCampaign()">
									<option></option>
									<option *ngFor="let a of campaigns$ | async" [value]="a.ad_campaign_id">
										{{ a.name }}
									</option>
								</select>
							</div>
							<div class="col-4 form-group" *ngIf="campaign">
								<label>Ad Set</label>
								<select
									class="form-control"
									[(ngModel)]="leadAdSet"
									(ngModelChange)="updateLeadAdSet()"
								>
									<option></option>
									<option *ngFor="let a of leadAdSet$ | async" value="{{ a.ad_group_id }}">
										{{ a.name }}
									</option>
								</select>
							</div>
							<div class="col-4 form-group">
								<label>Lead Form</label>
								<select
									class="form-control"
									[(ngModel)]="leadFormId"
									(ngModelChange)="updateLeadFormId()"
								>
									<option></option>
									<option *ngFor="let a of leadFormIds$ | async" [value]="a.fb_lead_frm_id">
										{{ a.name }}
									</option>
								</select>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Model Click Settings">
					<a ngbNavLink>Model Click Settings</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-4 form-group">
								<label>Campaign</label>
								<select
									class="form-control"
									[(ngModel)]="modelCampaign"
									(ngModelChange)="updateModelCampaign()"
								>
									<option></option>
									<option *ngFor="let a of campaigns$ | async" [value]="a.ad_campaign_id">
										{{ a.name }}
									</option>
								</select>
							</div>
							<div class="col-4 form-group" *ngIf="modelCampaign">
								<label>Ad Set</label>
								<select
									class="form-control"
									[(ngModel)]="modelAdSet"
									(ngModelChange)="updateModelAdSet()"
								>
									<option></option>
									<option *ngFor="let a of modelAdSet$ | async" value="{{ a.ad_group_id }}">
										{{ a.name }}
									</option>
								</select>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Messenger Click Settings">
					<a ngbNavLink>Messenger Click Settings</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-4 form-group">
								<label>Campaign</label>
								<select
									class="form-control"
									[(ngModel)]="messengerCampaign"
									(ngModelChange)="updateMessengerCampaign()"
								>
									<option></option>
									<option *ngFor="let a of campaigns$ | async" [value]="a.ad_campaign_id">
										{{ a.name }}
									</option>
								</select>
							</div>
							<div class="col-4 form-group" *ngIf="messengerCampaign">
								<label>Ad Set</label>
								<select
									class="form-control"
									[(ngModel)]="messengerAdSet"
									(ngModelChange)="updateMessengerAdSet()"
								>
									<option></option>
									<option *ngFor="let a of messengerAdSet$ | async" value="{{ a.ad_group_id }}">
										{{ a.name }}
									</option>
								</select>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Video Ad Settings">
					<a ngbNavLink>Video Ad Settings</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-4 form-group">
								<label>Campaign</label>
								<select
									class="form-control"
									[(ngModel)]="videoCampaign"
									(ngModelChange)="updateVideoCampaign()"
								>
									<option></option>
									<option *ngFor="let a of campaigns$ | async" [value]="a.ad_campaign_id">
										{{ a.name }}
									</option>
								</select>
							</div>
							<div class="col-4 form-group" *ngIf="videoCampaign">
								<label>Ad Set</label>
								<select
									class="form-control"
									[(ngModel)]="videoAdSet"
									(ngModelChange)="updateVideoAdSet()"
								>
									<option></option>
									<option *ngFor="let a of videoAdSet$ | async" value="{{ a.ad_group_id }}">
										{{ a.name }}
									</option>
								</select>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Video Defaults Settings">
					<a ngbNavLink>Video Defaults Settings</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-12">
								<div class="h5">Model Video Description</div>
							</div>
							<div class="col-12 form-group">
								<textarea
									type="text"
									[(ngModel)]="modelVideoDescription"
									class="form-control my-2"
								></textarea>
								<button
									class="btn btn-primary"
									[disabled]="adding"
									(click)="updateModelVideoDescription()"
								>
									Update
								</button>
							</div>
							<div class="col-12">
								<div class="h5">Listing Video Description</div>
							</div>
							<div class="col-12 form-group">
								<textarea
									type="text"
									[(ngModel)]="listingVideoDescription"
									class="form-control my-2"
								></textarea>
								<button
									class="btn btn-primary"
									[disabled]="adding"
									(click)="updateListingVideoDescription()"
								>
									Update
								</button>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="Weekly Automatic Posting Configs">
					<a ngbNavLink>Weekly Automatic Posting Configs</a>
					<ng-template ngbNavContent>
						<div class="row">
							<div class="col-12">
								<ng-container *ngFor="let dac of defaultAdConfigs; index as index">
									<div class="row align-items-center justify-content-center">
										<div class="col-4 form-group">
											<label>Campaign</label>
											<select
												class="form-control"
												[ngModel]="dac.campaignidBS | async"
												(ngModelChange)="dac.campaignidBS.next($event)"
											>
												<option></option>
												<option *ngFor="let a of campaigns$ | async" [value]="a.ad_campaign_id">
													{{ a.name }}
												</option>
											</select>
										</div>
										<div class="col-4 form-group">
											<label>Ad Set</label>
											<select class="form-control" [(ngModel)]="dac.ad_groupid">
												<option></option>
												<option
													*ngFor="let a of dac.ad_groupid$ | async"
													value="{{ a.ad_group_id }}"
												>
													{{ a.name }}
												</option>
											</select>
										</div>
										<div class="col-4 form-group">
											<label>Ad Type</label>
											<select class="form-control" [(ngModel)]="dac.adType">
												<option></option>
												<option *ngFor="let a of adTypes" [value]="a">{{ a }}</option>
											</select>
										</div>
										<div class="col-4 form-group" *ngIf="dac.adType === 'Lead Form'">
											<label>Lead Form</label>
											<select class="form-control" [(ngModel)]="dac.leadFormId">
												<option></option>
												<option
													*ngFor="let a of leadFormIds$ | async"
													[value]="a.fb_lead_frm_id"
												>
													{{ a.name }}
												</option>
											</select>
										</div>
										<div class="col-4 form-group" *ngIf="dac.adType === 'Lead Form'">
											<label>Salesperson</label>
											<select class="form-control" [(ngModel)]="dac.salespersonId">
												<option></option>
												<option
													*ngFor="let a of salespersonid$ | async"
													[value]="a.salespersonid"
												>
													{{ a.name }}
												</option>
											</select>
										</div>
										<div class="col-4 form-group">
											<label>Ad Count</label>
											<input type="number" class="form-control" [(ngModel)]="dac.adCount" />
										</div>
										<div
											class="col-4 form-group"
											*ngIf="dac.adType === 'Carousel' || dac.adType === 'Carousel - Message'"
										>
											<label>Ad Count In Carousel</label>
											<input type="number" class="form-control" [(ngModel)]="dac.carouselCount" />
										</div>
										<div class="col-4 form-group">
											<label>Condition</label>
											<select class="form-control" [(ngModel)]="dac.conditionId">
												<option></option>
												<option *ngFor="let a of conditionid$ | async" [value]="a.conditionid">
													{{ a.name }}
												</option>
											</select>
										</div>
										<div class="col-4 form-group">
											<label>Promotion</label>
											<select class="form-control" [(ngModel)]="dac.promotionId">
												<option></option>
												<option *ngFor="let a of promotionid$ | async" [value]="a.promotionid">
													{{ a.name }}
												</option>
											</select>
										</div>
										<div class="col-4 form-group">
											<label>Vehicle Type</label>
											<select class="form-control" [(ngModel)]="dac.vehicleTypeId">
												<option></option>
												<option
													*ngFor="let a of vehicleTypeId$ | async"
													[value]="a.vehicle_typeid"
												>
													{{ a.name }}
												</option>
											</select>
										</div>
										<div class="col-4 form-group">
											<label>Brand</label>
											<select class="form-control" [(ngModel)]="dac.brandid" multiple>
												<option></option>
												<option *ngFor="let a of brandids$ | async" [value]="a.brandid">
													{{ a.name }}
												</option>
											</select>
										</div>
										<div
											class="col-12 form-group"
											*ngIf="dac.adType === 'Carousel' || dac.adType === 'Carousel - Message'"
										>
											<label>Ad Title</label>
											<input class="form-control" [(ngModel)]="dac.adTitle" />
										</div>
										<div
											class="col-12 form-group"
											*ngIf="dac.adType === 'Carousel' || dac.adType === 'Carousel - Message'"
										>
											<label>Carousel Body</label>
											<textarea class="form-control" [(ngModel)]="dac.carouselBody"></textarea>
										</div>
										<div class="col-4 form-group">
											<label>UTM Campaign</label>
											<input type="type" class="form-control" [(ngModel)]="dac.utmCampaign" />
										</div>
										<div class="col-12">&nbsp;</div>
										<div class="col-6 form-group text-center">
											<button class="btn btn-success" (click)="runAutoPost(index)">Run</button>
										</div>
										<div class="col-6 form-group text-center">
											<button class="btn btn-danger" (click)="defaultAdConfigs.splice(index, 1)">
												Remove
											</button>
										</div>
									</div>
									<hr />
								</ng-container>
								<button class="btn btn-success mx-1" (click)="runAutoPost(-1)">Run All</button>
								<button class="btn btn-primary mx-1" (click)="addNewConfig()">Add Config</button>
								<button class="btn btn-primary mx-1" (click)="updateConfigs()">Save Configs</button>
							</div>
						</div>
					</ng-template>
				</li>
			</ul>
			<div [ngbNavOutlet]="mainNav"></div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultDescriptionsComponent {
	updateBS = new BehaviorSubject(null);
	defaultDesc: string = "";
	descSection: string = "";
	title: string = "";
	cta: string = "";
	numberToPost: number = 0;
	numberToPost2: number = 0;
	boatnumberToPost: number = 0;
	boatnumberToPost2: number = 0;
	numberAVToPost: number = 0;
	numberTourToPost: number = 0;
	duration = null;
	total_budget = null;
	timeSlots: any[] = [];
	timeSlots2: any[] = [];
	boattimeSlots: any[] = [];
	boattimeSlots2: any[] = [];
	timeAVSlots: any[] = [];
	timeTourSlots: any[] = [];
	adding = false;
	audience = "";
	special = "None";
	leadFormId = "";
	campaign = "";
	modelCampaign = "";
	modelAdSet = "";
	messengerCampaign = "";
	messengerAdSet = "";
	videoCampaign = "";
	videoAdSet = "";
	leadAdSet = "";
	headline = "";
	modelBody = "";
	leadBody = "";
	dailyPostingUTM = "";
	dailyPostingBrand = "";
	videoUTM = "";
	tourUTM = "";
	adDescription = "";
	carouselBody = "";
	modelVideoDescription = "";
	listingVideoDescription = "";
	location = "";
	location2 = "";

	modelCampaignBS = new BehaviorSubject<null | number | string>(null);
	messengerCampaignBS = new BehaviorSubject<null | number | string>(null);
	videoCampaignBS = new BehaviorSubject<null | number | string>(null);
	leadCampaignBS = new BehaviorSubject<null | number | string>(null);

	modelAdSet$ = this.modelCampaignBS.pipe(
		switchMap((ad_campaignid) =>
			this.http.post("/api/statement/GetAdGroupsForCampaign", { vars: { ad_campaignid } }),
		),
		map((response: any) => response.results),
	);

	messengerAdSet$ = this.messengerCampaignBS.pipe(
		switchMap((ad_campaignid) =>
			this.http.post("/api/statement/GetAdGroupsForCampaign", { vars: { ad_campaignid } }),
		),
		map((response: any) => response.results),
	);

	videoAdSet$ = this.videoCampaignBS.pipe(
		switchMap((ad_campaignid) =>
			this.http.post("/api/statement/GetAdGroupsForCampaign", { vars: { ad_campaignid } }),
		),
		map((response: any) => response.results),
	);

	leadAdSet$ = this.leadCampaignBS.pipe(
		switchMap((ad_campaignid) =>
			this.http.post("/api/statement/GetAdGroupsForCampaign", { vars: { ad_campaignid } }),
		),
		map((response: any) => response.results),
	);

	adTypes = ["Lead Form", "Model Click", "Carousel", "Model Click - Message", "Carousel - Message"];

	defaultAdConfigs: DefaultAdObj[] = [];

	postDays = {
		Sunday: true,
		Monday: true,
		Tuesday: true,
		Wednesday: true,
		Thursday: true,
		Friday: true,
		Saturday: true,
	};

	postDays2 = {
		Sunday: true,
		Monday: true,
		Tuesday: true,
		Wednesday: true,
		Thursday: true,
		Friday: true,
		Saturday: true,
	};

	boatpostDays = {
		Sunday: true,
		Monday: true,
		Tuesday: true,
		Wednesday: true,
		Thursday: true,
		Friday: true,
		Saturday: true,
	};

	boatpostDays2 = {
		Sunday: true,
		Monday: true,
		Tuesday: true,
		Wednesday: true,
		Thursday: true,
		Friday: true,
		Saturday: true,
	};

	postAVDays = {
		Sunday: true,
		Monday: true,
		Tuesday: true,
		Wednesday: true,
		Thursday: true,
		Friday: true,
		Saturday: true,
	};

	postTourDays = {
		Sunday: true,
		Monday: true,
		Tuesday: true,
		Wednesday: true,
		Thursday: true,
		Friday: true,
		Saturday: true,
	};

	descriptions$ = this.updateBS.pipe(
		switchMap(() => this.http.post("/api/statement/GetFacebookDescriptions", {})),
		map((response: any) => response.results),
		shareReplay(1),
	);

	conditionid$ = this.updateBS.pipe(
		switchMap(() => this.http.post("/api/statement/conditionid", {})),
		map((response: any) => response.results),
		shareReplay(1),
	);

	vehicleTypeId$ = this.updateBS.pipe(
		switchMap(() => this.http.post("/api/statement/vehicle_typeid", {})),
		map((response: any) => response.results),
		shareReplay(1),
	);

	brandids$ = this.updateBS.pipe(
		switchMap(() => this.http.post("/api/statement/brandid", { vars: { siteOnly: true } })),
		map((response: any) => response.results),
		shareReplay(1),
	);

	promotionid$ = this.updateBS.pipe(
		switchMap(() => this.http.post("/api/statement/promotionid", {})),
		map((response: any) => response.results),
		shareReplay(1),
	);

	salespersonid$ = this.updateBS.pipe(
		switchMap(() => this.http.post("/api/statement/salespersonid", {})),
		map((response: any) => response.results),
		shareReplay(1),
	);

	campaigns$ = this.http.post("/api/statement/FacebookAdCampaigns", {}).pipe(
		map((response: any) => response.results),
		shareReplay(1),
	);

	leadFormIds$ = this.http.post("/api/statement/FacebookLeadForms", {}).pipe(
		map((response: any) => response.results),
		shareReplay(1),
	);

	audiences$ = this.http
		.post("/api/statement/GetFacebookAudience", {})
		.pipe(map((response: any) => response.results));

	constructor(private http: HttpClient, private toastrService: ToastrService, private cd: ChangeDetectorRef) {
		this.http.get("/api/admin/setting/find/GalleryAutoPostTimes").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				const times = res.admin_setting.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberToPost = times.length;
					this.timeSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeSlots.push({ hour: parseInt(t[0]), minute: parseInt(t[1]), second: parseInt(t[2]) });
					}
				}
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/GalleryAutoPostTimes2").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				const times = res.admin_setting.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberToPost2 = times.length;
					this.timeSlots2 = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeSlots2.push({ hour: parseInt(t[0]), minute: parseInt(t[1]), second: parseInt(t[2]) });
					}
				}
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/BoatGalleryAutoPostTimes").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				const times = res.admin_setting.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.boatnumberToPost = times.length;
					this.boattimeSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.boattimeSlots.push({
							hour: parseInt(t[0]),
							minute: parseInt(t[1]),
							second: parseInt(t[2]),
						});
					}
				}
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/BoatGalleryAutoPostTimes2").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				const times = res.admin_setting.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.boatnumberToPost2 = times.length;
					this.boattimeSlots2 = [];
					for (const time of times) {
						const t = time.split(":");
						this.boattimeSlots2.push({
							hour: parseInt(t[0]),
							minute: parseInt(t[1]),
							second: parseInt(t[2]),
						});
					}
				}
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/AVAutoPostTimes").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				const times = res.admin_setting.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberAVToPost = times.length;
					this.timeAVSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeAVSlots.push({ hour: parseInt(t[0]), minute: parseInt(t[1]), second: parseInt(t[2]) });
					}
				}
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/TourAutoPostTimes").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				const times = res.admin_setting.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberTourToPost = times.length;
					this.timeTourSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeTourSlots.push({
							hour: parseInt(t[0]),
							minute: parseInt(t[1]),
							second: parseInt(t[2]),
						});
					}
				}
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/GalleryAutoPostDays").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.postDays = JSON.parse(res.admin_setting);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/GalleryAutoPostDays2").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.postDays2 = JSON.parse(res.admin_setting);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/BoatGalleryAutoPostDays").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.boatpostDays = JSON.parse(res.admin_setting);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/BoatGalleryAutoPostDays2").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.boatpostDays2 = JSON.parse(res.admin_setting);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/DailyPostingBrand").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.dailyPostingBrand = JSON.parse(res.admin_setting);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/DailyPostingUTM").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.dailyPostingUTM = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/TourUTM").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.tourUTM = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/VideoUTM").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.videoUTM = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/AVAutoPostDays").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.postAVDays = JSON.parse(res.admin_setting);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/TourAutoPostDays").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.postTourDays = JSON.parse(res.admin_setting);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_audience").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.audience = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/facebook_page_location").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.location = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/facebook_page_location2").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.location2 = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_lead_ad_id").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.leadFormId = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_lead_ad_campaign").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.campaign = res.admin_setting;
				this.leadCampaignBS.next(this.campaign);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_lead_ad_adset").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.leadAdSet = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_model_campaign").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.modelCampaign = res.admin_setting;
				this.modelCampaignBS.next(this.modelCampaign);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_model_adset").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.modelAdSet = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_messenger_campaign").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.messengerCampaign = res.admin_setting;
				this.messengerCampaignBS.next(this.messengerCampaign);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_messenger_adset").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.messengerAdSet = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_video_campaign").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.videoCampaign = res.admin_setting;
				this.videoCampaignBS.next(this.videoCampaign);
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_video_adset").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.videoAdSet = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_duration").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.duration = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_total_budget").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.total_budget = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_special").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.special = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_cta").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.cta = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/fb_title").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.title = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/ad_headline").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.headline = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/ad_body_text").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.modelBody = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/lead_ad_body_text").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.leadBody = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/carousel_ad_body_text").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.carouselBody = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/ad_description").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.adDescription = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/model_video_description").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.modelVideoDescription = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/listing_video_description").subscribe((res: any) => {
			if (null !== res.admin_setting) {
				this.listingVideoDescription = res.admin_setting;
				this.cd.markForCheck();
			}
		});
		this.http.get("/api/admin/setting/find/WeeklyPostingSchedule").subscribe((res: any) => {
			this.defaultAdConfigs = [];
			if (null !== res.admin_setting) {
				const temp = JSON.parse(res.admin_setting);
				for (const t of temp) {
					const obj = new DefaultAdObj(this.http);
					obj.setupFromServer(t);
					this.defaultAdConfigs.push(obj);
				}
				this.cd.markForCheck();
			}
		});
	}

	adjustTimeSlots(numberOfSlots: number) {
		if (numberOfSlots > this.timeSlots.length) {
			const diff = numberOfSlots - this.timeSlots.length;
			for (let i = 1; i <= diff; i++) {
				this.timeSlots.push({
					hour: 9,
					minute: 0,
					second: 0,
				});
			}
		} else if (numberOfSlots < this.timeSlots.length) {
			const diff = this.timeSlots.length - numberOfSlots;
			this.timeSlots.splice(-1 * diff, diff);
		}
	}
	adjustTimeSlots2(numberOfSlots2: number) {
		if (numberOfSlots2 > this.timeSlots2.length) {
			const diff = numberOfSlots2 - this.timeSlots2.length;
			for (let i = 1; i <= diff; i++) {
				this.timeSlots2.push({
					hour: 9,
					minute: 0,
					second: 0,
				});
			}
		} else if (numberOfSlots2 < this.timeSlots2.length) {
			const diff = this.timeSlots2.length - numberOfSlots2;
			this.timeSlots2.splice(-1 * diff, diff);
		}
	}

	boatadjustTimeSlots(boatnumberOfSlots: number) {
		if (boatnumberOfSlots > this.boattimeSlots.length) {
			const diff = boatnumberOfSlots - this.boattimeSlots.length;
			for (let i = 1; i <= diff; i++) {
				this.boattimeSlots.push({
					hour: 9,
					minute: 0,
					second: 0,
				});
			}
		} else if (boatnumberOfSlots < this.boattimeSlots.length) {
			const diff = this.boattimeSlots.length - boatnumberOfSlots;
			this.boattimeSlots.splice(-1 * diff, diff);
		}
	}
	boatadjustTimeSlots2(boatnumberOfSlots2: number) {
		if (boatnumberOfSlots2 > this.boattimeSlots2.length) {
			const diff = boatnumberOfSlots2 - this.boattimeSlots2.length;
			for (let i = 1; i <= diff; i++) {
				this.boattimeSlots2.push({
					hour: 9,
					minute: 0,
					second: 0,
				});
			}
		} else if (boatnumberOfSlots2 < this.boattimeSlots2.length) {
			const diff = this.boattimeSlots2.length - boatnumberOfSlots2;
			this.boattimeSlots2.splice(-1 * diff, diff);
		}
	}

	adjustAVTimeSlots(numberOfSlots: number) {
		if (numberOfSlots > this.timeAVSlots.length) {
			const diff = numberOfSlots - this.timeAVSlots.length;
			for (let i = 1; i <= diff; i++) {
				this.timeAVSlots.push({
					hour: 9,
					minute: 0,
					second: 0,
				});
			}
		} else if (numberOfSlots < this.timeAVSlots.length) {
			const diff = this.timeAVSlots.length - numberOfSlots;
			this.timeAVSlots.splice(-1 * diff, diff);
		}
	}

	adjustTourTimeSlots(numberOfSlots: number) {
		if (numberOfSlots > this.timeTourSlots.length) {
			const diff = numberOfSlots - this.timeTourSlots.length;
			for (let i = 1; i <= diff; i++) {
				this.timeTourSlots.push({
					hour: 9,
					minute: 0,
					second: 0,
				});
			}
		} else if (numberOfSlots < this.timeTourSlots.length) {
			const diff = this.timeTourSlots.length - numberOfSlots;
			this.timeTourSlots.splice(-1 * diff, diff);
		}
	}

	addDesc() {
		this.adding = true;
		this.http
			.post("/api/statement/AddDefaultDescription", {
				vars: { desc_default: this.defaultDesc, desc_sectionid: this.descSection },
			})
			.subscribe(
				() => {
					this.updateBS.next(null);
					this.toastrService.success("Description Added");
					this.defaultDesc = "";
					this.adding = false;
				},
				() => (this.adding = false),
			);
	}

	removeDesc(desc_defaultid: number) {
		this.http.post("/api/statement/HideDefaultDescription", { vars: { desc_defaultid } }).subscribe(() => {
			this.updateBS.next(null);
			this.toastrService.success("Description Removed");
		});
	}

	updateTimeSlots() {
		const val: any = [];
		for (const time of this.timeSlots) {
			val.push(
				(time.hour < 10 ? "0" + time.hour : time.hour) +
					":" +
					(time.minute < 10 ? "0" + time.minute : time.minute) +
					":00",
			);
		}
		this.http
			.post("/api/admin/setting/update/GalleryAutoPostTimes", { admin_setting_val: val.join(",") })
			.subscribe((res: any) => {
				const times = res.admin_setting.admin_setting_val.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberToPost = times.length;
					this.timeSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeSlots.push({ hour: parseInt(t[0]), minute: parseInt(t[1]), second: parseInt(t[2]) });
					}
				}
				this.cd.markForCheck();
				this.toastrService.success("Time Slots Updated");
			});
	}

	updateTimeSlots2() {
		const val: any = [];
		for (const time of this.timeSlots2) {
			val.push(
				(time.hour < 10 ? "0" + time.hour : time.hour) +
					":" +
					(time.minute < 10 ? "0" + time.minute : time.minute) +
					":00",
			);
		}
		this.http
			.post("/api/admin/setting/update/GalleryAutoPostTimes2", { admin_setting_val: val.join(",") })
			.subscribe((res: any) => {
				const times = res.admin_setting.admin_setting_val.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberToPost2 = times.length;
					this.timeSlots2 = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeSlots2.push({ hour: parseInt(t[0]), minute: parseInt(t[1]), second: parseInt(t[2]) });
					}
				}
				this.cd.markForCheck();
				this.toastrService.success("Time Slots Updated");
			});
	}
	boatupdateTimeSlots() {
		const val: any = [];
		for (const time of this.boattimeSlots) {
			val.push(
				(time.hour < 10 ? "0" + time.hour : time.hour) +
					":" +
					(time.minute < 10 ? "0" + time.minute : time.minute) +
					":00",
			);
		}
		this.http
			.post("/api/admin/setting/update/BoatGalleryAutoPostTimes", { admin_setting_val: val.join(",") })
			.subscribe((res: any) => {
				const times = res.admin_setting.admin_setting_val.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.boatnumberToPost = times.length;
					this.boattimeSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.boattimeSlots.push({
							hour: parseInt(t[0]),
							minute: parseInt(t[1]),
							second: parseInt(t[2]),
						});
					}
				}
				this.cd.markForCheck();
				this.toastrService.success("Time Slots Updated");
			});
	}

	boatupdateTimeSlots2() {
		const val: any = [];
		for (const time of this.timeSlots2) {
			val.push(
				(time.hour < 10 ? "0" + time.hour : time.hour) +
					":" +
					(time.minute < 10 ? "0" + time.minute : time.minute) +
					":00",
			);
		}
		this.http
			.post("/api/admin/setting/update/BoatGalleryAutoPostTimes2", { admin_setting_val: val.join(",") })
			.subscribe((res: any) => {
				const times = res.admin_setting.admin_setting_val.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.boatnumberToPost2 = times.length;
					this.boattimeSlots2 = [];
					for (const time of times) {
						const t = time.split(":");
						this.boattimeSlots2.push({
							hour: parseInt(t[0]),
							minute: parseInt(t[1]),
							second: parseInt(t[2]),
						});
					}
				}
				this.cd.markForCheck();
				this.toastrService.success("Time Slots Updated");
			});
	}
	updateAVTimeSlots() {
		const val: any = [];
		for (const time of this.timeAVSlots) {
			val.push(
				(time.hour < 10 ? "0" + time.hour : time.hour) +
					":" +
					(time.minute < 10 ? "0" + time.minute : time.minute) +
					":00",
			);
		}
		this.http
			.post("/api/admin/setting/update/AVAutoPostTimes", { admin_setting_val: val.join(",") })
			.subscribe((res: any) => {
				const times = res.admin_setting.admin_setting_val.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberAVToPost = times.length;
					this.timeAVSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeAVSlots.push({ hour: parseInt(t[0]), minute: parseInt(t[1]), second: parseInt(t[2]) });
					}
				}
				this.cd.markForCheck();
				this.toastrService.success("Time Slots Updated");
			});
	}

	updateTourTimeSlots() {
		const val: any = [];
		for (const time of this.timeTourSlots) {
			val.push(
				(time.hour < 10 ? "0" + time.hour : time.hour) +
					":" +
					(time.minute < 10 ? "0" + time.minute : time.minute) +
					":00",
			);
		}
		this.http
			.post("/api/admin/setting/update/TourAutoPostTimes", { admin_setting_val: val.join(",") })
			.subscribe((res: any) => {
				const times = res.admin_setting.admin_setting_val.split(",");
				if (!(times.length === 1 && times[0] === "")) {
					this.numberTourToPost = times.length;
					this.timeTourSlots = [];
					for (const time of times) {
						const t = time.split(":");
						this.timeTourSlots.push({
							hour: parseInt(t[0]),
							minute: parseInt(t[1]),
							second: parseInt(t[2]),
						});
					}
				}
				this.cd.markForCheck();
				this.toastrService.success("Time Slots Updated");
			});
	}

	updateDays() {
		this.http
			.post("/api/admin/setting/update/GalleryAutoPostDays", { admin_setting_val: JSON.stringify(this.postDays) })
			.subscribe((res: any) => {
				this.postDays = JSON.parse(res.admin_setting.admin_setting_val);
				this.cd.markForCheck();
				this.toastrService.success("Days Updated");
			});
	}

	updateDays2() {
		this.http
			.post("/api/admin/setting/update/GalleryAutoPostDays2", {
				admin_setting_val: JSON.stringify(this.postDays2),
			})
			.subscribe((res: any) => {
				this.postDays2 = JSON.parse(res.admin_setting.admin_setting_val);
				this.cd.markForCheck();
				this.toastrService.success("Days Updated");
			});
	}
	boatupdateDays() {
		this.http
			.post("/api/admin/setting/update/BoatGalleryAutoPostDays", {
				admin_setting_val: JSON.stringify(this.boatpostDays),
			})
			.subscribe((res: any) => {
				this.boatpostDays = JSON.parse(res.admin_setting.admin_setting_val);
				this.cd.markForCheck();
				this.toastrService.success("Days Updated");
			});
	}

	boatupdateDays2() {
		this.http
			.post("/api/admin/setting/update/BoatGalleryAutoPostDays2", {
				admin_setting_val: JSON.stringify(this.boatpostDays2),
			})
			.subscribe((res: any) => {
				this.boatpostDays2 = JSON.parse(res.admin_setting.admin_setting_val);
				this.cd.markForCheck();
				this.toastrService.success("Days Updated");
			});
	}

	updateDailyPosting() {
		this.http
			.post("/api/admin/setting/update/DailyPostingUTM", {
				admin_setting_val: this.dailyPostingUTM,
			})
			.subscribe((res: any) => {
				this.dailyPostingUTM = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Daily Posting UTM Updated");
			});
	}
	updateDailyPostingBrand() {
		this.http
			.post("/api/admin/setting/update/DailyPostingBrand", {
				admin_setting_val: JSON.stringify(this.dailyPostingBrand),
			})
			.subscribe((res: any) => {
				this.dailyPostingBrand = JSON.parse(res.admin_setting.admin_setting_val);
				this.cd.markForCheck();
				this.toastrService.success("Daily Posting Brand(s) Updated");
			});
	}
	updateTour() {
		this.http
			.post("/api/admin/setting/update/TourUTM", {
				admin_setting_val: this.tourUTM,
			})
			.subscribe((res: any) => {
				this.tourUTM = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Tour UTM Updated");
			});
	}
	updateVideo() {
		this.http
			.post("/api/admin/setting/update/VideoUTM", {
				admin_setting_val: this.videoUTM,
			})
			.subscribe((res: any) => {
				this.tourUTM = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Tour UTM Updated");
			});
	}

	updateAVDays() {
		this.http
			.post("/api/admin/setting/update/AVAutoPostDays", { admin_setting_val: JSON.stringify(this.postAVDays) })
			.subscribe((res: any) => {
				this.postAVDays = JSON.parse(res.admin_setting.admin_setting_val);
				this.cd.markForCheck();
				this.toastrService.success("Days Updated");
			});
	}

	updateTourDays() {
		this.http
			.post("/api/admin/setting/update/TourAutoPostDays", {
				admin_setting_val: JSON.stringify(this.postTourDays),
			})
			.subscribe((res: any) => {
				this.postTourDays = JSON.parse(res.admin_setting.admin_setting_val);
				this.cd.markForCheck();
				this.toastrService.success("Days Updated");
			});
	}

	updateCta() {
		this.http.post("/api/admin/setting/update/fb_cta", { admin_setting_val: this.cta }).subscribe((res: any) => {
			this.cta = res.admin_setting.admin_setting_val;
			this.cd.markForCheck();
			this.toastrService.success("Call to Action Updated");
		});
	}
	updateDuration() {
		this.http
			.post("/api/admin/setting/update/fb_duration", { admin_setting_val: this.duration })
			.subscribe((res: any) => {
				this.duration = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Duration Updated");
			});
	}
	updateBudget() {
		this.http
			.post("/api/admin/setting/update/fb_total_budget", { admin_setting_val: this.total_budget })
			.subscribe((res: any) => {
				this.total_budget = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Budget Updated");
			});
	}
	updateTitle() {
		this.http
			.post("/api/admin/setting/update/fb_title", { admin_setting_val: this.title })
			.subscribe((res: any) => {
				this.title = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Title Updated");
			});
	}
	updateHeadline() {
		this.http
			.post("/api/admin/setting/update/ad_headline", { admin_setting_val: this.headline })
			.subscribe((res: any) => {
				this.headline = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Headline Updated");
			});
	}
	updateModelBody() {
		this.http
			.post("/api/admin/setting/update/ad_body_text", { admin_setting_val: this.modelBody })
			.subscribe((res: any) => {
				this.modelBody = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Model Ad Body Updated");
			});
	}
	updateLeadBody() {
		this.http
			.post("/api/admin/setting/update/lead_ad_body_text", { admin_setting_val: this.leadBody })
			.subscribe((res: any) => {
				this.leadBody = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Lead Ad Body Updated");
			});
	}
	updateCarouselBody() {
		this.http
			.post("/api/admin/setting/update/carousel_ad_body_text", { admin_setting_val: this.carouselBody })
			.subscribe((res: any) => {
				this.carouselBody = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Carousel Ad Body Updated");
			});
	}
	updateAdDescription() {
		this.http
			.post("/api/admin/setting/update/ad_description", { admin_setting_val: this.adDescription })
			.subscribe((res: any) => {
				this.adDescription = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Ad Description Updated");
			});
	}
	updateModelVideoDescription() {
		this.http
			.post("/api/admin/setting/update/model_video_description", {
				admin_setting_val: this.modelVideoDescription,
			})
			.subscribe((res: any) => {
				this.modelVideoDescription = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Model Video Description Updated");
			});
	}
	updateListingVideoDescription() {
		this.http
			.post("/api/admin/setting/update/listing_video_description", {
				admin_setting_val: this.listingVideoDescription,
			})
			.subscribe((res: any) => {
				this.listingVideoDescription = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Listing Video Description Updated");
			});
	}
	updateSpecial() {
		this.http
			.post("/api/admin/setting/update/fb_special", { admin_setting_val: this.special })
			.subscribe((res: any) => {
				this.special = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Special Updated");
			});
	}
	updateAudience() {
		this.http
			.post("/api/admin/setting/update/fb_audience", {
				admin_setting_val: this.audience !== "" ? this.audience : null,
			})
			.subscribe((res: any) => {
				this.audience = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Audience Updated");
			});
	}
	updateCampaign() {
		this.leadCampaignBS.next(this.campaign);
		this.http
			.post("/api/admin/setting/update/fb_lead_ad_campaign", { admin_setting_val: this.campaign })
			.subscribe((res: any) => {
				this.campaign = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Lead Ad Campaign Updated");
			});
	}
	updateLeadAdSet() {
		this.http
			.post("/api/admin/setting/update/fb_lead_ad_adset", { admin_setting_val: this.leadAdSet })
			.subscribe((res: any) => {
				this.leadAdSet = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Lead Ad AdSet Updated");
			});
	}
	updateModelCampaign() {
		this.modelCampaignBS.next(this.modelCampaign);
		this.http
			.post("/api/admin/setting/update/fb_model_campaign", { admin_setting_val: this.modelCampaign })
			.subscribe((res: any) => {
				this.modelCampaign = res.admin_setting.admin_setting_val;
				this.modelCampaignBS.next(this.modelCampaign);
				this.cd.markForCheck();
				this.toastrService.success("Model Campaign Updated");
			});
	}
	updateModelAdSet() {
		this.http
			.post("/api/admin/setting/update/fb_model_adset", { admin_setting_val: this.modelAdSet })
			.subscribe((res: any) => {
				this.modelAdSet = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Model AdSet Updated");
			});
	}
	updateMessengerCampaign() {
		this.messengerCampaignBS.next(this.messengerCampaign);
		this.http
			.post("/api/admin/setting/update/fb_messenger_campaign", { admin_setting_val: this.messengerCampaign })
			.subscribe((res: any) => {
				this.messengerCampaign = res.admin_setting.admin_setting_val;
				this.messengerCampaignBS.next(this.messengerCampaign);
				this.cd.markForCheck();
				this.toastrService.success("Messenger Campaign Updated");
			});
	}
	updateMessengerAdSet() {
		this.http
			.post("/api/admin/setting/update/fb_messenger_adset", { admin_setting_val: this.messengerAdSet })
			.subscribe((res: any) => {
				this.messengerAdSet = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Messenger AdSet Updated");
			});
	}
	updateVideoCampaign() {
		this.videoCampaignBS.next(this.videoCampaign);
		this.http
			.post("/api/admin/setting/update/fb_video_campaign", { admin_setting_val: this.videoCampaign })
			.subscribe((res: any) => {
				this.videoCampaign = res.admin_setting.admin_setting_val;
				this.videoCampaignBS.next(this.videoCampaign);
				this.cd.markForCheck();
				this.toastrService.success("Video Campaign Updated");
			});
	}
	updateVideoAdSet() {
		this.http
			.post("/api/admin/setting/update/fb_video_adset", { admin_setting_val: this.videoAdSet })
			.subscribe((res: any) => {
				this.videoAdSet = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Video AdSet Updated");
			});
	}
	updateLeadFormId() {
		this.http
			.post("/api/admin/setting/update/fb_lead_ad_id", { admin_setting_val: this.leadFormId })
			.subscribe((res: any) => {
				this.leadFormId = res.admin_setting.admin_setting_val;
				this.cd.markForCheck();
				this.toastrService.success("Lead Ad Form Updated");
			});
	}

	updateConfigs() {
		const configs = [];
		for (const i of this.defaultAdConfigs) {
			configs.push(i.sendToServer());
		}
		this.http
			.post("/api/admin/setting/update/WeeklyPostingSchedule", {
				admin_setting_val: JSON.stringify(configs),
			})
			.subscribe((res: any) => {
				this.defaultAdConfigs = [];
				if (null !== res.admin_setting.admin_setting_val) {
					const temp = JSON.parse(res.admin_setting.admin_setting_val);
					for (const t of temp) {
						const obj = new DefaultAdObj(this.http);
						obj.setupFromServer(t);
						this.defaultAdConfigs.push(obj);
					}
				}
				this.cd.markForCheck();
				this.toastrService.success("Configs Updated");
			});
	}

	addNewConfig() {
		this.defaultAdConfigs.push(new DefaultAdObj(this.http));
	}

	runAutoPost(index: number) {
		this.http
			.post("/api/service/facebook/post/listing/weekly", { index })
			.subscribe(() => this.toastrService.success("Build Queued"));
	}
}

class DefaultAdObj {
	campaignidBS = new BehaviorSubject(null);
	ad_groupid$ = this.campaignidBS.pipe(
		switchMap((ad_campaignid) =>
			this.http.post("/api/statement/GetAdGroupsForCampaign", { vars: { ad_campaignid } }),
		),
		map((response: any) => response.results),
	);
	ad_groupid = null;
	adCount = 0;
	adType = null;
	carouselCount = 0;
	carouselBody = "";
	adTitle = "";
	vehicleTypeId = null;
	leadFormId = null;
	conditionId = null;
	promotionId = null;
	salespersonId = null;
	brandid = null;
	utmCampaign = null;
	constructor(private http: HttpClient) {}

	sendToServer() {
		return {
			ad_campaignid: this.campaignidBS.getValue(),
			ad_groupid: this.ad_groupid,
			adType: this.adType,
			adCount: this.adCount,
			carouselCount: this.carouselCount,
			vehicle_typeid: this.vehicleTypeId,
			conditionid: this.conditionId,
			promotionid: this.promotionId,
			leadFormId: this.leadFormId,
			salespersonid: this.salespersonId,
			carouselBody: this.carouselBody,
			adTitle: this.adTitle,
			brandid: this.brandid,
			utmCampaign: this.utmCampaign,
		};
	}

	setupFromServer(data: any) {
		this.campaignidBS.next(data.ad_campaignid);
		this.ad_groupid = data.ad_groupid;
		this.adType = data.adType;
		this.adCount = data.adCount;
		this.carouselCount = data.carouselCount;
		this.vehicleTypeId = data.vehicle_typeid;
		this.conditionId = data.conditionid;
		this.promotionId = data.promotionid;
		this.leadFormId = data.leadFormId;
		this.salespersonId = data.salespersonid;
		this.carouselBody = data.carouselBody;
		this.adTitle = data.adTitle;
		this.brandid = data.brandid;
		this.utmCampaign = data.utmCampaign;
	}
}
