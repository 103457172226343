<div class="cm-tab-customerview theme-bg">
	<div class="row">
		<div
			class="h1 col-12 text-white font-normal font-weight-normal text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0 bg-primary"
		>
			Views By User
		</div>
		<div class="col-12">
			<div class="py-2">
				<ngb-progressbar type="primary" [value]="100" [striped]="true" [animated]="true" *ngIf="loading"
					>Loading...</ngb-progressbar
				>
			</div>
			<table
				class="table table-sm table-striped table-hover table-responsive w-100 d-block d-md-table"
				*ngIf="users$ | async as users"
			>
				<thead>
					<tr>
						<th>Name</th>
						<th class="text-center">Saved Search Count</th>
						<th class="text-center">Watched Home Count</th>
						<th class="text-center">Unique Listings Viewed</th>
						<th class="text-center">Time of Last Visit</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let user of users">
						<td>
							<button
								class="btn btn-secondary w-100"
								(click)="userIdBS.next(user.userid); showUser = true"
							>
								{{ user.first_name }} {{ user.last_name }}
							</button>
						</td>
						<td class="text-center">
							{{ user.savedSearchCount }}
						</td>
						<td class="text-center">
							{{ user.watchCount }}
						</td>
						<td class="text-center">
							{{ user.listingCount }}
						</td>
						<td class="text-center">
							<span *ngIf="user.lastVisit">{{ user.lastVisit | date: "short" }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<cm-modal [show]="showUser" (showChange)="showUser = $event">
	<ng-template cmModal>
		<div class="row">
			<div
				class="h1 col-12 text-white font-normal font-weight-normal text-uppercase flex-center flex-column px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0 bg-primary"
			>
				<ng-container *ngIf="cusotmerInfo$ | async as customerInfo">
					<div>{{ customerInfo.first_name }} {{ customerInfo.last_name }}</div>
					<a
						href="tel:{{ customerInfo.customer_phone_number }}"
						class="text-white"
						*ngIf="customerInfo.customer_phone_number"
						>{{ customerInfo.customer_phone_number }}</a
					>
					<a
						href="mailto:{{ customerInfo.customer_email }}"
						class="text-white"
						*ngIf="customerInfo.customer_email"
						>{{ customerInfo.customer_email }}</a
					></ng-container
				>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="row">
						<div class="col-12 text-center h2 tk-adelle font-weight-normal text-tertiary">
							Watched Properties
						</div>
						<cm-stevo-listing-nearby-homes
							*ngIf="favs$ | async as favs"
							class="col-12"
							[listingids]="favs"
							[limit]="favs.length"
							title=""
							[slidesInView]="{ xs: 1, '768': 2, '1100': 3 }"
						></cm-stevo-listing-nearby-homes>
					</div>
					<div class="row">
						<div class="col-12 text-center h2 tk-adelle font-weight-normal text-tertiary">
							Saved Searches
						</div>
						<table class="table table-sm table-striped table-hover">
							<thead>
								<tr>
									<th>NAME</th>
									<th>SEARCH TERMS</th>
									<th>ACTIONS</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let search of savedSearches$ | async">
									<td class="tk-adelle font-weight-normal text-tertiary">{{ search.saved_srch }}</td>
									<td>
										<ul class="list-unstyled list-inline">
											<li class="d-inline-block mx-1" *ngFor="let item of search.labels">
												<b>{{ item.title }}: </b> {{ item.value }}
											</li>
										</ul>
									</td>
									<td>
										<div
											class="btn btn-secondary text-white font-weight-bold rounded"
											(click)="loadSearch(search)"
										>
											LOAD SEARCH
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="row">
						<div class="col-12 text-center h2 tk-adelle font-weight-normal text-tertiary">
							Homes Visited
						</div>
						<table class="table table-sm table-striped table-hover">
							<thead>
								<tr>
									<th>ADDRESS</th>
									<th class="text-center">VISIT COUNT</th>
									<th class="text-center">LAST VISIT</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let visit of visits$ | async">
									<td>
										<a routerLink="{{ visit.content_page_url }}">{{ visit.h1 }}</a>
									</td>
									<td class="text-center">{{ visit.visit }}</td>
									<td class="text-center">{{ visit.lastVisit | date: "short" }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</cm-modal>
