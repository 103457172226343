import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "cm-field-month-year",
	templateUrl: "./field-month-year.component.html",
	styleUrls: ["./field-month-year.component.scss"],
})
export class FieldMonthYearComponent {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() value?: string; // TODO: parse date if set

	@Output() valueChange = new EventEmitter<string>();

	month: string = "";
	year: string = "";
	yearList: number[] = [];

	constructor() {
		const currentYear = new Date().getFullYear();
		for (let i = 0; i < 50; i++) {
			this.yearList.push(currentYear - i);
		}
	}

	changed() {
		this.valueChange.emit(`${this.year}-${this.month}-01`);
	}
}
