<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<select
	*ngIf="multiple"
	#select
	class="form-control"
	[name]="name"
	[id]="id"
	[disabled]="disabled"
	[required]="required"
	multiple
	(blur)="validate()"
	[(ngModel)]="value"
	(ngModelChange)="valueChange.emit($event)"
>
	<option *ngIf="!multiple" value=""></option>
	<option *ngFor="let opt of options" [value]="opt.value">{{ opt.label }}</option>
</select>
<select
	*ngIf="!multiple"
	#select
	class="form-control"
	[name]="name"
	[id]="id"
	[disabled]="disabled"
	[required]="required"
	(blur)="validate()"
	[(ngModel)]="value"
	(ngModelChange)="valueChange.emit($event)"
>
	<option *ngIf="!multiple" value=""></option>
	<option *ngFor="let opt of options" [value]="opt.value">{{ opt.label }}</option>
</select>
<div class="invalid-feedback">{{ error }}</div>
