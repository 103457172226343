import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { UserService } from "@core/app/user.service";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import { faHeart as faEmptyHeart } from "@fortawesome/pro-regular-svg-icons";

@Component({
	selector: "cm-save-listing",
	template: `
		<div (click)="onFavoritesClick($event)" class="btn w-100 btn-outline-secondary">
			<fa-icon
				[icon]="favored(userService.fav$ | async) ? faHeart : faEmptyHeart"
				[ngClass]="{
					'text-danger': favored(userService.fav$ | async)
				}"
			></fa-icon>
		</div>
		<cm-login-modal
			[show]="showLogin"
			(loggedIn)="onFavoritesClick(); showLogin = false"
			(showChange)="showLogin = $event"
		></cm-login-modal>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveListingComponent {
	@Input() listingid!: number;
	showLogin = false;

	faHeart = faHeart;
	faEmptyHeart = faEmptyHeart;

	constructor(public userService: UserService, private http: HttpClient, private cd: ChangeDetectorRef) {}

	onFavoritesClick(event?: any) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		// Check if we even have a user
		if (this.userService.get()) {
			const customerid = this.userService.get("customerId");
			// Check if we have a customerid for the logged in user
			if (customerid) {
				if (!this.favored()) {
					this.http
						.post("/api/statement/FavoriteInsert", {
							vars: {
								customerid: customerid,
								listingid: this.listingid,
							},
						})
						.subscribe(() => {
							this.userService.refresh();
							this.cd.markForCheck();
						});
				} else {
					this.http
						.post("/api/statement/FavoriteDelete", {
							vars: {
								customerid: customerid,
								listingid: this.listingid,
							},
						})
						.subscribe(() => {
							this.userService.refresh();
							this.cd.markForCheck();
						});
				}
			} else {
				// If we don't have a customerid, show the login modal
				this.showLogin = true;
			}
		} else {
			// If we don't have a user, show the login modal
			this.showLogin = true;
		}
	}

	favored(favs?: any) {
		if (!favs) {
			favs = this.userService.get("favs");
		}
		return favs !== null && favs.length > 0 && favs.indexOf(this.listingid) !== -1;
	}
}
