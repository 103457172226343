import { HttpClient } from "@angular/common/http";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { map, tap } from "rxjs/operators";

@Component({
	selector: "cm-tab-saved-search-views",
	templateUrl: "./tab-saved-search.component.html",
	styleUrls: ["./tab-content.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class TabSavedSearchViewsComponent {
	loading = true;
	savedSearches$ = this.http
		.post("/api/statement/GetSavedSearchViews", {
			vars: { site_configid: this.pageData.appInfo.data.siteConfigId },
		})
		.pipe(
			map((res: any) => res.results),
			tap(() => {
				this.loading = false;
			}),
		);

	constructor(private http: HttpClient, @Inject("PAGE_DATA") private pageData: any) {}
}
