import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
	selector: "cm-scroll",
	template: `
		<div class="position-relative" style="background: var(--secondary); height: 2px">
			<div
				class="bar"
				style="background: var(--primary); min-width: 40px; height: 20px"
				[ngStyle]="{ 'width.%': barWidth(), 'left.%': barOffset() }"
			></div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			.bar {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				transition: left 0.3s;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollComponent {
	@Input() offset: number = 0;
	@Input() visible: number = 1;
	@Input() total: number = 10;

	barWidth() {
		return (this.visibleClamp() / this.total) * 100;
	}

	barOffset() {
		return (Math.min(this.offset, this.total - this.visibleClamp()) / this.total) * 100;
	}

	visibleClamp() {
		return Math.min(this.visible, this.total);
	}
}
