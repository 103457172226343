import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { IPageData } from "@model/page-data";
import { ToastrService } from "ngx-toastr";
import { ImagePipe } from "@common/pipes/image";
import { RestService, Service } from "@core/app/rest.service";

let idNum: number = 0;

@Component({
	selector: "cm-field-compare",
	templateUrl: "./field-compare.component.html",
	styleUrls: ["./field-compare.component.scss"],
	providers: [ImagePipe],
})
export class FieldCompareComponent {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;

	@Output() valueChange = new EventEmitter<[any, any, any]>();

	id: number = idNum++;
	brands: Brand[] | null = null;
	service: Service;
	units: [Unit, Unit, Unit] = [new Unit(), new Unit(), new Unit()];

	constructor(
		private toastrService: ToastrService,
		rest: RestService,
		@Inject("PAGE_DATA") public pageData: IPageData,
		private imagePipe: ImagePipe,
	) {
		this.service = rest.init("statement");

		this.loadBrands();
	}

	async onBrandSelect(unit: Unit) {
		unit.listings = null;

		if (!unit.brand) {
			return;
		}

		let response;
		try {
			response = await this.service.post("GetListings", { vars: { brandid: unit.brand.id } });
		} catch (ex) {
			this.toastrService.error("Failed to load listings");
			return;
		}

		unit.listings = response.results.map((row: any) => ({
			id: parseInt(row.listingid),
			year: row.model_year,
			modelNum: row.model_num,
			subvin: row.vin ? row.vin.substring(-6) : null,
			modelNumYearid: row.model_num_yearid,
			image: row.img_dir ? { src: this.imagePipe.transform(row.img_dir + row.img_file, "l") } : null,
			floorplan: row.img_dir3 ? { src: this.imagePipe.transform(row.img_dir3 + row.img_file3, "s") } : null,
			condition: row.condition,
			price: parseFloat(row.price),
			wholesalePrice: parseFloat(row.cost) + parseFloat(row.freight || 0),
		}));
	}

	async onListingSelect(unit: Unit) {
		unit.specs = null;

		if (!unit.listing) {
			return;
		}

		let response;
		try {
			response = await this.service.post("GetModelSpecs", {
				vars: { model_num_yearid: unit.listing.modelNumYearid },
			});
		} catch (ex) {
			this.toastrService.error("Failed to load model specs");
			return;
		}

		const result = response.results[0];

		unit.specs = {
			sleeps: parseInt(result.sleeps),
			slides: parseInt(result.slides),
			hitchWeight: parseInt(result.hitch_weight),
			shipWeight: parseInt(result.ship_weight),
			gvwr: parseInt(result.gvwr),
			length: {
				feet: parseInt(result.exterior_length_feet),
				inches: parseInt(result.exterior_length_inches),
			},
			height: {
				feet: parseInt(result.exterior_height_feet),
				inches: parseInt(result.exterior_height_inches),
			},
			width: {
				feet: parseInt(result.exterior_width_feet),
				inches: parseInt(result.exterior_width_inches),
			},
			freshWater: parseInt(result.fresh_water),
			grayWater: parseInt(result.gray_water),
			blackWater: parseInt(result.black_water),
			lpCapacity: parseInt(result.lp_capacity),
			tireSize: result.tire_size,
			furnaceBtu: parseInt(result.furnace_btu),
		};

		this.valueChange.emit([
			this.units[0].listing ? this.units[0].listing!.id : null,
			this.units[1].listing ? this.units[1].listing!.id : null,
			this.units[2].listing ? this.units[2].listing!.id : null,
		]);
	}

	private async loadBrands() {
		let response;
		try {
			response = await this.service.post("GetBrandFilter", {});
		} catch (ex) {
			this.toastrService.error("Failed to load brands");
			return;
		}

		this.brands = response.results.map((row: any) => new Brand(parseInt(row.brandid), row.brand));
	}
}

class Brand {
	constructor(public id: number, public name: string) {}
}

interface IListing {
	id: number;
	year: number;
	modelNum: string;
	subvin: string | null;
	modelNumYearid: number;
	image: any;
	floorplan: any;
	condition: string;
	price: number;
	wholesalePrice: number;
}

class Unit {
	brand: Brand | null = null;
	listing: IListing | null = null;

	listings: IListing[] | null = null;
	specs: ISpecs | null = null;
}

interface ISpecs {
	sleeps: number;
	slides: number;
	hitchWeight: number;
	shipWeight: number;
	gvwr: number;
	length: IDistance;
	height: IDistance;
	width: IDistance;
	freshWater: number;
	grayWater: number;
	blackWater: number;
	lpCapacity: number;
	tireSize: string;
	furnaceBtu: number;
}

interface IDistance {
	feet: number;
	inches: number;
}
