import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DefaultDescriptionsComponent } from "./components/default-descriptions.component";
import { FormsModule } from "@angular/forms";
import { NgbNavModule, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
	declarations: [DefaultDescriptionsComponent],
	imports: [CommonModule, FontAwesomeModule, FormsModule, NgbNavModule, NgbTimepickerModule],
	exports: [DefaultDescriptionsComponent],
})
export class FacebookModule {}
